<nz-layout>
  <nz-content>
    <!-- <vb-sidebar></vb-sidebar> -->
    <!-- <vb-support-chat></vb-support-chat> -->
    <div
      class="authContainer"
      [ngClass]="{
        vb__layout__squaredBorders: isSquaredBorders,
        vb__layout__cardsShadow: isCardShadow,
        vb__layout__borderless: isBorderless,
        white: authPagesColor === 'white',
        gray: authPagesColor === 'gray'
      }"
      [ngStyle]="{
        backgroundImage: authPagesColor === 'image' ? 'url(assets/images/content/photos/8.jpg)' : ''
      }"
    >
      <div
        class="topbar"
        [ngClass]="{
          topbarGray: isGrayTopbar
        }"
      >
        <div class="logoContainer">
          <div class="logo">
            <img
              src="assets/images/logo.svg"
              alt="logo"
              style="height: 24px;width: 24px;margin-right: 10px;"
            />
            <div class="name">{{ logo }}</div>
          </div>
        </div>
        <!-- <div class="d-none d-sm-block">
          <span class="mr-2">Don't have an account?</span>
          <a routerLink="/auth/register" class="font-size-16 vb__utils__link">
            Sign up
          </a>
        </div> -->
      </div>
      <div class="mb-5">
        <div
          [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')"
          [@fadein]="routeAnimation(outlet, 'fadein')"
        >
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <div class="mt-auto pb-5 pt-5">
        <!-- <ul class="footerNav list-unstyled d-flex mb-0 flex-wrap justify-content-center">
          <li>
            <a href="javascript: void(0);">Terms of Use</a>
          </li>
          <li class="active list-inline-item">
            <a href="javascript: void(0);">Compliance</a>
          </li>
          <li>
            <a href="javascript: void(0);">Support</a>
          </li>
          <li>
            <a href="javascript: void(0);">Contacts</a>
          </li>
          <li>
            <a href="javascript: void(0);">About</a>
          </li>
          <li>
            <a href="javascript: void(0);">Advertising</a>
          </li>
        </ul> -->
        <div class="text-center">
          Copyright © {{ date }} PT Onindonesia Sistem Teknologi
          <!-- <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">
            Sellpixels.com
          </a> |
          <a href="https://www.sellpixels.org/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> -->
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
