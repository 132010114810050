<form nz-form [formGroup]="formViewCollectiveLeave">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="title" class="text-left">Leave Title</nz-form-label>
        <input nz-input id="title'" formControlName="title" disabled="true" />
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item style="display: flex; flex-direction: column;">
        <nz-form-label nzFor="dateOfLeave" class="text-left">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              formControlName="startValue"
              nzPlaceHolder="Start"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formViewCollectiveLeave.value.startValue"
              [nzDisabled]="true"
            ></nz-date-picker>
            <div style="margin-left: 4px; margin-right: 4px;">-</div>
            <nz-date-picker
              #endDatePicker
              formControlName="endValue"
              nzPlaceHolder="End"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formViewCollectiveLeave.value.endValue"
              [nzDisabled]="true"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="description" class="text-left">Description</nz-form-label>
        <input nz-input id="description'" formControlName="description" disabled="true" />
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="shift_name" class="text-left">Leave Shift</nz-form-label>
        <input nz-input id="shift_name'" formControlName="shift_name" disabled="true" />
      </nz-form-item>
    </div>
  </div>
</form>
