import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import storeeeee from 'store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { Observable, Observer } from 'rxjs'
import { OnMitraService } from 'src/app/services/on-mitra.service'
import { PendingForm } from 'src/app/services/interface/on-mitra.model'

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-edit-pending',
  templateUrl: './edit-pending.component.html',
})
export class EditPendingComponent implements OnInit {
  @Input() _id: string

  form: FormGroup
  fileList: { [key: string]: NzUploadFile[] } = {
    profile_img: [],
    ktp_img: [],
    kk_img: [],
    sim_img: [],
    stnk_img: [],
    skck_img: [],
    sks_img: [],
    npwp_img: [],
  }

  constructor(
    private fb: FormBuilder,
    private mitraService: OnMitraService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      email: [null],
      first_name: [null],
      last_name: [null],
      gender: [null],
      date_of_birth: [null],
      phoneNumberPrefix: ['+62'],
      phone_number: [null],
      disabled: [null, Validators.compose([Validators.required])],
      plat_no: [null],
      vehicle_type: [null],
      npwp_no: [null],
      profile_img: [null, Validators.compose([Validators.required])],
      ktp_img: [null, Validators.compose([Validators.required])],
      kk_img: [null, Validators.compose([Validators.required])],
      sim_img: [null, Validators.compose([Validators.required])],
      stnk_img: [null, Validators.compose([Validators.required])],
      skck_img: [null, Validators.compose([Validators.required])],
      sks_img: [null, Validators.compose([Validators.required])],
      npwp_img: [null, Validators.compose([Validators.required])],
    })
  }

  isLoading: boolean = true
  ngOnInit(): void {
    this.fetchPendingDetails()
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  npwpNo: string

  fetchPendingDetails() {
    this.isLoading = true
    this.mitraService.detailPending(this._id).subscribe(
      res => {
        this.npwpNo = res.npwp_no
        this.form.patchValue(res)
        this.isLoading = false
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  cancel(): void {
    this.modal.destroy()
  }

  submitForm(): void {
    this.isLoading = true
    var isUploading = false
    //looping object
    for (var i in this.fileList) {
      for (let j = 0; j < this.fileList[i].length; j++) {
        if (['uploading', 'error'].includes(this.fileList[i][j].status)) {
          isUploading = true
          break
        }
      }
      if (isUploading) break
    }
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (!isUploading && this.form.valid) {
      var formSubs: PendingForm = this.form.value
      formSubs._id = this._id
      formSubs.npwp_no = this.npwpNo

      Object.keys(this.fileList).forEach(r => {
        if (this.fileList[r][0].status === 'done') {
          formSubs[r] = {
            status: this.fileList[r][0].status,
            name: this.fileList[r][0].response?.name || this.fileList[r][0].name,
            url: this.fileList[r][0].response?.url || this.fileList[r][0].url,
            uid: this.fileList[r][0].uid,
          }
        }
      })

      this.mitraService.verifyPending(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
        },
        err => {
          console.log(err)
          this.notification.error('Error', err.error.message)
          this.isLoading = false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return
    }
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq(type: string) {
    return (item: NzUploadXHRArgs) => {
      const formData = new FormData()
      formData.append(type, item.file as any)

      const req = new HttpRequest(
        'POST',
        this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp/on_mitra',
        formData,
        {
          headers: new HttpHeaders({
            authorization: 'Bearer ' + storeeeee.get('accessToken'),
          }),
          reportProgress: true,
          withCredentials: false,
        },
      )

      return this.http.request(req).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total > 0) {
              ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
            }
            item.onProgress!(event, item.file)
          } else if (event instanceof HttpResponse) {
            item.onSuccess!(event.body, item.file, event)
          }
        },
        err => {
          // console.log(err)
          /* error */
          item.onError!(err, item.file)
        },
      )
    }
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam, type: string) {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      if (type === 'profile_img') {
        this.form.patchValue({ profile_img: true })
      }
      if (type === 'ktp_img') {
        this.form.patchValue({ ktp_img: true })
      }
      if (type === 'kk_img') {
        this.form.patchValue({ kk_img: true })
      }
      if (type === 'sim_img') {
        this.form.patchValue({ sim_img: true })
      }
      if (type === 'stnk_img') {
        this.form.patchValue({ stnk_img: true })
      }
      if (type === 'skck_img') {
        this.form.patchValue({ skck_img: true })
      }
      if (type === 'sks_img') {
        this.form.patchValue({ sks_img: true })
      }
      if (type === 'npwp_img') {
        this.form.patchValue({ npwp_img: true })
      }
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }
}
