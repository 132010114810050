<form nz-form [formGroup]="formCollectiveLeave">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="title" class="text-left">Leave Title</nz-form-label>
        <nz-form-control nzErrorTip="Please input title correctly!">
          <input
            nz-input
            id="title"
            formControlName="title"
            placeholder="Leave Title"
            style="margin-left: 44px;"
            class="col-10"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="date" class="text-left">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <nz-range-picker
            formControlName="date"
            (ngModelChange)="onChange($event)"
            style="margin-left: 40px;"
          ></nz-range-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-lg-6 col-md-12">
      <nz-form-item>
        <nz-form-label nzFor="description" class="text-left">Leave Information</nz-form-label>
        <nz-form-control nzErrorTip="Please input information correctly!">
          <textarea
            rows="4"
            nz-input
            id="description"
            formControlName="description"
            placeholder="Leave Information"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="shift_id" class="text-left">Leave Shift</nz-form-label>
        <nz-form-control nzErrorTip="Please input shift correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a shift"
            [(ngModel)]="selectedShift"
            formControlName="shift_id"
          >
            <nz-option
              *ngFor="let option of listOfShift"
              [nzLabel]="option.shift_name"
              [nzValue]="option.id"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div
      class="d-flex"
      style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
    >
      <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
      <button
        nz-button
        nzType="default"
        nzDanger
        style="background-color: rgb(231, 64, 64) !important; color: white;"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
