import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import store from 'store'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ApiService } from './api.service'
import { newHistory } from '../models/asset-tracking.model'
@Injectable({
  providedIn: 'root',
})
export class AssetTrackingService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }

  getAssetList(assetNumber: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/tracking' +
        `${assetNumber.length > 0 ? '?assetNumber=' + assetNumber : ''}`,
      { headers: this.getCustomHeaders() },
    )
  }

  addNewHistory(payload: newHistory): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/tracking/',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  exportHistory(assetNumber: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/tracking/export?assetNumber=' +
        assetNumber,

      { headers: this.getCustomHeaders() },
    )
  }
}
