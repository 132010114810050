<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="full_name" class="text-left">Full Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input full name correctly!">
                        <input nz-input id="'full_name'" readonly formControlName="full_name"
                            placeholder="Input full name ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="phone" class="text-left">Phone</nz-form-label>
                    <nz-form-control nzErrorTip="Please input phone correctly!">
                        <input nz-input id="'phone'" readonly formControlName="phone"
                            placeholder="Input phone ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid email">
                        <input nz-input id="'email'" formControlName="email" placeholder="Input Email ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="business_name" class="text-left">Business Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid business name">
                        <input nz-input id="'business_name'" formControlName="business_name" placeholder="Input business name ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="product_sold" class="text-left">Product Sold</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid product sold">
                        <input nz-input id="'product_sold'" formControlName="product_sold" placeholder="Input product sold ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="shipment_quantity" class="text-left">Shipment Quantity per Week (kg)</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid shipment quantity">
                        <input nz-input id="'shipment_quantity'" formControlName="shipment_quantity" placeholder="Input shipment quantity ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="business_link" class="text-left">Business Link</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid business link">
                        <a [href]=businessLink target="_blank">
                            <input style="cursor: pointer;" nz-input id="'business_link'" formControlName="business_link" placeholder="Input business link ..." readonly />
                        </a>
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="updated_at" class="text-left">Inquiry Date</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid date">
                        <input nz-input id="'updated_at'" formControlName="updated_at" placeholder="Input date ..." readonly />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </form>
</nz-spin>