import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { tap } from 'rxjs/operators'
import { QueryTable } from 'src/app/services/interface/general.model'
import { ShiftService } from 'src/app/services/shift.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-create-shift-scheduler',
  templateUrl: './create-shift-scheduler.component.html',
})
export class CreateShiftSchedulerComponent implements OnInit {
  @ViewChild('toaster', { static: true }) toasterTemplate: TemplateRef<{}>
  listOfData: any[] = []
  isLoading: boolean = false
  showLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  importExcelFile: NzUploadFile[] = []

  toasterIcon: string
  toasterColor: string
  toasterMessage: string

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Employee Name',
      key: 'employee_name',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '250px',
    },
    {
      name: 'NIP',
      key: 'employee_code',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '200px',
    },
    {
      name: 'Year',
      key: 'year',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Month',
      key: 'month',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    ...Array.from({ length: 31 }).map((_, index) => ({
      name: `${index + 1}`,
      key: `schedule${index + 1}`,
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    })),
  ]

  constructor(private shiftService: ShiftService, private notification: NzNotificationService) {}

  ngOnInit(): void {}

  // fetchScheduler() {
  //   this.isLoading = false
  //   this.shiftService.
  // }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    // this.fetchScheduler()
  }

  reset() {
    this.listOfData = []
    this.importExcelFile = []
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    // this.fetchScheduler()
  }

  trackByName(_: number, item: any): string {
    return item.name
  }

  handleChangeImport(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.isLoading = true
        break
      case 'done':
        this.isLoading = false
        break
      case 'error':
        this.isLoading = false
        break
    }
  }

  downloadTemplateShiftScheduler() {
    let link = document.createElement('a')
    link.download = 'Template_Shift_Scheduler'
    link.href = 'assets/file/Template_Shift_Scheduler.xlsx'
    link.click()
  }

  getMonthName(monthNumber: number): string {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    return months[monthNumber - 1]
  }

  customUpload = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)
    this.shiftService.uploadExcel(formData).subscribe(
      r => {
        item.onSuccess(r, item.file, r)
        this.listOfData = r.json

        this.listOfData.forEach(item => {
          item.schedule.forEach((value: any, index: number) => {
            item[`schedule${index + 1}`] = value
          })
          delete item.schedule
        })
        this.toasterMessage = `Upload Success\n${item.file.name}`
        this.toasterIcon = 'check-circle'
        this.toasterColor = '#52c41a'
        this.createBasicNotification(this.toasterTemplate)
      },
      error => {
        item.onError(error, item.file)
        this.toasterMessage = 'Upload Error. ' + error.message
        this.toasterIcon = 'warning'
        this.toasterColor = '#eb2f96'
        this.createBasicNotification(this.toasterTemplate)
      },
    )
  }

  submitExcel() {
    this.showLoading = true
    const transformedData = this.listOfData.map(data => {
      const schedule = Array.from({ length: 31 }).map(
        (_, index) => data[`schedule${index + 1}`] || 'string',
      )
      return {
        employee_name: data.employee_name || 'string',
        employee_code: data.employee_code || 'string',
        year: data.year || 0,
        month: data.month || 0,
        schedule,
        placement_code: data.placement_code || 'string',
      }
    })

    const transformedPayload = { json: transformedData }

    this.shiftService
      .createShiftScheduler(transformedPayload)
      .pipe(
        tap({
          next: (res: any) => {
            this.showLoading = false
            this.toasterMessage = 'Shift Scheduler Created Successfully'
            this.toasterIcon = 'check-circle'
            this.toasterColor = '#52c41a'
            this.createBasicNotification(this.toasterTemplate)
            this.listOfData = []
            this.importExcelFile = []
          },
          error: (err: any) => {
            this.showLoading = false
            this.toasterMessage = err.message
            this.toasterIcon = 'warning'
            this.toasterColor = '#eb2f96'
            this.createBasicNotification(this.toasterTemplate)
          },
        }),
      )
      .subscribe()
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'topRight',
    })
  }
}
