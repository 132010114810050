import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { HrService } from 'src/app/services/hr.service'
import { PositionForm } from 'src/app/services/interface/position.model'
import { AddPositionComponent } from './add-position/add-position.component'
import { EditPositionComponent } from './edit-position/edit-position.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-position-management',
  templateUrl: './position-management.component.html',
})
export class PositionManagementComponent implements OnInit {
  listOfData: PositionForm[] = []

  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private hrService: HrService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '90px',
    },
    {
      name: 'Position',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Head',
      key: 'parent_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Created At',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
    {
      name: 'Updated At',
      key: 'updated_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
  ]

  ngOnInit(): void {
    this.fetchPosition()
  }

  fetchPosition() {
    this.isLoading = true
    this.hrService.getPosition(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.data
      this.totalData = r.total
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchPosition()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchPosition()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchPosition()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  addPosition() {
    const modal = this.modal.create({
      nzTitle: 'Add New Position',
      nzContent: AddPositionComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchPosition()
    })
  }

  async viewPosition(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Position',
      nzContent: EditPositionComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      nzOnCancel: () => {
        console.log('CANCEL')
      },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchPosition()
    })
  }

  deletePosition(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this Position?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.hrService.deletePosition(id).subscribe(
          r => {
            this.notification.success('Success', 'Successfully Deleted Position!')
            this.fetchPosition()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchPosition()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    })
  }
}
