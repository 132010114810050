<form nz-form [formGroup]="formAddAnnualLeave">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="leaveType" class="text-left">Leave Type</nz-form-label>
        <nz-form-control nzErrorTip="Please input type correctly!">
          <input nz-input placeholder="Annual Leave" defaultValue="Annual Value" readonly />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="dateOfLeave" class="text-left">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              [nzDisabledDate]="disabledStartDate"
              formControlName="startValue"
              nzPlaceHolder="Start"
              (ngModelChange)="onChangeStart($event)"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
            ></nz-date-picker>
            <div style="margin-left: 4px; margin-right: 4px;">-</div>
            <nz-date-picker
              #endDatePicker
              [nzDisabledDate]="disabledEndDate"
              (ngModelChange)="onChangeEnd($event)"
              formControlName="endValue"
              nzPlaceHolder="End"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleEndOpenChange($event)"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="substitution" class="text-left">Substitution</nz-form-label>
        <nz-form-control nzErrorTip="Please input substitution correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a substitution"
            [(ngModel)]="selectedValue"
            formControlName="substitution"
          >
            <nz-option
              *ngFor="let option of listOfSubtitution"
              [nzLabel]="option.employee_name"
              [nzValue]="option.employee_code"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="reason" class="text-left">Reason</nz-form-label>
        <nz-form-control nzErrorTip="Please input reason correctly!">
          <input nz-input id="reason'" formControlName="reason" placeholder="Input reason" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div
      class="d-flex"
      style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
    >
      <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
      <button
        nz-button
        nzType="default"
        nzDanger
        style="background-color: rgb(231, 64, 64) !important; color: white;"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
