<form nz-form [formGroup]="formDetailLeaveHr">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="employee_name" class="text-left">Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            id="employee_name"
            formControlName="employee_name"
            disabled="true"
            class="col-9"
            style="margin-left:62px"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="leaveType" class="text-left">Leave Type</nz-form-label>

        <input nz-input id="type" formControlName="type" disabled="true" class="col-8" />
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="substitute_name" class="text-left">Substitution</nz-form-label>
        <nz-form-control nzErrorTip="Please input substitution correctly!">
          <input
            nz-input
            id="substitute_name'"
            formControlName="substitute_name"
            disabled="true"
            class="col-10"
            style="margin-left:24px"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="dateOfLeave" class="text-left">Date</nz-form-label>
        <div style="margin-left: 42px; display: flex; flex-direction: row; align-items: center;">
          <nz-date-picker
            formControlName="startValue"
            nzPlaceHolder="Start"
            nzFormat="dd-MMM-yyyy"
            [nzDefaultPickerValue]="formDetailLeaveHr.value.startValue"
            [nzDisabled]="true"
          ></nz-date-picker>
          <div style="margin-left: 4px; margin-right: 4px;">-</div>
          <nz-date-picker
            #endDatePicker
            formControlName="endValue"
            nzPlaceHolder="End"
            nzFormat="dd-MMM-yyyy"
            [nzDefaultPickerValue]="formDetailLeaveHr.value.endValue"
            [nzDisabled]="true"
          ></nz-date-picker>
        </div>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="reason" class="text-left">Reason</nz-form-label>
        <input
          nz-input
          id="reason'"
          formControlName="reason"
          disabled="true"
          class="col-8"
          style="margin-left:52px;"
        />
      </nz-form-item>
    </div>
  </div>

  <hr *ngIf="filterStatus === 'WAITING'" />
  <div class="col-12" *ngIf="filterStatus === 'WAITING'">
    <nz-form-item>
      <nz-form-label nzFor="notes_approval" class="text-left" style="font-weight: 600;"
        >Add Notes</nz-form-label
      >
      <input
        nz-input
        id="notes_approval"
        formControlName="notes_approval"
        class="col-10 input-notes"
      />
    </nz-form-item>
  </div>

  <div
    class="d-flex"
    style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
  >
    <button
      nz-button
      *ngIf="filterStatus === 'WAITING'"
      nzType="primary"
      (click)="approveLeave(leaveId)"
    >
      Approve
    </button>
    <button
      nz-button
      *ngIf="filterStatus === 'WAITING'"
      nzType="default"
      nzDanger
      style="background-color: rgb(231, 64, 64) !important; color: white;"
      (click)="rejectLeave(leaveId)"
    >
      Reject
    </button>
    <button nz-button nzType="" (click)="closeModal()">Cancel</button>
  </div>
</form>
