<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          class="table-Employeeing"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          [nzPageSizeOptions]="[25, 50, 100, 250]"
          [nzScroll]="{ x: '2500px', y: '500px' }"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzTitle]="headerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of listOfData">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td [nzLeft]="column.left" [nzRight]="column.right">
                  {{
                    column.key.startsWith('schedule')
                      ? data[column.key] !== null
                        ? data[column.key]
                        : '-'
                      : column.key === 'month'
                      ? getMonthName(data[column.key])
                      : data[column.key]
                  }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col">
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
        <a class="btn btn-lg btn-light mr-2" (click)="reset()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
      <span>
        <nz-date-picker
          nzMode="month"
          [(ngModel)]="date"
          (ngModelChange)="onChange($event)"
        ></nz-date-picker>
      </span>
    </div>
    <div class="col">
      <form
        nz-form
        [nzLayout]="'inline'"
        [formGroup]="form"
        (ngSubmit)="search()"
        class="float-right"
      >
        <nz-form-item>
          <nz-form-control>
            <input
              [formControl]="form.controls['keyword']"
              (keyup.enter)="search()"
              nz-input
              placeholder="Search placement ..."
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-template>
