import { Component, Input, OnInit, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core'
import { AssetManagementService } from 'src/app/services/asset-management.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PlacementForm } from 'src/app/services/interface/employee.model'
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal'
import { CapitalizeWord } from '../../../../shared/pipes/capitalize-word.pipe'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { HrService } from 'src/app/services/hr.service'
@Component({
  selector: 'app-view-placement',
  templateUrl: './view-placement.component.html',
  styleUrls: ['./view-placement.component.scss'],
})
export class ViewPlacementComponent implements OnInit {
  @Input() company: string
  @Input() config: string

  @ViewChild('toasterConfig', { static: true }) toasterConfigTemplate: TemplateRef<{}>
  confirmModal?: NzModalRef

  selectedConfig: string
  selectedCompany: string

  getPlacementValue: PlacementForm[] = []
  configData: string[] = []
  isLoading: boolean = true
  pageSize: number = 10
  pageIndex: number = 1

  filterForm: FormGroup
  addForm: FormGroup
  editForm: FormGroup
  placementForm: FormGroup

  isDownloading: boolean = false
  isOkLoading = false

  isAddConfigValueVisible: boolean = false
  isAddConfigValueConfirm: boolean = false

  isEditConfigValueVisible: boolean = false
  isEditConfigValueConfirm: boolean = false

  selectedPlacementValue: string = ''
  displayedConfigValue: string = ''
  displayedCode: string = ''

  messageConfigValue: string
  iconConfigValue: string
  colorConfigValue: string

  importExcelFile: NzUploadFile[] = []

  emptyData: PlacementForm[] = []

  constructor(
    private hrService: HrService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private notification: NzNotificationService,
  ) {
    this.filterForm = this.fb.group({
      config: [this.selectedConfig, Validators.compose([Validators.required])],
      search: ['', Validators.compose([Validators.required])],
    })

    this.addForm = this.fb.group({
      placementValue: ['', Validators.compose([Validators.required])],
      code: ['-', Validators.compose([Validators.required])],
    })

    this.editForm = this.fb.group({
      placementValue: ['', Validators.compose([Validators.required])],
      code: ['-', Validators.compose([Validators.required])],
    })

    this.placementForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
    })
  }

  listOfColumn = [
    {
      title: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '100px',
    },
    {
      title: 'Name',
      key: 'name',
      left: false,
      right: false,
      width: '130px',
      compare: (a: PlacementForm, b: PlacementForm) => a.name.localeCompare(b.name),
      priority: false,
    },
    {
      title: 'Created By',
      key: 'created_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: PlacementForm, b: PlacementForm) => a.created_by.localeCompare(b.created_by),
      priority: 4,
    },
    {
      title: 'Created At',
      key: 'created_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: PlacementForm, b: PlacementForm) => a.created_at.localeCompare(b.created_at),
      priority: 2,
    },
    {
      title: 'Updated By',
      key: 'updated_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: PlacementForm, b: PlacementForm) => a.updated_by.localeCompare(b.updated_by),
      priority: 3,
    },
    {
      title: 'Updated At',
      key: 'updated_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: PlacementForm, b: PlacementForm) => a.updated_at.localeCompare(b.updated_at),
      priority: 1,
    },
  ]

  ngOnInit(): void {
    //console.log(this.company, 'company data')
    //console.log(this.config, "config data")
    this.configData = []
    this.selectedCompany = this.company
    this.selectedConfig = this.config
    this.filterForm.get('config').setValue(this.selectedConfig)
    this.fetchPlacement()
  }

  add() {
    this.isAddConfigValueVisible = true
  }

  addConfigValue() {
    this.isAddConfigValueConfirm = true
    setTimeout(() => {
      this.isAddConfigValueVisible = false
      this.isAddConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload = {
        name: this.addForm.value.placementValue,
      }

      this.hrService.addEmployeePlacement(payload).subscribe(
        r => {
          this.messageConfigValue = r.message
          this.iconConfigValue = 'check-circle'
          this.colorConfigValue = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchPlacement()
        },
        error => {
          this.messageConfigValue = error.error.message
          this.iconConfigValue = 'warning'
          this.colorConfigValue = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  deletePlacement(code: number) {
    this.confirmModal = this.modal.confirm({
      nzWidth: '500px',
      nzTitle: `Do you want to delete this record?`,
      nzContent:
        'When clicked the OK button, everything associate with this value will be deleted also!',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 500)
        }).then(() => {
          setTimeout(() => {
            console.log(code)
            this.hrService.deleteEmployeePlacement(code).subscribe(
              r => {
                this.messageConfigValue = r.message
                this.iconConfigValue = 'check-circle'
                this.colorConfigValue = '#52c41a'
                this.createBasicNotification(this.toasterConfigTemplate)
                this.fetchPlacement()
              },
              error => {
                this.messageConfigValue = error.error.message
                this.iconConfigValue = 'warning'
                this.colorConfigValue = '#eb2f96'
                this.createBasicNotification(this.toasterConfigTemplate)
              },
            )
          }, 500)
        }),
    })
  }

  edit(data: any) {
    this.isEditConfigValueVisible = true
    this.displayedConfigValue = data.name
    this.selectedPlacementValue = data.name
    this.displayedCode = data.code
  }

  fetchPlacement() {
    this.isLoading = true
    this.hrService.getEmployeePlacement().subscribe((r: any) => {
      this.getPlacementValue = r
      for (let item of r) {
        this.configData.push(item)
      }
      this.isLoading = false
      // console.log(this.getConfig)
    })
  }

  apply() {
    this.selectedConfig = this.filterForm.value.config
  }

  editConfigValue(): void {
    this.isEditConfigValueConfirm = true

    setTimeout(() => {
      this.isEditConfigValueVisible = false
      this.isEditConfigValueConfirm = false

      const capitalizeWordPipe = new CapitalizeWord()
      const payload = {
        code: this.displayedCode,
        name: this.editForm.value.placementValue,
      }

      this.hrService.editEmployeePlacement(payload).subscribe(
        r => {
          this.messageConfigValue = r.message
          this.iconConfigValue = 'check-circle'
          this.colorConfigValue = '#52c41a'
          this.createBasicNotification(this.toasterConfigTemplate)
          this.fetchPlacement()
        },
        error => {
          this.messageConfigValue = error.error.message
          this.iconConfigValue = 'warning'
          this.colorConfigValue = '#eb2f96'
          this.createBasicNotification(this.toasterConfigTemplate)
        },
      )
    }, 500)
  }

  handleAddCancel(): void {
    this.isAddConfigValueVisible = false
  }

  handleEditCancel(): void {
    this.isEditConfigValueVisible = false
  }

  handleChangeImport(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.isLoading = true
        break
      case 'done':
        console.log(info.file.response, 'import')
        this.messageConfigValue = info.file.response.message
        this.iconConfigValue = 'check-circle'
        this.colorConfigValue = '#52c41a'
        this.createBasicNotification(this.toasterConfigTemplate)
        break
      case 'error':
        this.isLoading = false
        break
    }
  }

  // customImport = (item: NzUploadXHRArgs) => {
  //   const formData = new FormData()
  //   formData.append('file', item.file as any)
  //   formData.append('company', this.selectedCompany)
  //   this.assetManagementService.importVendor(formData).subscribe(
  //     r => {
  //       item.onSuccess(r, item.file, r)
  //       this.importExcelFile = []
  //     },
  //     error => {
  //       item.onError(error, item.file)
  //       console.log(error.error.message)
  //       this.messageConfigValue = error.error.message
  //       this.iconConfigValue = 'warning'
  //       this.colorConfigValue = '#eb2f96'
  //       this.createBasicNotification(this.toasterConfigTemplate)
  //       this.importExcelFile = []
  //     },
  //   )
  // }

  // formatNumber(event: any, formControl) {
  //   const inputValue = event.target.value
  //   const number = inputValue.replace(/[^\d.+]/g, '')
  //   this.vendorForm.get(formControl).setValue(number)
  // }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'bottomRight',
    })
  }
}
