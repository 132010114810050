<div>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <nz-table
            #attendanceTable
            [nzShowSizeChanger]="false"
            [nzData]="dataAttendances"
            [nzLoading]="isLoading"
            [nzTitle]="headerTable"
            nzSize="small"
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Late Status</th>
                <th>Time In</th>
                <th>Status In</th>
                <th>Reason In</th>
                <th>Early Status</th>
                <th>Time Out</th>
                <th>Status Out</th>
                <th>Reason Out</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of attendanceTable.data">
                <td>{{ formatMonth(data.date) }}</td>
                <td>
                  {{ data.in.statusLate ? 'TELAT' : 'TIDAK TELAT' }}
                </td>
                <td [ngStyle]="{ color: data.in.statusLate ? 'red' : 'green' }">
                  {{ data.in.timeIn }}
                </td>
                <td>{{ data.in.statusIn }}</td>
                <td>
                  {{ data.in.offsiteReason !== null ? data.in.offsiteReason : '-' }}
                </td>
                <td>
                  {{ data.out.statusEarly ? 'PULANG CEPAT' : 'TIDAK PULANG CEPAT' }}
                </td>

                <td [ngStyle]="{ color: data.out.statusEarly ? 'red' : 'green' }">
                  {{ data.out.timeOut }}
                </td>
                <td>{{ data.out.statusOut }}</td>
                <td>
                  {{ data.out.offsiteReason !== null || '' ? data.out.offsiteReason : '-' }}
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col ">Attendance</div>
    <div class="col float-right">
      <nz-form-item style="margin-bottom: 0;">
        <nz-form-label>Month</nz-form-label>
        <nz-form-control>
          <nz-date-picker
            nzMode="month"
            [formControl]="date"
            (ngModelChange)="fetchAttendancePersonal()"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</ng-template>
