import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ListOfEmployee } from 'src/app/services/interface/leave-management/leave.model'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-add-leave-config',
  templateUrl: './add-leave-config.component.html',
  styleUrls: ['./add-leave-config.component.scss'],
})
export class AddLeaveConfigComponent implements OnInit {
  formAddLeaveConfig: FormGroup
  isLoading: boolean = false
  selectedValue = true
  listOfEmployee: ListOfEmployee[] = []
  listOfShift = []
  selectedShift = null
  isIndividual: boolean = true
  selectedEmployee = null
  keyword = ''
  searchChange$ = new BehaviorSubject('')

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
  ) {
    this.formAddLeaveConfig = this.fb.group({
      individual: ['', [Validators.required]],
      employee_code: [''],
      quota: ['', [Validators.required]],
      shift_id: [''],
    })
  }

  ngOnInit(): void {
    this.fetchListEmployee()
    this.fetchLeaveShift()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    if (this.formAddLeaveConfig.valid) {
      let payload = {}

      if (this.isIndividual === true) {
        payload = {
          individual: this.formAddLeaveConfig.value.individual,
          employee_code: this.formAddLeaveConfig.value.employee_code,
          quota: this.formAddLeaveConfig.value.quota,
        }
      }

      if (this.isIndividual === false) {
        payload = {
          individual: this.formAddLeaveConfig.value.individual,
          shift_id: this.formAddLeaveConfig.value.shift_id,
          quota: this.formAddLeaveConfig.value.quota,
        }
      }

      this.modal.destroy()
      var formSubs: any = payload
      this.leaveManagementService.addQuotaAnnualLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Add Quota Leave')
          this.modal.destroy()
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  onCancel(): void {
    this.modal.destroy()
  }

  onTypeChange(value: boolean): void {
    this.isIndividual = value === true
    if (!this.isIndividual) {
      this.selectedEmployee = null
    }
  }

  fetchListEmployee() {
    this.isLoading = true
    this.leaveManagementService.getListEmployee(this.keyword).subscribe((r: any) => {
      this.isLoading = false
      this.listOfEmployee = r
    })
  }

  onSearch(value: string): void {
    this.isLoading = true
    this.searchChange$.next(value)
    this.keyword = value
  }

  fetchLeaveShift() {
    this.isLoading = true
    this.leaveManagementService.getListShift().subscribe(
      (r: any) => {
        this.isLoading = false
        this.listOfShift = r
      },
      (error: any) => {
        this.isLoading = false
      },
    )
  }
}
