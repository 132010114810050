<form nz-form [formGroup]="form">
    <div class="row">
        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="first_name" class="text-left">First Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input name correctly!">
                    <input nz-input id="'first_name'" formControlName="first_name"
                        placeholder="Input agent's first name.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="last_name" class="text-left">Last Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input name correctly!">
                    <input nz-input id="'last_name'" formControlName="last_name"
                        placeholder="Input agent's last name.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="username" class="text-left">Username</nz-form-label>
                <nz-form-control nzErrorTip="Please input username correctly!">
                    <input nz-input id="'username'" formControlName="username" placeholder="Input agent's username.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="password" class="text-left">Password</nz-form-label>
                <nz-form-control nzErrorTip="Please input password correctly! Password should be atleast 8 characters!">
                    <input nz-input id="'password'" formControlName="password" placeholder="Input agent's password.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="role">Agent Role</nz-form-label>
                <nz-form-control nzErrorTip="Please select role!">
                    <nz-select formControlName="role" nzPlaceHolder="Choose Role">
                        <nz-option *ngFor="let item of roleArr" [nzLabel]="item.title" [nzValue]="item.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                <nz-form-control nzErrorTip="Please input email correctly!">
                    <input nz-input id="'email'" formControlName="email" placeholder="Input email.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="phone" class="text-left">Phone No.</nz-form-label>
                <nz-form-control nzErrorTip="Please input number correctly!">
                    <input nz-input id="'phone'" formControlName="phone" placeholder="Input agent's phone.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-item>
                <nz-form-label nzFor="location_agent" class="text-left">Location Agent</nz-form-label>
                <nz-form-control nzErrorTip="Please input location agent correctly!">
                    <input nz-input id="'location_agent'" formControlName="location_agent"
                        placeholder="Input location agent.." />
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</form>