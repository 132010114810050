<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          class="table-Employeeing"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          [nzPageSizeOptions]="[25, 50, 100, 250]"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ x: '2500px', y: '80vh' }"
          [nzTitle]="headerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Employee">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewEmployee(data.employeeCode)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                </td>
                <td
                  *ngIf="column.key == 'pictureIn' || column.key == 'pictureOut'"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                >
                  <a (click)="openImage(data[column.key])">
                    <ng-container *ngIf="data[column.key] !== null; else dashTemplate">
                      <img
                        style="width: 160px; height: 160px"
                        [src]="data[column.key]"
                        alt="picture-in-out"
                      />
                    </ng-container>
                    <ng-template #dashTemplate>
                      <span>-</span>
                    </ng-template>
                  </a>
                </td>

                <td
                  *ngIf="
                    column.key != 'actions' &&
                    column.key != 'pictureIn' &&
                    column.key != 'pictureOut' && 
                    column.key != 'attachment'
                  "
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [ngClass]="{
                    'red-text':
                      (column.key === 'createdAt' && data.late) ||
                      (column.key === 'updatedAt' && data.early),
                    'green-text':
                      (column.key === 'createdAt' && !data.late) ||
                      (column.key === 'updatedAt' && !data.early)
                  }"
                >
                  {{ data[column.key] }}
                </td>

                <td
                  *ngIf="
                    column.key == 'attachment'
                  "
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                >
                  <ng-container *ngIf="data[column.key] !== null; else dashTemplate">
                    <a [href]="data[column.key]" target="_blank">
                      <button nz-button [nzType]="'primary'">Download attachment</button>
                    </a>
                  </ng-container>
                  <ng-template #dashTemplate>
                    <span>-</span>
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
<nz-modal
  [nzVisible]="previewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="previewVisible = false"
>
  <ng-template #modalContent style="width: 100%; height: 100%;">
    <img [src]="previewImage" />
  </ng-template>
</nz-modal>
<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col">
      <nz-form-label nzFor="sort" class="text-left">Sort by date: </nz-form-label>
      <nz-date-picker
        [(ngModel)]="date"
        (ngModelChange)="onChange($event)"
        [nzDisabledDate]="disabledDate"
        [nzAllowClear]="false"
      ></nz-date-picker>
      <span> - </span>
      <button nz-button [nzType]="'primary'" (click)="showModal()">
        <span>Export By Month</span>
      </button>
    </div>
    <div class="col">
      <form
        nz-form
        [nzLayout]="'inline'"
        [formGroup]="form"
        (ngSubmit)="search()"
        class="float-right"
      >
        <nz-form-item>
          <nz-form-label nzFor="prefix" class="text-left">Prefix</nz-form-label>
          <nz-form-control>
            <nz-select
              id="prefix"
              [formControl]="form.controls['prefix']"
              [(ngModel)]="prefixDefault"
              nzPlaceHolder="Select Prefix ..."
              style="width: 150px;"
              (ngModelChange)="prefixSearchCategory($event)"
            >
              <nz-option nzValue="employeeCode" nzLabel="Employee Code"></nz-option>
              <nz-option nzValue="employeeName" nzLabel="Employee Name"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <!-- name and employee code search -->
            <div *ngIf="defaultprefixCategory">
              <input [formControl]="form.controls['keyword']" nz-input placeholder="Keyword..." />
            </div>
            <!-- employee shift search -->
            <div *ngIf="prefixCategoryshift">
              <nz-select
                [formControl]="form.controls['keyword']"
                nzAllowClear
                nzPlaceHolder="Select..."
              >
                <nz-option
                  *ngFor="let shiftvalue of shiftList"
                  [nzValue]="shiftvalue.shift_name"
                  [nzLabel]="shiftvalue.shift_name"
                ></nz-option>
              </nz-select>
            </div>
            <!-- status in and out search -->
            <div *ngIf="prefixCategoryStatus">
              <nz-select
                [formControl]="form.controls['keyword']"
                nzPlaceHolder="Select..."
                nzAllowClear
              >
                <nz-option nzValue="onsite" nzLabel="onsite"></nz-option>
                <nz-option nzValue="offsite" nzLabel="offsite"></nz-option>
              </nz-select>
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Excel">
          <button nz-button nzType="secondary" (click)="onExport()">
            <span>Export</span>
          </button>
          <a #downloadLink id="downloadLink" style="display: none;"></a>
        </span>
      </form>
    </div>
  </div>
</ng-template>

<nz-modal
  nzWidth="300px"
  [(nzVisible)]="isVisible"
  nzTitle="Choose Month To Export"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
>
  <ng-container *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="12" nzOffset="6">
        <nz-date-picker
          nzMode="month"
          [(ngModel)]="dateMonth"
          [nzDisabledDate]="disabledDate"
          (ngModelChange)="onChangeMonth($event)"
        ></nz-date-picker>
      </div>
    </div>
  </ng-container>
</nz-modal>

<ng-template #modalFooter>
  <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
  <button nz-button nzType="primary" (click)="onExportByMonth()" [nzLoading]="isConfirmLoading">
    Export
  </button>
</ng-template>
