import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared.module'
import { LayoutsModule } from 'src/app/layouts/layouts.module'
import { AppPreloader } from 'src/app/app-routing-loader'
import { AuthGuard } from 'src/app/@vb/components/Guard/auth.guard'
import { WidgetsComponentsModule } from 'src/app/@vb/widgets/widgets-components.module'

// layouts & notfound
import { LayoutAuthComponent } from 'src/app/layouts/Auth/auth.component'
import { LayoutMainComponent } from 'src/app/layouts/Main/main.component'

// pages
// VB:REPLACE-START:ROUTER-IMPORTS
import { DashboardComponent } from './pages/dashboard/dashboard.component'
import { TableComponent } from './pages/table/table.component'
import { TicketingComponent } from './pages/ticketing/ticketing.component'
import { AddTicketComponent } from './pages/ticketing/add-ticket/add-ticket.component'
import { ViewTicketComponent } from './pages/ticketing/view-ticket/view-ticket.component'
import { AgentComponent } from './pages/administration/agent/agent.component'
import { VendorComponent } from './pages/administration/vendor/vendor.component'
import { AddAgentComponent } from './pages/administration/agent/add-agent/add-agent.component'
import { AddVendorComponent } from './pages/administration/vendor/add-vendor/add-vendor.component'
import { ViewAgentComponent } from './pages/administration/agent/view-agent/view-agent.component'
import { ViewVendorComponent } from './pages/administration/vendor/view-vendor/view-vendor.component'
import { ProblemTypeComponent } from './pages/administration/problem-type/problem-type.component'
import { AddProblemTypeComponent } from './pages/administration/problem-type/add-problem-type/add-problem-type.component'
import { ViewProblemTypeComponent } from './pages/administration/problem-type/view-problem-type/view-problem-type.component'
import { AgentManagementComponent } from './pages/agent-management/agent-management.component'
import { AddAgentAccountComponent } from './pages/agent-management/add-agent/add-agent.component'
import { EditAgentComponent } from './pages/agent-management/edit-agent/edit-agent.component'
import { EmployeeManagementComponent } from './pages/hr/employee-management/employee-management.component'
import { AddEmployeeComponent } from './pages/hr/employee-management/add-employee/add-employee.component'
import { EditEmployeeComponent } from './pages/hr/employee-management/edit-employee/edit-employee.component'
import { AttendanceComponent } from './pages/hr/attendance/attendance.component'
import { ShiftComponent } from './pages/hr/shift-management/shift/shift.component'
import { EmployeeShiftComponent } from './pages/hr/shift-management/employee-shift/employee-shift.component'
import { UserProfileComponent } from './pages/user-profile/user-profile.component'
import { ChangePasswordComponent } from './pages/user-profile/change-password/change-password.component'
import { StatisticsTicketingComponent } from './pages/ticketing/statistics-ticketing/statistics-ticketing.component'
import { DriverManagementComponent } from './pages/onfleet/driver-management/driver-management.component'
import { WarehouseManagementComponent } from './pages/onfleet/warehouse-management/warehouse-management.component'
import { TripLogComponent } from './pages/onfleet/trip-log/trip-log.component'
import { AddDriverComponent } from './pages/onfleet/driver-management/add-driver/add-driver.component'
import { EditDriverComponent } from './pages/onfleet/driver-management/edit-driver/edit-driver.component'
import { AddWarehouseComponent } from './pages/onfleet/warehouse-management/add-warehouse/add-warehouse.component'
import { BannerComponent } from './pages/cms/banner/banner.component'
import { BlogComponent } from './pages/cms/blog/blog.component'
import { AddBlogComponent } from './pages/cms/blog/add-blog/add-blog.component'
import { NgxEditorModule } from 'ngx-editor'
import { AddBannerComponent } from './pages/cms/banner/add-banner/add-banner.component'
import { VacancyComponent } from './pages/cms/vacancy/vacancy.component'
import { AddVacancyComponent } from './pages/cms/vacancy/add-vacancy/add-vacancy.component'
import { EditBannerComponent } from './pages/cms/banner/edit-banner/edit-banner.component'
import { EditBlogComponent } from './pages/cms/blog/edit-blog/edit-blog.component'
import { DropPointManagementComponent } from './pages/cms/drop-point-management/drop-point-management.component'
import { AddDropPointComponent } from './pages/cms/drop-point-management/add-drop-point/add-drop-point.component'
import { ViewDropPointComponent } from './pages/cms/drop-point-management/view-drop-point/view-drop-point.component'
import { PartnershipComponent } from './pages/cms/ondelivery-cms/partnership/partnership.component'
import { WaybillLotteryComponent } from './pages/cms/ondelivery-cms/waybill-lottery/waybill-lottery.component'
import { EditVacancyComponent } from './pages/cms/vacancy/edit-vacancy/edit-vacancy.component'
import { DepartmentComponent } from './pages/cms/department/department.component'
import { EditDepartmentComponent } from './pages/cms/department/edit-department/edit-department.component'
import { AddDepartmentComponent } from './pages/cms/department/add-department/add-department.component'
import { TrainingComponent } from './pages/cms/training/training.component'
import { AddTrainingComponent } from './pages/cms/training/add-training/add-training.component'
import { EditTrainingComponent } from './pages/cms/training/edit-training/edit-training.component'
import { TrainingViewsComponent } from './pages/cms/training-views/training-views.component'

// import by ilham
import { MeetingRoomManagementComponent } from './pages/hr/meeting-management/meeting-room/meeting-room.component'
import { AddRoomComponent } from './pages/hr/meeting-management/meeting-room/add-room/add-room.component'
import { EditRoomComponent } from './pages/hr/meeting-management/meeting-room/edit-room/edit-room.component'
import { ScheduleRoomManagementComponent } from './pages/hr/meeting-management/schedule-room/schedule-room.component'
import { AddScheduleComponent } from './pages/hr/meeting-management/schedule-room/add-schedule/add-schedule.component'
import { EditScheduleComponent } from './pages/hr/meeting-management/schedule-room/edit-schedule/edit-schedule.component'
import { FreelanceAgentComponent } from './pages/freelance-agent/freelance-agent.component'

import { AddFreelanceAgentComponent } from './pages/freelance-agent/add-freelance-agent/add-freelance-agent.component'
import { EditFreelanceAgentComponent } from './pages/freelance-agent/edit-freelance-agent/edit-freelance-agent.component'

import { OnMitraPendingComponent } from './pages/on-mitra-management/on-mitra-pending/on-mitra-pending.component'
import { EditPendingComponent } from './pages/on-mitra-management/on-mitra-pending/edit-pending/edit-pending.component'
import { OnMitraVerifiedComponent } from './pages/on-mitra-management/on-mitra-verified/on-mitra-verified.component'
import { EditVerifiedComponent } from './pages/on-mitra-management/on-mitra-verified/edit-verified/edit-verified.component'

import { AddShiftComponent } from './pages/hr/shift-management/shift/add-shift/add-shift.component'
import { EditShiftComponent } from './pages/hr/shift-management/shift/edit-shift/edit-shift.component'
import { TakeLeaveComponent } from './pages/hr/leave-management/take-leave/take-leave.component'
import { ManageLeaveComponent } from './pages/hr/leave-management/manage-leave/manage-leave.component'
import { EditLeaveComponent } from './pages/hr/leave-management/manage-leave/edit-leave/edit-leave.component'
import { AddLeaveComponent } from './pages/hr/leave-management/take-leave/add-leave/add-leave.component'
import { ViewLeaveComponent } from './pages/hr/leave-management/take-leave/view-leave/view-leave.component'
import { PositionManagementComponent } from './pages/hr/position-management/position-management.component'
import { AddPositionComponent } from './pages/hr/position-management/add-position/add-position.component'
import { EditPositionComponent } from './pages/hr/position-management/edit-position/edit-position.component'
import { VoucherComponent } from './pages/onapps-management/voucher/voucher.component'
import { AddVoucherComponent } from './pages/onapps-management/voucher/add-voucher/add-voucher.component'
import { EditVoucherComponent } from './pages/onapps-management/voucher/edit-voucher/edit-voucher.component'
import { BusinessInquiryComponent } from './pages/cta/business-inquiry/business-inquiry.component'
import { ViewInquiryComponent } from './pages/cta/business-inquiry/view-inquiry/view-inquiry.component'
import { AgentInquiryComponent } from './pages/cta/agent-inquiry/agent-inquiry.component'
import { ViewAgentInquiryComponent } from './pages/cta/agent-inquiry/view-agent-inquiry/view-agent-inquiry.component'
import { ExportAgentInquiryComponent } from './pages/cta/agent-inquiry/export-agent-inquiry/export-agent-inquiry.component'
import { ExportBusinessInquiryComponent } from './pages/cta/business-inquiry/export-business-inquiry/export-business-inquiry.component'
import { NewUserVoucherComponent } from './pages/onapps-management/new-user-voucher/new-user-voucher.component'
import { AssetManagementComponent } from './pages/asset-management/asset-management.component'
import { AssetConfigurationComponent } from './pages/asset-management/asset-configuration/asset-configuration.component'
import { AssetTrackingComponent } from './pages/asset-management/asset-tracking/asset-tracking.component'
import { NewUserConfigsComponent } from './pages/onapps-management/new-user-voucher/new-user-configs/new-user-configs.component'
import { NewUserListsComponent } from './pages/onapps-management/new-user-voucher/new-user-lists/new-user-lists.component'
import { EditNewUserConfigsComponent } from './pages/onapps-management/new-user-voucher/new-user-configs/edit-new-user-configs/edit-new-user-configs.component'
import { CreateNewUserConfigsComponent } from './pages/onapps-management/new-user-voucher/new-user-configs/create-new-user-configs/create-new-user-configs.component'
import { EditNewUserListsComponent } from './pages/onapps-management/new-user-voucher/new-user-lists/edit-new-user-lists/edit-new-user-lists.component'
import { ViewConfigurationComponent } from './pages/asset-management/asset-configuration/view-configuration/view-configuration.component'
import { LocationManagementComponent } from './pages/hr/location-management/location-management.component'
import { AddLocationComponent } from './pages/hr/location-management/add-location/add-location.component'
import { EditLocationComponent } from './pages/hr/location-management/edit-location/edit-location.component'
import { ViewPlacementComponent } from './pages/hr/employee-management/view-placement/view-placement.component'
import { CandidateComponent } from './pages/freelance-agent/freelance-marketing/candidate/candidate.component'
import { ExistingComponent } from './pages/freelance-agent/freelance-marketing/existing/existing.component'
import { ReservedComponent } from './pages/freelance-agent/freelance-marketing/reserved/reserved.component'
import { FreelanceMarketingComponent } from './pages/freelance-agent/freelance-marketing/freelance-marketing.component'
import { CreateShiftSchedulerComponent } from './pages/hr/shift-management/shift-scheduler/create-shift-scheduler/create-shift-scheduler.component'
import { ListShiftSchedulerComponent } from './pages/hr/shift-management/shift-scheduler/list-shift-scheduler/list-shift-scheduler.component'
import { ShiftSchedulerComponent } from './pages/hr/shift-management/shift-scheduler/shift-scheduler.component'
import { AttendanceNormalComponent } from './pages/hr/attendance/normal-shift/normal-attendance.component'
import { AttendanceSalesComponent } from './pages/hr/attendance/sales-shift/sales-attendance.component'
import { SalesReportComponent } from './pages/sales/sales-report/sales-report.component'

//Leave
import { LeaveApprovalHrComponent } from './pages/hr/leave-request/leave-approval-hr/leave-approval-hr.component'
import { AnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/annual-leave.component'
import { SpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/special-leave.component'
import { AddAnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/add-annual-leave/add-annual-leave.component'
import { AddSpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/add-special-leave/add-special-leave.component'
import { ViewDetailLeaveComponent } from './pages/hr/leave-request/leave-approval-hr/view-detail-leave/view-detail-leave.component'
import { TabWaitingSpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/tab-waiting-special-leave/tab-waiting-special-leave.component'
import { TabApprovedSpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/tab-approved-special-leave/tab-approved-special-leave.component'
import { TabCancelledSpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/tab-cancelled-special-leave/tab-cancelled-special-leave.component'
import { LeaveCollectiveComponent } from './pages/hr/leave-request/leave-collective/leave-collective.component'
import { AddCollectiveLeaveComponent } from './pages/hr/leave-request/leave-collective/add-collective-leave/add-collective-leave.component'
import { TabWaitingLeaveApprovalComponent } from './pages/hr/leave-request/leave-approval-hr/tab-waiting-leave-approval/tab-waiting-leave-approval.component'
import { TabApprovedLeaveApprovalComponent } from './pages/hr/leave-request/leave-approval-hr/tab-approved-leave-approval/tab-approved-leave-approval.component'
import { TabCancelledLeaveApprovalComponent } from './pages/hr/leave-request/leave-approval-hr/tab-cancelled-leave-approval/tab-cancelled-leave-approval.component'
import { LeaveConfigComponent } from './pages/hr/leave-request/leave-config/leave-config.component'
import { AddLeaveConfigComponent } from './pages/hr/leave-request/leave-config/add-leave-config/add-leave-config.component'
import { ViewDetailAnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/view-detail-annual-leave/view-detail-annual-leave.component'
import { ViewDetailCollectiveLeaveComponent } from './pages/hr/leave-request/leave-collective/view-detail-collective-leave/view-detail-collective-leave.component'
import { ViewDetailSpecialLeaveComponent } from './pages/leave-management/leave-request/special-leave/view-detail-special-leave/view-detail-special-leave.component'
import { TabWaitingAnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/tab-waiting-annual-leave/tab-waiting-annual-leave.component'
import { TabApprovedAnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/tab-approved-annual-leave/tab-approved-annual-leave.component'
import { TabCancelledAnnualLeaveComponent } from './pages/leave-management/leave-request/annual-leave/tab-cancelled-annual-leave/tab-cancelled-annual-leave.component'

// VB:REPLACE-END:ROUTER-IMPORTS

const routes: Routes = [
  {
    path: '',
    // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutMainComponent,
    canActivate: [AuthGuard],
    children: [
      // VB:REPLACE-START:ROUTER-CONFIG
      {
        path: 'user-profile',
        data: { title: 'User Profile' },
        component: UserProfileComponent,
        children: [
          {
            path: 'change-password',
            data: { title: 'Change Password' },
            component: ChangePasswordComponent,
          },
        ],
      },
      {
        path: 'dashboard',
        data: { title: 'Dashboard' },
        component: DashboardComponent,
      },
      {
        path: 'table',
        data: { title: 'Table' },
        component: TableComponent,
      },

      {
        path: 'ticketing',
        data: { title: 'Ticketing' },
        children: [
          {
            path: 'statistics',
            data: { title: 'Statistics' },
            component: StatisticsTicketingComponent,
          },
          {
            path: 'tickets',
            data: { title: 'Tickets' },
            component: TicketingComponent,
          },
        ],
      },
      {
        path: 'administration',
        data: { title: 'Administration' },
        children: [
          {
            path: 'agent',
            data: { title: 'Agent' },
            component: AgentComponent,
          },
          {
            path: 'agent-management',
            data: { title: 'Agent Management' },
            component: AgentManagementComponent,
          },
          {
            path: 'vendor',
            data: { title: 'Vendor' },
            component: VendorComponent,
          },
          {
            path: 'problem-type',
            data: { title: 'Problem Type' },
            component: ProblemTypeComponent,
          },
        ],
      },
      {
        path: 'human-resource',
        data: { title: 'Human Resource' },
        children: [
          {
            path: 'employee-management',
            data: { title: 'Employee Management' },
            component: EmployeeManagementComponent,
          },
          {
            path: 'position-management',
            data: { title: 'Position Management' },
            component: PositionManagementComponent,
          },
          {
            path: 'location-management',
            data: { title: 'Location Management' },
            component: LocationManagementComponent,
          },
          {
            path: 'meeting-management',
            data: { title: 'Meeting Management' },
            children: [
              {
                path: 'meeting-room',
                data: { title: 'Meeting Room' },
                component: MeetingRoomManagementComponent,
              },
              {
                path: 'schedule-room',
                data: { title: 'Schedule Room' },
                component: ScheduleRoomManagementComponent,
              },
            ],
          },
          {
            path: 'attendance',
            data: { title: 'Attendance' },
            component: AttendanceComponent,
          },
          {
            path: 'shift-management',
            data: { title: 'Shift Management' },
            children: [
              {
                path: 'shift',
                data: { title: 'Shift' },
                component: ShiftComponent,
              },
              {
                path: 'employee-shift',
                data: { title: 'Employee Shift' },
                component: EmployeeShiftComponent,
              },
              {
                path: 'shift-scheduler',
                data: { title: 'Shift Scheduler' },
                component: ShiftSchedulerComponent,
              },
            ],
          },
          {
            path: 'leave-hr-management',
            data: { title: 'Leave Management' },
            children: [
              {
                path: 'leave-approval-hr',
                data: { title: 'Leave Approval HR' },
                component: LeaveApprovalHrComponent,
              },
              {
                path: 'leave-collective',
                data: { title: 'Leave Collective' },
                component: LeaveCollectiveComponent,
              },
              {
                path: 'leave-config',
                data: { title: 'Leave Config' },
                component: LeaveConfigComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'onfleet',
        data: { title: 'On Fleet Management' },
        children: [
          {
            path: 'driver-management',
            data: { title: 'Driver Management' },
            component: DriverManagementComponent,
          },
          {
            path: 'fleet-management',
            data: { title: 'Fleet Management' },
            component: WarehouseManagementComponent,
          },
          {
            path: 'trip-log',
            data: { title: 'Trip Log' },
            component: TripLogComponent,
          },
        ],
      },
      {
        path: 'cms',
        data: { title: 'CMS' },
        children: [
          {
            path: 'banner',
            data: { title: 'Banner Management' },
            component: BannerComponent,
          },
          {
            path: 'blog',
            data: { title: 'Blog Management' },
            component: BlogComponent,
          },
          {
            path: 'vacancy',
            data: { title: 'Vacancy' },
            component: VacancyComponent,
          },
          {
            path: 'department',
            data: { title: 'Department' },
            component: DepartmentComponent,
          },
          {
            path: 'droppoint',
            data: { title: 'Drop Point Management' },
            component: DropPointManagementComponent,
          },
          {
            path: 'training',
            data: { title: 'Training' },
            children: [
              {
                path: 'training-management',
                data: { title: 'Training Management' },
                component: TrainingComponent,
              },
              {
                path: 'views-management',
                data: { title: 'Training Views' },
                component: TrainingViewsComponent,
              },
            ],
          },
          {
            path: 'ondelivery-cms',
            data: { title: 'ONDELIVERY CMS' },
            children: [
              {
                path: 'partnership',
                data: { title: 'Partnership' },
                component: PartnershipComponent,
              },
              {
                path: 'waybill-lottery',
                data: { title: 'Waybill Lottery' },
                component: WaybillLotteryComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'leave-management',
        data: { title: 'Leave Management' },
        children: [
          {
            path: 'leave-request',
            data: { title: 'Leave Request' },
            children: [
              {
                path: 'annual-leave',
                data: { title: 'Annual Leave' },
                component: AnnualLeaveComponent,
              },
              {
                path: 'special-leave',
                data: { title: 'Special Leave' },
                component: SpecialLeaveComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'approval',
        loadChildren: () =>
          import('./pages/branding-approval/branding-approval.module').then(
            m => m.BrandingApprovalModule,
          ),
      },
      {
        path: 'freelance-management',
        data: { title: 'Freelance Management' },
        children: [
          {
            path: 'freelance-marketing',
            data: { title: 'Freelance Marketing' },
            component: FreelanceMarketingComponent,
          },
        ],
      },
      {
        path: 'mitra-management',
        data: { title: 'On Mitra Management' },
        children: [
          {
            path: 'pending',
            data: { title: 'Pending Mitra' },
            component: OnMitraPendingComponent,
          },
          {
            path: 'verified',
            data: { title: 'Verified Mitra' },
            component: OnMitraVerifiedComponent,
          },
        ],
      },
      {
        path: 'onapps-management',
        data: { title: 'OnApps Management' },
        children: [
          {
            path: 'voucher',
            data: { title: 'Voucher' },
            component: VoucherComponent,
          },
          {
            path: 'new-user-voucher',
            data: { title: 'New User Voucher' },
            component: NewUserVoucherComponent,
          },
        ],
      },
      {
        path: 'cta',
        data: { title: 'CTA' },
        children: [
          {
            path: 'business-inquiry',
            data: { title: 'Business Inquiry' },
            component: BusinessInquiryComponent,
          },
          {
            path: 'agent-inquiry',
            data: { title: 'Agent Inquiry' },
            component: AgentInquiryComponent,
          },
        ],
      },
      {
        path: 'asset-management',
        data: { title: 'Asset Management' },
        children: [
          {
            path: 'asset-inv',
            data: { title: 'Asset Inventory' },
            component: AssetManagementComponent,
          },
          {
            path: 'asset-conf',
            data: { title: 'Asset Configuration' },
            component: AssetConfigurationComponent,
          },
          {
            path: 'asset-track',
            data: { title: 'Asset Tracking' },
            component: AssetTrackingComponent,
          },
          {
            path: 'view-configuration',
            data: { title: 'View Configuration' },
            component: ViewConfigurationComponent,
          },
        ],
      },
      {
        path: 'sales',
        data: { title: 'Sales' },
        children: [
          {
            path: 'sales-report',
            data: { title: 'Sales Report' },
            component: SalesReportComponent,
          },
        ],
      },
      // VB:REPLACE-END:ROUTER-CONFIG
    ],
  },
  {
    path: 'auth',
    component: LayoutAuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/pages/auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/404',
  },
]

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppPreloader,
      relativeLinkResolution: 'legacy',
    }),
    LayoutsModule,
    WidgetsComponentsModule,
    NgxEditorModule,
  ],
  declarations: [
    // VB:REPLACE-START:ROUTER-DECLARATIONS
    DashboardComponent,
    TableComponent,

    // Ticketing
    TicketingComponent,
    AddTicketComponent,
    ViewTicketComponent,
    StatisticsTicketingComponent,

    // Asset Management
    AssetManagementComponent,
    AssetConfigurationComponent,
    AssetTrackingComponent,
    ViewConfigurationComponent,

    // Administration
    AgentComponent,
    AddAgentComponent,
    ViewAgentComponent,
    AgentManagementComponent,
    AddAgentAccountComponent,
    EditAgentComponent,
    VendorComponent,
    AddVendorComponent,
    ViewVendorComponent,
    ProblemTypeComponent,
    AddProblemTypeComponent,
    ViewProblemTypeComponent,

    // Human Resources
    EmployeeManagementComponent,
    AddEmployeeComponent,
    ViewPlacementComponent,
    EditEmployeeComponent,
    MeetingRoomManagementComponent,
    AddRoomComponent,
    EditRoomComponent,
    ScheduleRoomManagementComponent,
    AddScheduleComponent,
    EditScheduleComponent,
    AttendanceComponent,
    AttendanceNormalComponent,
    AttendanceSalesComponent,
    ShiftComponent,
    AddShiftComponent,
    EditShiftComponent,
    EmployeeShiftComponent,
    TakeLeaveComponent,
    AddLeaveComponent,
    ManageLeaveComponent,
    EditLeaveComponent,
    ViewLeaveComponent,
    PositionManagementComponent,
    AddPositionComponent,
    EditPositionComponent,
    LocationManagementComponent,
    AddLocationComponent,
    EditLocationComponent,
    ShiftSchedulerComponent,
    CreateShiftSchedulerComponent,
    ListShiftSchedulerComponent,
    SalesReportComponent,

    //FLEET MANAGEMENT
    AddWarehouseComponent,
    EditDriverComponent,
    AddDriverComponent,
    DriverManagementComponent,
    WarehouseManagementComponent,
    TripLogComponent,

    //FREELANCE AGENT
    FreelanceAgentComponent,
    FreelanceMarketingComponent,
    AddFreelanceAgentComponent,
    EditFreelanceAgentComponent,
    CandidateComponent,
    ExistingComponent,
    ReservedComponent,

    //ON MITRA MANAGEMENT
    OnMitraPendingComponent,
    EditPendingComponent,
    OnMitraVerifiedComponent,
    EditVerifiedComponent,

    //CMS
    WaybillLotteryComponent,
    PartnershipComponent,
    AddDropPointComponent,
    DropPointManagementComponent,
    ViewDropPointComponent,
    EditBlogComponent,
    AddBlogComponent,
    EditBannerComponent,
    AddBannerComponent,
    BannerComponent,
    BlogComponent,
    VacancyComponent,
    AddVacancyComponent,
    EditVacancyComponent,
    TrainingComponent,
    AddTrainingComponent,
    EditTrainingComponent,
    TrainingViewsComponent,
    DepartmentComponent,
    EditDepartmentComponent,
    AddDepartmentComponent,

    //ONAPPS MANAGEMENT
    VoucherComponent,
    AddVoucherComponent,
    EditVoucherComponent,
    NewUserVoucherComponent,
    NewUserConfigsComponent,
    EditNewUserConfigsComponent,
    CreateNewUserConfigsComponent,
    NewUserListsComponent,
    EditNewUserListsComponent,

    //CMS
    BusinessInquiryComponent,
    ViewInquiryComponent,
    ExportBusinessInquiryComponent,
    AgentInquiryComponent,
    ViewAgentInquiryComponent,
    ExportAgentInquiryComponent,

    //Core Web
    UserProfileComponent,
    ChangePasswordComponent,
    // VB:REPLACE-END:ROUTER-DECLARATIONS

    //Leave Management
    AnnualLeaveComponent,
    SpecialLeaveComponent,
    AddAnnualLeaveComponent,
    AddSpecialLeaveComponent,
    TabWaitingSpecialLeaveComponent,
    TabApprovedSpecialLeaveComponent,
    TabCancelledSpecialLeaveComponent,
    ViewDetailAnnualLeaveComponent,
    ViewDetailSpecialLeaveComponent,
    TabWaitingAnnualLeaveComponent,
    TabApprovedAnnualLeaveComponent,
    TabCancelledAnnualLeaveComponent,

    //HR Management
    LeaveApprovalHrComponent,
    ViewDetailLeaveComponent,
    LeaveCollectiveComponent,
    AddCollectiveLeaveComponent,
    TabWaitingLeaveApprovalComponent,
    TabApprovedLeaveApprovalComponent,
    TabCancelledLeaveApprovalComponent,
    LeaveConfigComponent,
    AddLeaveConfigComponent,
    ViewDetailCollectiveLeaveComponent,
  ],
  providers: [AppPreloader],
  exports: [RouterModule],
})
export class AppRoutingModule {}
