import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { OnMitraService } from 'src/app/services/on-mitra.service'
import { Mitra } from 'src/app/services/interface/on-mitra.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { EditVerifiedComponent } from './edit-verified/edit-verified.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-on-mitra-verified',
  templateUrl: './on-mitra-verified.component.html',
})
export class OnMitraVerifiedComponent implements OnInit {
  listOfData: Mitra[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private mitraService: OnMitraService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['email', Validators.compose([Validators.required])],
    })
  }

  mitraStatus = [
    {
      title: 'Active',
      key: 'active',
      selected: false,
      disabled: false,
    },
    {
      title: 'Disabled',
      key: 'disabled',
      selected: false,
      disabled: false,
    },
  ]

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '70px',
    },
    {
      name: 'First Name',
      key: 'first_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Last Name',
      key: 'last_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Email',
      key: 'email',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Status',
      key: 'disabled',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '70px',
    },
    {
      name: 'Vehicle Type',
      key: 'vehicle_type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Verified By',
      key: 'verified_by',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Updated By',
      key: 'updated_by',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Disabled By',
      key: 'disabled_by',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Verified At',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Updated At',
      key: 'updated_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Disabled At',
      key: 'disabled_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
  ]

  ngOnInit(): void {
    this.fetchMitra()
  }

  fetchMitra() {
    this.isLoading = true
    this.mitraService.getVerified(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchMitra()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchMitra()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'email' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchMitra()
  }

  changeType(event: any) {
    var filterObj = {
      field: 'disabled',
      keyword: '',
    }
    if (event.key == 'active') {
      filterObj.keyword = 'false'
    } else if (event.key == 'disabled') {
      filterObj.keyword = 'true'
    }
    var isExist = false
    for (let i = 0; i < this.queryTable.filters.length; i++) {
      if (this.queryTable.filters[i].field == 'disabled') {
        isExist = true
        this.queryTable.filters[i].keyword = filterObj.keyword
      }
    }
    if (!isExist) this.queryTable.filters.push(filterObj)
    this.fetchMitra()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  viewMitra(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Pending Mitra',
      nzContent: EditVerifiedComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      // nzOkText: 'Update',
      // nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchMitra()
    })
  }
}
