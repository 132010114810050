<div class="row">
  <div class="col-3">
    <ng-container *ngIf="(image != null); else elseBlock">
      <nz-avatar [nzShape]="'square'" [nzSize]="200" [nzSrc]=image.url></nz-avatar>
    </ng-container>
    <ng-template #elseBlock>
      <img nz-image height="200px" nzSrc="https://sunshineapi.onindonesia.id/api/employee/image/default.png" alt="" />
    </ng-template>
  </div>
  <div class="col-lg-8 col-md-6">
    <form [formGroup]="form" nz-form [nzLayout]="'horizontal'">
      <div class="row">
        <div class="col-4">
          <nz-form-item>
            <nz-form-control>
              <nz-form-label>Username</nz-form-label>
              <input nz-input formControlName="username" placeholder="Username..." readonly />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-4">
          <nz-form-item>
            <nz-form-control>
              <nz-form-label>Code</nz-form-label>
              <input nz-input formControlName="code" placeholder="Code..." readonly />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-4">
          <nz-form-item>
            <nz-form-control>
              <nz-form-label>Email</nz-form-label>
              <input nz-input formControlName="email" placeholder="Email..." readonly />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-4">
          <nz-form-item>
            <nz-form-control>
              <nz-form-label>Role</nz-form-label>
              <input nz-input formControlName="role" placeholder="Role..." readonly />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-4">
          <nz-form-item>
            <nz-form-control>
              <nz-form-label>Position</nz-form-label>
              <input nz-input formControlName="position" placeholder="Position..." readonly />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row mb-5">
  <div class="col-3"></div>
  <div class="col-lg-8 col-md-6">
    <button nz-button [nzType]="'primary'" (click)="changePassword()">Change Password</button>
  </div>
</div>
<router-outlet></router-outlet>