import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import store from 'store'
import { QueryTable } from './interface/general.model'
// import { AdForm } from './interface/ad.model'
// import { BlogForm } from './interface/blog.model'

@Injectable({
  providedIn: 'root',
})
export class CTAService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  //--   BUSINESS INQUIRY SERVICES   --//

  // createAd(form: AdForm): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/create', form, params)
  // }

  // updateAd(form: AdForm): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/update', form, params)
  // }

  getBusinessInquiry(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/business-inquiry/list',
      queryTable,
      params,
    )
  }

  businessInquiryDetail(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/business-inquiry/detail',
      params,
    )
  }

  exportBusinessInquiry(date): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/business-inquiry/export',
      { date: date },
      params,
    )
  }

  // deleteAd(id: string): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     const body = {
  //         id: id,
  //     }
  //     return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/delete', body, params)
  // }

  //--   AGENT INQUIRY SERVICES   --//

  // createBlog(form: BlogForm): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     return this.http.post(
  //         this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/create',
  //         form,
  //         params,
  //     )
  // }

  // updateBlog(form: BlogForm): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     return this.http.post(
  //         this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/update',
  //         form,
  //         params,
  //     )
  // }

  getAgentInquiry(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/agent-inquiry/list',
      queryTable,
      params,
    )
  }

  agentInquiryDetail(id: string): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/agent-inquiry/detail',
      params,
    )
  }

  exportAgentInquiry(date): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/cta/agent-inquiry/export',
      { date: date },
      params,
    )
  }

  // deleteBlog(id: string): Observable<any> {
  //     const accessToken = store.get('accessToken')
  //     const params = accessToken
  //         ? {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`,
  //             },
  //         }
  //         : {}
  //     const body = {
  //         id: id,
  //     }
  //     return this.http.post(
  //         this.apiService.API_SUNSHINE_SERVER + '/api/cms/blog/delete',
  //         body,
  //         params,
  //     )
  // }
}
