import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TicketingService } from 'src/app/services/ticketing.service'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
// import { TicketForm, uploadList } from 'src/app/services/interface/ticketing.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { BehaviorSubject, Observable, Observer, of } from 'rxjs'
import { HrService } from 'src/app/services/hr.service'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { EmployeeForm } from 'src/app/services/interface/employee.model'
import { FleetService } from 'src/app/services/fleet.service'
import { Driver, DriverForm } from 'src/app/services/interface/driver.model'
// import { extname } from 'path'
interface options {
  codeName: string
  code: string
}

interface optionArgs {
  type: string
  value: string
}

interface roleOptions {
  codeName: string
  code: string
}

@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
})
export class EditDriverComponent implements OnInit {
  date = null
  isLoading: boolean = true
  isLoadingOptions: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{3,16}$/)]),
      ],
      driverId: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      driverName: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])],
      dateOfBirth: [null],
      placeOfBirth: [null],
      registeredAddress: [null],
      currentAddress: [null],
      citizenNumber: [null],
      driverLicenseType: [null],
      maritalStatus: [null],
      contractNumber: [null],
      employmentStatus: [null],
      phoneNumberPrefix: ['+62'],
      phoneNumber: [null],
      bankName: [null],
      accountNumber: [null],
      accountName: [null],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  @Input() data: any
  @Input() driverId: string
  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })
  roleOptions: options[] = []
  bankInfoOptions: options[] = []
  employementStatusOptions: options[] = []
  placementOptions: options[] = []

  loopArgs = ['role', 'placement', 'bankInfo', 'employementStatus']
  ngOnInit(): void {
    this.fetchDriverDetails()
    this.form.statusChanges.subscribe(state => {
      // console.log(state)
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchDriverDetails() {
    this.isLoading = true
    this.fleetService.detailDriver(this.driverId).subscribe(
      r => {
        this.form.patchValue(r)
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      // console.log('cobacobacobacoba')
      var formSubs: DriverForm = this.form.value
      formSubs.phoneNumber = formSubs.phoneNumberPrefix + formSubs.phoneNumber
      // console.log(formSubs)
      this.fleetService.updateDriver(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  resetPassword() {
    this.modalService.confirm({
      nzTitle: 'Are you sure reset password this employee?',
      nzContent: '<b style="color: red;">This Action cannot be undone !</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.apiService.resetPassword(this.form.value.username).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchDriverDetails()
          },
          err => {
            this.notification.error('Error', err.error.message)
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }

  resetUniqueKey() {
    // this.modalService.confirm({
    //   nzTitle: 'Are you sure reset unique key this employee?',
    //   nzContent: '<b style="color: red;">This Action cannot be undone !</b>',
    //   nzOkText: 'Yes',
    //   nzOkType: 'primary',
    //   nzOkDanger: true,
    //   nzOnOk: () => {
    //     this.apiService.resetUniqueKey(this.form.value.username).subscribe(
    //       r => {
    //         this.notification.success('Success', r.message)
    //         this.fetchEmployeeDetails()
    //       },
    //       err => {
    //         this.notification.error('Error', err.error.message)
    //       },
    //     )
    //   },
    //   nzCancelText: 'No',
    //   nzOnCancel: () => console.log('Cancel'),
    // })
  }
}
