import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ListSubstituteType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-view-detail-annual-leave',
  templateUrl: './view-detail-annual-leave.component.html',
  styleUrls: ['./view-detail-annual-leave.component.scss'],
})
export class ViewDetailAnnualLeaveComponent implements OnInit {
  @Input() leaveId: string
  formDetailAnnualLeave: FormGroup
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []
  leaveType = 'Annual Leave'

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
  ) {
    this.formDetailAnnualLeave = this.fb.group({
      substitute_name: ['', [Validators.required]],
      startValue: ['', Validators.required],
      endValue: ['', Validators.required],
      reason: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.leaveManagementService.getDetailAnnualLeave(this.leaveId).subscribe(
      (r: any) => {
        const data = {
          substitute_name: r.substitute_name,
          startValue: r.date[0],
          endValue: r.date[1],
          reason: r.reason,
        }

        this.formDetailAnnualLeave.patchValue(data)
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  closeModal(): void {
    this.modal.destroy()
  }
}
