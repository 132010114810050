import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CMSService } from 'src/app/services/cms.service'
import { AddDropPointComponent } from './add-drop-point/add-drop-point.component'
import { ViewDropPointComponent } from './view-drop-point/view-drop-point.component'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { DropPoint } from 'src/app/services/interface/drop-point.model'
import { QueryTable } from 'src/app/services/interface/general.model'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-drop-point-management',
  templateUrl: './drop-point-management.component.html',
})
export class DropPointManagementComponent implements OnInit {
  listOfData: DropPoint[] = []
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private cmsService: CMSService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['locationName', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'No.',
      key: 'displayId',
      sortOrder: null,
      sortFn: false,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '40px',
    },
    {
      name: 'City',
      key: 'city',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Location Name',
      key: 'locationName',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Location Side',
      key: 'locationSide',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '120px',
    },
    {
      name: 'Address',
      key: 'address',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '330px',
    },
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: true,
      width: '90px',
    },
  ]

  ngOnInit(): void {
    this.fetchDropPoint()
  }

  fetchDropPoint() {
    this.isLoading = true
    this.cmsService.getDropPoint(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchDropPoint()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'locationName' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchDropPoint()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.queryTable.pageIndex = 0
    this.fetchDropPoint()
  }

  addDropPoint() {
    const modal = this.modal.create({
      nzTitle: 'Add New Drop Point',
      nzContent: AddDropPointComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchDropPoint()
    })
  }

  async viewDropPoint(locationId: string) {
    const modal = this.modal.create({
      nzTitle: 'View Drop Point',
      nzContent: ViewDropPointComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        locationId: locationId,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      nzWidth: '75%',
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => this.fetchDropPoint())
  }

  showDeleteConfirm(locationId: string): void {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this drop point?',
      nzContent: '<b style="color: red;">Once deleted, there is no turning back.</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        // console.log('OK')
        this.cmsService.deleteDropPoint(locationId).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchDropPoint()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchDropPoint()
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }
}
