<form nz-form [formGroup]="formDetailAnnualLeave">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="leaveType" class="text-left">Leave Type</nz-form-label>
        <nz-form-control nzErrorTip="Please input name correctly!">
          <input nz-input [value]="leaveType" disabled="true" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="dateOfLeave" class="text-left">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              formControlName="startValue"
              nzPlaceHolder="Start"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formDetailAnnualLeave.value.startValue"
              [nzDisabled]="true"
            ></nz-date-picker>
            <div style="margin-left: 4px; margin-right: 4px;">-</div>
            <nz-date-picker
              #endDatePicker
              formControlName="endValue"
              nzPlaceHolder="End"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formDetailAnnualLeave.value.endValue"
              [nzDisabled]="true"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="substitute_name" class="text-left">Substitution</nz-form-label>
        <nz-form-control nzErrorTip="Please input reason correctly!">
          <input nz-input id="substitute_name'" formControlName="substitute_name" disabled="true" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="reason" class="text-left">Reason</nz-form-label>
        <nz-form-control nzErrorTip="Please input reason correctly!">
          <input nz-input id="reason'" formControlName="reason" disabled="true" />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
