import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { TicketingService } from 'src/app/services/ticketing.service'
import { customAlphabet } from 'nanoid'
import {
  NzTableQueryParams,
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzResizeEvent } from 'ng-zorro-antd/resizable'
import { Driver } from 'src/app/services/interface/driver.model'
import { FilterObj, QueryTable } from 'src/app/services/interface/general.model'
import { FleetService } from 'src/app/services/fleet.service'
import { AddDriverComponent } from './add-driver/add-driver.component'
import { EditDriverComponent } from './edit-driver/edit-driver.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver-management.component.html',
})
export class DriverManagementComponent implements OnInit {
  listOfData: Driver[] = []
  date
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  form: FormGroup
  // form: FormArray

  statusFiltered: string = 'Total'
  isDownloading: boolean = false
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fleetService: FleetService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      filters: this.fb.array([
        this.fb.group({
          prefix: ['driverName', Validators.compose([Validators.required])],
          keyword: [null],
        }),
      ]),
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
      // console.log(this.role)
    })
  }

  role: string

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    {
      name: 'Date Updated',
      key: 'updatedAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Driver ID',
      key: 'driverId',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Driver Name',
      key: 'driverName',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Employment Status',
      key: 'employmentStatus',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },

    {
      name: 'Phone Number',
      key: 'phoneNumber',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
  ]

  ngOnInit(): void {
    this.fetchDriver()
    // console.log(this.advancedFilters.at(0).get('prefix').value)
  }

  fetchDriver() {
    this.isLoading = true
    this.fleetService.getDriver(this.queryTable).subscribe((r: any) => {
      // console.log('QTQTQTQTQTYQTQTQTQT')
      // console.log(r)
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  get advancedFilters(): FormArray {
    return this.form.get('filters') as FormArray
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    // console.log(this.pageIndex, this.pageSize)
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchDriver()
  }

  search() {
    if (this.advancedFilters.controls[0].value.keyword?.length > 0) {
      var index = this.queryTable.filters.findIndex(e => e.field)
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
      var filter: FilterObj = {
        field: this.advancedFilters.controls[0].value.prefix,
        keyword: this.advancedFilters.controls[0].value.keyword,
      }
      this.queryTable.filters.push(filter)
    } else {
      var index = this.queryTable.filters.findIndex(e => e.field)
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
    }
    // console.log(this.queryTable)
    this.fetchDriver()
  }

  reset() {}

  addDriver() {
    const modal = this.modal.create({
      nzTitle: 'Add New Driver',
      nzContent: AddDriverComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchDriver()
    })
  }

  async viewDriver(driverId: string) {
    const modal = this.modal.create({
      nzTitle: 'View Employee',
      nzContent: EditDriverComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        driverId,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchDriver()
    })
  }

  exportTickets(): void {}
}
