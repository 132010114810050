import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import store from 'store'
import { ApiService } from './api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { addAsset, searchEmployee } from '../models/asset-inventory.model'

@Injectable({
  providedIn: 'root',
})
export class AssetInventoryServiceService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }

  getInventoriesList(company: string, filter: string, key: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/inventories?company=' +
        company +
        '&filter=' +
        filter +
        '&key=' +
        key,
      { headers: this.getCustomHeaders() },
    )
  }

  addAsset(payload: addAsset): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/inventories',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  editAsset(payload: Object): Observable<any> {
    return this.http.put<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/inventories',
      payload,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + store.get('accessToken'),
        }),
      },
    )
  }

  deleteAsset(asset_number: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/inventories?assetNumber=' +
        asset_number,
      { headers: this.getCustomHeaders() },
    )
  }

  getEmployee(payload: searchEmployee): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee/autocomplete',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  searchAutoComplete(company: string, filter: string, key: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/searchautocomplete?company=' +
        company +
        '&filter=' +
        filter +
        '&key=' +
        key,
      { headers: this.getCustomHeaders() },
    )
  }

  deleteFile(filename: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/inventories/upload/?filename=' +
        filename,
      { headers: this.getCustomHeaders() },
    )
  }

  uploadFile(payload: FormData): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/inventories/upload',
      payload,
    )
  }

  importExcel(payload: FormData): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/import',
      payload,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + store.get('accessToken'),
        }),
      },
    )
  }

  exportAssets(company: string, filter: string, key: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/inventories/export?company=' +
        company +
        '&filter=' +
        filter +
        '&key=' +
        key,
      { headers: this.getCustomHeaders() },
    )
  }
}
