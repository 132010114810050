<form nz-form [formGroup]="form">
  <div class="row" style="margin-bottom: 15px;">
    <div class="col-lg-6 col-md-12"></div>
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Driver Basic Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="username" class="text-left">Username</nz-form-label>
                <nz-form-control nzErrorTip="Please input username correctly!">
                  <input
                    nz-input
                    id="'username'"
                    formControlName="username"
                    placeholder="Input Username ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="driverId" class="text-left">Driver Code</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid email">
                  <input
                    nz-input
                    id="driverId"
                    formControlName="driverId"
                    placeholder="Input Driver ID ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="driverName" class="text-left">Driver Full Name</nz-form-label>
                <nz-form-control nzErrorTip="Please input a valid employee code">
                  <input
                    nz-input
                    id="driverName"
                    formControlName="driverName"
                    placeholder="Input Driver's Full Name..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <div class="row" style="margin-bottom: 15px;">
    <div class="col-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Driver Personal Information">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="citizenNumber" class="text-left"
                  >Citizen Number</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input citizen number correctly!">
                  <input
                    nz-input
                    id="'citizenNumber'"
                    formControlName="citizenNumber"
                    placeholder="Input Citizen Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="gender">Gender</nz-form-label>
                <nz-form-control nzErrorTip="Please select gender!">
                  <nz-select formControlName="gender" nzPlaceHolder="Select Gender ...">
                    <nz-option nzLabel="Male" nzValue="Male"></nz-option>
                    <nz-option nzLabel="Female" nzValue="Female"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-3 col-md-6">
              <nz-form-item>
                <nz-form-label nzFor="dateOfBirth" class="text-left">Date of Birth</nz-form-label>
                <nz-form-control nzErrorTip="Please enter a valid date of birth">
                  <nz-date-picker id="'dateOfBirth'" formControlName="dateOfBirth"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="placeOfBirth" class="text-left">Place Of Birth</nz-form-label>
                <nz-form-control nzErrorTip="Please input place of birth correctly!">
                  <input
                    nz-input
                    id="'placeOfBirth'"
                    formControlName="placeOfBirth"
                    placeholder="Input Place Of Birth ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="driverLicenseType" class="text-left"
                  >Driver License's Type</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select driver license!">
                  <nz-select
                    formControlName="driverLicenseType"
                    nzPlaceHolder="Select Type of Driver License ..."
                  >
                    <nz-option nzLabel="SIM A" nzValue="SIM A"></nz-option>
                    <nz-option nzLabel="SIM B1" nzValue="SIM B 1"></nz-option>
                    <nz-option nzLabel="SIM B2" nzValue="SIM B 2"></nz-option>
                    <nz-option nzLabel="SIM C" nzValue="SIM C"></nz-option>
                    <nz-option nzLabel="None" nzValue="None"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="phoneNumber">Phone Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input your phone number!">
                  <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                    <ng-template #addOnBeforeTemplate>
                      <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                        <nz-option nzLabel="+62" nzValue="+62"></nz-option>
                      </nz-select>
                    </ng-template>
                    <input formControlName="phoneNumber" id="'phoneNumber'" nz-input />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="registeredAddress" class="text-left"
                  >Registered Address</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input registered address correctly!">
                  <textarea
                    rows="2"
                    id="'registeredAddress'"
                    nz-input
                    formControlName="registeredAddress"
                    placeholder="Input Registered Address ..."
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="currentAddress" class="text-left"
                  >Current Address</nz-form-label
                >
                <nz-form-control nzErrorTip="Please input current address correctly!">
                  <textarea
                    rows="2"
                    id="'currentAddress'"
                    nz-input
                    formControlName="currentAddress"
                    placeholder="Input Current Address ..."
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="maritalStatus" class="text-left"
                  >Marital Status</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select marital status!">
                  <nz-select
                    formControlName="maritalStatus"
                    nzPlaceHolder="Select Marital Status ..."
                  >
                    <nz-option nzLabel="Single" nzValue="Single"></nz-option>
                    <nz-option nzLabel="Maried" nzValue="Maried"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Contract Information">
          <div class="row">
            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="contractNumber" class="text-left">Contract Number</nz-form-label>
                <nz-form-control nzErrorTip="Please input contract number correctly!">
                  <input
                    nz-input
                    id="'contractNumber'"
                    formControlName="contractNumber"
                    placeholder="Input Contract Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="employmentStatus" class="text-left"
                  >Employment Status</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select Employment Status">
                  <nz-select
                    formControlName="employmentStatus"
                    nzPlaceHolder="Select Employment Status ..."
                  >
                    <nz-option nzLabel="Freelancer" nzValue="Freelancer"></nz-option>
                    <nz-option nzLabel="Contract Employee" nzValue="Contract Employee"></nz-option>
                    <nz-option nzLabel="Fixed Employee" nzValue="Fixed Employee"></nz-option>
                    <nz-option nzLabel="Intern" nzValue="Intern"></nz-option>

                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <div class="col-lg-6 col-md-12">
      <nz-collapse>
        <nz-collapse-panel nzHeader="Employee Payment Information">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <nz-form-item>
                <nz-form-label nzFor="bankName" class="text-left"
                  >Bank Name</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select Bank Name">
                  <nz-select
                    formControlName="bankName"
                    nzPlaceHolder="Select Bank Name ..."
                  >
                    <nz-option nzLabel="BCA" nzValue="BCA"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="accountName" class="text-left"
                  >Account Name</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select account name!">
                  <input
                    nz-input
                    id="'accountName'"
                    formControlName="accountName"
                    placeholder="Input Account Name ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col-12">
              <nz-form-item>
                <nz-form-label nzFor="accountNumber" class="text-left"
                  >Account Number</nz-form-label
                >
                <nz-form-control nzErrorTip="Please select account number!">
                  <input
                    nz-input
                    id="'accountNumber'"
                    formControlName="accountNumber"
                    placeholder="Input Account Number ..."
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</form>
