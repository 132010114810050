import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { ScheduleRoomService } from 'src/app/services/schedule-room.service'
import { MeetingRoomService } from 'src/app/services/meeting-room.service'
import { ScheduleRoomForm } from 'src/app/services/interface/schedule-room.model'
import { DatePipe } from '@angular/common'
import { differenceInCalendarDays } from 'date-fns'

interface options {
  id: string
  name: string
}

@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.scss'],
})
export class EditScheduleComponent implements OnInit {
  @Input() id: string
  form: FormGroup
  isLoading: boolean = true
  roomArr: options[] = []
  divArr: options[] = [
    {
      id: 'Assistant Director',
      name: 'Assistant Director',
    },
    {
      id: 'HRD',
      name: 'HRD',
    },
    {
      id: 'Finance & Accounting',
      name: 'Finance & Accounting',
    },
    {
      id: 'Quality Control',
      name: 'Quality Control',
    },
    {
      id: 'Trainer',
      name: 'Trainer',
    },
    {
      id: 'IT',
      name: 'IT',
    },
    {
      id: 'Multimedia',
      name: 'Multimedia',
    },
    {
      id: 'GA and Purchasing',
      name: 'GA and Purchasing',
    },
    {
      id: 'Marketing',
      name: 'Marketing',
    },
    {
      id: 'Other',
      name: 'Other',
    },
  ]
  colourArr: options[] = [
    {
      id: 'error',
      name: 'Red',
    },
    {
      id: 'success',
      name: 'Green',
    },
    {
      id: 'warning',
      name: 'Yellow',
    },
  ]

  user: string
  colours: string
  data: any

  constructor(
    private fb: FormBuilder,
    private scheduleRoomService: ScheduleRoomService,
    private meetingRoomService: MeetingRoomService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      username: [{ value: null, disabled: true }],
      capacity: [null, Validators.compose([Validators.required, Validators.min(1)])],
      meetingRoom: [null, Validators.compose([Validators.required])],
      division: [null, Validators.compose([Validators.required])],
      agenda: [null, Validators.compose([Validators.required])],
      timeStart: [null, Validators.compose([Validators.required])],
      timeEnd: [null, Validators.compose([Validators.required])],
      dateStart: [null, Validators.compose([Validators.required])],
      dateEnd: [null, Validators.compose([Validators.required])],
      colour: [null, Validators.compose([Validators.required])],
    })
    this.meetingRoomService.roomOption().subscribe((r: any) => {
      this.roomArr = r
    })
    this.scheduleRoomService.username().subscribe(data => {
      this.user = data.role
    })
  }

  disabledDateStart = (current: Date): boolean => {
    // Can not select days before today and today
    var date = new Date(this.form.get('timeStart').value)
    return differenceInCalendarDays(current, date) < 0
  }

  disabledDateEnd = (current: Date): boolean => {
    // Can not select days before today and today
    var date = new Date(this.form.get('timeStart').value)
    return differenceInCalendarDays(current, date.setDate(date.getDate())) < 0
  }

  // pipe = new DatePipe('en-Us')

  ngOnInit(): void {
    this.fetchScheduleRoomDetails()
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchScheduleRoomDetails() {
    this.isLoading = true
    this.scheduleRoomService.viewdetailScheduleRoom(this.id).subscribe(
      r => {
        this.data = r
        if (r.colour == 'warning') {
          this.colours = 'Yellow'
        }
        if (r.colour == 'error') {
          this.colours = 'Red'
        }
        if (r.colour == 'success') {
          this.colours = 'Green'
        }
        this.form.patchValue(r)
        this.isLoading = false
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: ScheduleRoomForm = this.form.getRawValue()
      formSubs.id = this.id
      this.scheduleRoomService.updateScheduleRoom(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          this.fetchScheduleRoomDetails()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          this.fetchScheduleRoomDetails()
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      this.fetchScheduleRoomDetails()
      return false
    }
  }
}
