import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { TicketCommentForm, TicketDetails } from 'src/app/services/interface/ticketing.model'
import { UploadList } from 'src/app/services/interface/general.model'
import { TicketingService } from 'src/app/services/ticketing.service'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { Observable, Observer } from 'rxjs'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import storee from 'store'
import { ApiService } from 'src/app/services/api.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
})
export class ViewTicketComponent implements OnInit, AfterViewInit {
  @Input() ticketTag: string

  form: FormGroup
  formComment: FormGroup
  fileList: NzUploadFile[] = []
  fileListComment: NzUploadFile[] = []
  profile_img: any
  constructor(
    private msg: NzMessageService,
    private modal: NzModalRef,
    private fb: FormBuilder,
    private ticketingService: TicketingService,
    private store: Store<any>,
    private http: HttpClient,
    private apiService: ApiService,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      tag: [null],
      vendor: [null],
      waybill: [null],
      agent: [null],
      person: [null],
      problemType: [null],
      description: [null],
      createdAt: [null],
      updatedAt: [null],
      createdBy: [null],
      status: [null],
    })

    this.formComment = this.fb.group({
      comments: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([Validators.required])],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
      this.profile_img = state.profile_img
    })
  }
  signer: string
  isLoadingComment: boolean = false

  data = []
  detailsData: TicketDetails
  ngAfterViewInit(): void {}
  isLoading: boolean = true
  ngOnInit(): void {
    this.fetchDetails()
  }

  fetchDetails() {
    this.isLoading = true
    this.ticketingService.getTicketDetails(this.ticketTag).subscribe((r: TicketDetails) => {
      this.detailsData = r
      this.fileList = r.attachments
      this.data = r.comments
      this.form.patchValue(this.detailsData)
      this.isLoading = false
    })
  }

  handleBeforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    var ext = item.file.name.split('.').pop()
    formData.append('file', item.file as any, this.ticketTag + '.' + ext)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/tickets/upload',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      if (file.hasOwnProperty('originFileObj')) {
        if (file.response == undefined) {
          observer.next(true)
          observer.complete()
        } else {
          this.apiService.deleteFileTemp(file.response?.name).subscribe(
            r => {
              observer.next(true)
              observer.complete()
            },
            e => {
              observer.next(false)
              file.status = 'done'
              this.msg.error('Failed to Delete')
              observer.complete()
            },
          )
        }
      } else {
        observer.next(true)
        observer.complete()
      }
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  submitComment() {
    this.isLoadingComment = true
    var isUploading = false
    for (let i = 0; i < this.fileListComment.length; i++) {
      if (['uploading', 'error'].includes(this.fileListComment[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }
    for (const i in this.formComment.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (isUploading) {
      this.msg.error('Please wait until all files uploaded')
      this.isLoadingComment = true
      return
    }

    if (this.formComment.valid) {
      var attachmentList: UploadList[] = []
      for (let i = 0; i < this.fileListComment.length; i++) {
        if ((this.fileListComment[i].status = 'done')) {
          var tempMetaAttachment: UploadList = {
            name: this.fileListComment[i].response?.name || this.fileListComment[i].name,
            status: this.fileListComment[i].status,
            url: this.fileListComment[i].response?.url || this.fileListComment[i].url,
            uid: this.fileListComment[i].uid,
          }
          attachmentList.push(tempMetaAttachment)
        }
      }
      var formSubs: TicketCommentForm = this.formComment.value
      formSubs.signer = this.signer
      formSubs.attachments = attachmentList
      formSubs.ticketTag = this.ticketTag
      this.ticketingService.createCommentTicket(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoadingComment = false
          this.modal.destroy()
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoadingComment = false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoadingComment = false
    }
  }
}
