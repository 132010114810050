import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTableLeave } from 'src/app/services/interface/general.model'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ViewDetailAnnualLeaveComponent } from '../view-detail-annual-leave/view-detail-annual-leave.component'

@Component({
  selector: 'app-tab-waiting-annual-leave',
  templateUrl: './tab-waiting-annual-leave.component.html',
  //   styleUrls: ['./annual-leave.component.scss'],
})
export class TabWaitingAnnualLeaveComponent implements OnInit {
  form: FormGroup
  isAnnualLeave = true
  selectedFilter = 'WAITING'
  selectedType = 'Annual Leave'

  listOfData: AnnualLeaveType[] = []
  isLoading: boolean = true

  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  constructor(
    private fb: FormBuilder,
    private leaveManagementService: LeaveManagementService,
    private modal: NzModalService,
    private notification: NzNotificationService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTableLeave = {
    sort: null,
    annual_leave: true,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns = [
    {
      name: 'ID',
      key: 'id',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '30px',
    },
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '30px',
    },

    {
      name: 'Total Leave',
      key: 'total_leave',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '30px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Date of Leave',
      key: 'date',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Date of In',
      key: 'join_date',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '35px',
    },
    {
      name: 'Reason',
      key: 'reason',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Notes',
      key: 'notes_approval',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {
    // this.fetchAnnualLeaveList()
  }

  fetchAnnualLeaveList() {
    this.isLoading = true
    this.leaveManagementService
      .getAnnualLeaveList(
        this.selectedFilter,
        this.queryTable,
        this.isAnnualLeave,
        this.selectedType,
      )
      .subscribe(
        (r: any) => {
          this.isLoading = false
          // this.listOfData = r.rows

          this.listOfData = r.rows.map((item: any) => ({
            ...item,
            status: this.transformStatus(item.status),
          }))

          this.totalData = r.count
        },
        (error: any) => {
          this.isLoading = false
        },
      ),
      err => {
        this.fetchAnnualLeaveList()
      }
  }

  transformStatus(status: string): string {
    switch (status) {
      case 'WAITING':
        return 'Waiting for Approval HR'
      case 'CANCELED':
        return 'Canceled'
      case 'APPROVED':
        return 'Approved'
      default:
        return status
    }
  }

  cancelLeave(id: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure cancel this leave?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.leaveManagementService.cancelAnnualLeave(id).subscribe(
          r => {
            this.notification.success('Success', 'Successfully cancel leave!')
            this.fetchAnnualLeaveList()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchAnnualLeaveList()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => '',
    })
  }

  viewDetailLeave(leaveId: string) {
    const modal = this.modal.create({
      nzTitle: 'View Detail Annual Leave',
      nzContent: ViewDetailAnnualLeaveComponent,
      nzStyle: { top: '50px' },
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        leaveId: leaveId,
      },
      nzMaskClosable: false,
      nzFooter: [
        {
          label: 'Close',
          danger: true,
          onClick() {
            modal.destroy()
          },
        },
      ],
      nzWidth: '75%',
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAnnualLeaveList()
  }
}
