<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="is_active" class="text-left">Active</nz-form-label>
                    <nz-form-control>
                        <nz-switch formControlName="is_active"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input name correctly!">
                        <input nz-input id="'name'" formControlName="name" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="code" class="text-left">Code</nz-form-label>
                    <nz-form-control nzErrorTip="Please input code!">
                        <input nz-input id="'code'" formControlName="code" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="type" class="text-left">Type</nz-form-label>
                    <nz-form-control nzErrorTip="Please select a type!">
                        <nz-select formControlName="type" nzPlaceHolder="Select type ...">
                            <nz-option nzLabel="Percentage" nzValue="percentage"></nz-option>
                            <nz-option nzLabel="Numeric" nzValue="numeric"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <!-- <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="status">Status</nz-form-label>
                    <nz-form-control nzErrorTip="Please select a status!">
                        <nz-select formControlName="status" nzPlaceHolder="Select status ..."
                            (ngModelChange)="statusSelect($event)">
                            <nz-option nzLabel="Public" nzValue="public"></nz-option>
                            <nz-option nzLabel="Private" nzValue="private"></nz-option>
                            <nz-option nzLabel="Individual" nzValue="individual"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div> -->
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="value" class="text-left">Value</nz-form-label>
                    <nz-form-control nzErrorTip="Please input value correctly!">
                        <input nz-input type="number" id="'value'" formControlName="value" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="department" class="text-left">Department</nz-form-label>
                    <nz-form-control nzErrorTip="Please choose department correctly!">
                        <nz-select formControlName="department" nzMode="multiple" nzPlaceHolder="Please select"
                            (ngModelChange)="departmentSelect($event)">
                            <nz-option *ngFor="let item of listOfDeptOption" [nzLabel]="item"
                                [nzValue]="item"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="quota" class="text-left">Overall Quota</nz-form-label>
                    <nz-form-control nzErrorTip="Please input quota correctly!">
                        <input nz-input type="number" id="'quota'" formControlName="quota"
                            placeholder="Skip if unlimited" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="quota_per_user" class="text-left">Quota Per User</nz-form-label>
                    <nz-form-control nzErrorTip="Please input quota per user correctly!">
                        <input nz-input type="number" id="'quota_per_user'" formControlName="quota_per_user"
                            placeholder="Skip if unlimited" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="minDiscount" class="text-left">Minimum Price</nz-form-label>
                    <nz-form-control nzErrorTip="Please input minimum price correctly!">
                        <input nz-input type="number" id="'minDiscount'" formControlName="minDiscount" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="maxDiscount" class="text-left">Maximum Discount</nz-form-label>
                    <nz-form-control nzErrorTip="Please input maximum discount correctly!">
                        <input nz-input type="number" id="'maxDiscount'" formControlName="maxDiscount" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="min_weight" class="text-left">Minimum Weight (Kg)</nz-form-label>
                    <nz-form-control nzErrorTip="Please input minimum weight correctly!">
                        <input nz-input type="number" id="'min_weight'" formControlName="min_weight" placeholder="you can leave it blank or enter zero" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="max_weight" class="text-left">Maximum Weight (Kg)</nz-form-label>
                    <nz-form-control nzErrorTip="Please input maximum weight correctly!">
                        <input nz-input type="number" id="'max_weight'" formControlName="max_weight" placeholder="you can leave it blank or enter zero" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="startDate" class="text-left">Start Date</nz-form-label>
                    <nz-form-control nzErrorTip="Please input valid date">
                        <nz-date-picker formControlName="startDate" [nzDisabledDate]="disabledDate" nzShowTime
                            nzFormat="dd-MM-yyyy HH:mm:ss">
                        </nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="dueDate" class="text-left">Expiry Date</nz-form-label>
                    <nz-form-control nzErrorTip="Please input valid date">
                        <nz-date-picker formControlName="dueDate" [nzDisabledDate]="disabledDate" nzShowTime
                            nzFormat="dd-MM-yyyy HH:mm:ss">
                        </nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-label nzFor="image" class="text-left">Voucher Image</nz-form-label>
                <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
                    [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
                    [nzBeforeUpload]="handleBeforeUpload" [nzAccept]="'image/png, image/jpeg, image/jpg'"
                    [nzDisabled]="fileList.length > 0" [nzShowButton]="!(fileList.length > 0)"
                    (nzChange)="handleChange($event)">
                    <div>
                        <i nz-icon nzType="plus"></i>
                        <div style="margin-top: 8px">Upload</div>
                    </div>
                </nz-upload>
                <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                    (nzOnCancel)="previewVisible = false">
                    <ng-template #modalContent>
                        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                    </ng-template>
                </nz-modal>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="description" class="text-left">Description</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid description">
                        <nz-textarea-count>
                            <textarea rows="4" formControlName="description" nz-input></textarea>
                        </nz-textarea-count>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="is_inner_city" class="text-left">Inner City Only?</nz-form-label>
                    <nz-form-control nzExtra="(BANDUNG - BANDUNG; JABODETABEK - JABODETABEK; SURABAYA - SURABAYA)">
                        <nz-switch formControlName="is_inner_city" (ngModelChange)="inner_city_select($event)"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <!-- <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="isFcm" class="text-left">Notification status</nz-form-label>
                    <nz-form-control nzErrorTip="Please input a valid notification status">
                        <nz-switch formControlName="isFcm"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div> -->
            <ng-template [ngIf]="isOndelivery">
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-label nzFor="service" class="text-left">Service</nz-form-label>
                        <nz-form-control nzErrorTip="Please choose service correctly!">
                            <nz-select formControlName="service" nzMode="multiple" nzPlaceHolder="Please select">
                                <ng-container *ngFor="let item of listOfServiceOption">
                                    <nz-option *ngIf="!isLoadingOptions" [nzLabel]="item.name"
                                        [nzValue]="item.id"></nz-option>
                                </ng-container>
                                <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                                    <span nz-icon nzType="loading" class="loading-icon"></span>
                                    Loading Data...
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-label nzFor="origin_city" class="text-left">Origin City</nz-form-label>
                        <nz-form-control nzErrorTip="Please input city correctly!">
                            <nz-select (ngModelChange)="originCitySelect($event)" formControlName="origin_city"
                                nzShowSearch nzServerSearch [nzShowArrow]="false" nzMode="multiple"
                                nzPlaceHolder="Please input city name" (nzOnSearch)="originSearch($event)">
                                <ng-container *ngFor="let item of listOfOriginCityOption">
                                    <nz-option *ngIf="!isLoadingOptions" [nzLabel]="item.city_name"
                                        [nzValue]="item"></nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div class="col-6">
                    <nz-form-item>
                        <nz-form-label nzFor="destination_city" class="text-left">Destination City</nz-form-label>
                        <nz-form-control nzErrorTip="Please input city correctly!">
                            <nz-select (ngModelChange)="destinationCitySelect($event)"
                                formControlName="destination_city" nzShowSearch nzServerSearch [nzShowArrow]="false"
                                nzMode="multiple" nzPlaceHolder="Please input city name"
                                (nzOnSearch)="destinationSearch($event)">
                                <ng-container *ngFor="let item of listOfDestinationCityOption">
                                    <nz-option *ngIf="!isLoadingOptions" [nzLabel]="item.city_name"
                                        [nzValue]="item"></nz-option>
                                </ng-container>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </ng-template>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <h4>New User Criteria</h4>
                </div>
                <div class="row">
                    <h4>The default New User Criteria was "user never had transaction in any service". Options below are
                        additional requirements for using voucher</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <nz-form-item>
                    <nz-form-label nzFor="restriction_transaction_service" class="text-left">No transaction
                        in selected service</nz-form-label>
                    <nz-form-control>
                        <nz-switch formControlName="restriction_transaction_service"
                            (ngModelChange)="transactionCriteriaSelect($event)"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <!-- <div class="col-4">
                <ng-template [ngIf]="transaction_same_asform">
                    <nz-form-item>
                        <nz-form-label nzFor="restriction_transaction_same_asform" class="text-left">Service is/are
                            same as "Service" form above</nz-form-label>
                        <nz-form-control>
                            <nz-switch formControlName="restriction_transaction_same_asform"
                            (ngModelChange)="transactionDiffSelect($event)"></nz-switch>
                        </nz-form-control>
                    </nz-form-item>
                </ng-template>
            </div> -->
            <div class="col-6">
                <ng-template [ngIf]="res_transaction_custom">
                    <nz-form-item>
                        <nz-form-label nzFor="restriction_transaction_custom" class="text-left">Service Restriction</nz-form-label>
                        <nz-form-control nzErrorTip="Please choose service correctly!">
                            <nz-select formControlName="restriction_transaction_custom" nzMode="multiple"
                                nzPlaceHolder="Please select">
                                <ng-container *ngFor="let item of listOfServiceOption">
                                    <nz-option *ngIf="!isLoadingOptions" [nzLabel]="item.name"
                                        [nzValue]="item.id"></nz-option>
                                </ng-container>
                                <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                                    <span nz-icon nzType="loading" class="loading-icon"></span>
                                    Loading Data...
                                </nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <nz-form-item>
                    <nz-form-label nzFor="restriction_time_registration" class="text-left">User registered in certain time</nz-form-label>
                    <nz-form-control>
                        <nz-switch formControlName="restriction_time_registration"
                            (ngModelChange)="timeCriteriaSelect($event)"></nz-switch>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-4">
                <ng-template [ngIf]="res_time_custom">
                    <nz-form-item>
                        <nz-form-label nzFor="restriction_time_start" class="text-left">Start Date</nz-form-label>
                        <nz-date-picker formControlName="restriction_time_start" nzShowTime
                            nzFormat="dd-MM-yyyy HH:mm:ss">
                        </nz-date-picker>
                    </nz-form-item>
                </ng-template>
            </div>
            <div class="col-4">
                <ng-template [ngIf]="res_time_custom">
                    <nz-form-item>
                        <nz-form-label nzFor="restriction_time_end" class="text-left">End Date</nz-form-label>
                        <nz-date-picker formControlName="restriction_time_end" nzShowTime
                            nzFormat="dd-MM-yyyy HH:mm:ss">
                        </nz-date-picker>
                    </nz-form-item>
                </ng-template>
            </div>
        </div>
    </form>
</nz-spin>