<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          class="table-Employeeing"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          [nzPageSizeOptions]="[25, 50, 100, 250]"
          [nzScroll]="{ x: '2500px', y: '900px' }"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzTitle]="headerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td [nzLeft]="column.left" [nzRight]="column.right">
                  {{
                    column.key.startsWith('schedule')
                      ? data[column.key] !== null
                        ? data[column.key]
                        : '-'
                      : column.key === 'month'
                      ? getMonthName(data[column.key])
                      : data[column.key]
                  }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col">
      <span
        nzTooltipPlacement="top"
        nz-tooltip
        nzTooltipTitle="Restart Filter & Sort"
        (click)="reset()"
      >
        <a class="btn btn-lg btn-light mr-2">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>

      <span
        nzTooltipPlacement="top"
        nz-tooltip
        nzTooltipTitle="Download Template"
        (click)="downloadTemplateShiftScheduler()"
      >
        <a class="btn btn-lg btn-light mr-2">
          <i class="fe fe-download"></i>
        </a>
      </span>

      <nz-upload
        [nzCustomRequest]="customUpload"
        [(nzFileList)]="importExcelFile"
        (nzChange)="handleChangeImport($event)"
        [nzRemove]="true"
        [nzShowButton]="true"
      >
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Upload Excel">
          <a class="btn btn-lg btn-light mr-2">
            <i class="fe fe-upload"></i>
          </a>
        </span>
      </nz-upload>
    </div>

    <div class="col">
      <div class="row">
        <div class="ml-auto mr-3">
          <button
            nz-button
            nzType="primary"
            [nzLoading]="showLoading"
            (click)="submitExcel()"
            [disabled]="listOfData.length == 0"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #toaster>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <span nz-icon [nzType]="toasterIcon" [ngStyle]="{ color: toasterColor }"></span>
      </span>
      <div class="ant-notification-notice-message">Message :</div>
      <div class="ant-notification-notice-description">
        {{ toasterMessage }}
      </div>
    </div>
  </div>
</ng-template>
