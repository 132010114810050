import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import store from 'store'
import { ApiService } from '../api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  AddQuotaAnnualLeaveType,
  AnnualLeaveType,
  CollectiveLeaveType,
  LeaveConfigType,
  ListAnnualLeaveType,
  RequestAnnualLeaveType,
  RequestCollectiveLeaveType,
  RequestSpecialLeaveType,
} from '../interface/leave-management/leave.model'
import { QueryTable, QueryTableLeave, QueryTableLeaveConfig } from '../interface/general.model'
import { getYear } from 'date-fns'

@Injectable({
  providedIn: 'root',
})
export class LeaveManagementService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }

  //
  // Leave Management
  getListSubstitute() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/list-substitute',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  addQuotaAnnualLeave(payload: AddQuotaAnnualLeaveType) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/add-quota-annual',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  listLeaveType() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/list-leave-type',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  checkQuotaLeave() {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/check-quota',
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  listConfig(queryTable: QueryTableLeaveConfig) {
    let filter = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: 'created_at',
        order: 'ascend',
      },
    }
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/config-list',
      filter,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getListEmployee(keyword: string) {
    let query = {
      keyword: keyword,
    }
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER +
        `/api/leave-management/employee-list?keyword=${keyword}`,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  //
  // leave management/request
  requestAnnualLeave(payload: RequestAnnualLeaveType) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/request/annual',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  cancelAnnualLeave(id: string) {
    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/${id}`,
      {},
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getDetailAnnualLeave(id: string) {
    return this.http.get<AnnualLeaveType>(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/${id}`,

      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  editRequestLeave(id: string, payload: Object) {
    return this.http.patch<ListAnnualLeaveType>(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/${id}`,
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getAnnualLeaveList(
    selectedFilter: string,
    queryTable: QueryTableLeave,
    isAnnualLeave: boolean,
    selectedType: string,
  ): Observable<ListAnnualLeaveType> {
    let filters = []

    if (selectedType) {
      filters = [
        {
          field: 'status',
          keyword: selectedFilter,
        },

        {
          field: 'type',
          keyword: selectedType,
        },
      ]
    }

    if (!selectedType) {
      filters = [
        {
          field: 'status',
          keyword: selectedFilter,
        },
      ]
    }

    let query = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      annual_leave: isAnnualLeave,
      sort: {
        field: 'date',
        order: 'ascend',
      },
      filters: filters,
    }

    return this.http.post<ListAnnualLeaveType>(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/request',
      query,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  requestSpecialLeave(payload: RequestSpecialLeaveType) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/request/special',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  createCollectiveLeave(payload: RequestCollectiveLeaveType) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/request/collective',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getListCollectiveLeave(selectedFilter: string, queryTable: QueryTable) {
    if (!selectedFilter) {
      selectedFilter = String(getYear(new Date()))
    }

    let filter = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: 'date',
        order: 'ascend',
      },
      filters: [
        {
          field: 'year',
          keyword: selectedFilter,
        },
      ],
    }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/request/collective/list',
      filter,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  getDetailCollectiveLeave(id: string) {
    return this.http.get(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/collective/${id}`,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  deleteCollectiveLeave(id: string) {
    return this.http.delete(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/collective/${id}`,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  //
  // Leave Management/Approval
  getListApprovalLeave(queryTable, selectedFilter: string) {
    let filter = {
      page: queryTable.pageIndex || 0,
      size: queryTable.pageSize || 5,
      sort: {
        field: 'date',
        order: 'ascend',
      },
      filters: [
        {
          field: 'status',
          keyword: selectedFilter,
        },
      ],
    }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/approval',
      filter,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  giveCancelRequestLeave(id: string, notes: string) {
    let body = { notes_approval: notes }
    return this.http.put(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/approval/${id}`,
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  giveApproveRequestLeave(id: string, notes: string) {
    let body = { notes_approval: notes }

    return this.http.patch(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/approval/${id}`,
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  exportApprovalLeave(mode: string, date: string) {
    let body = { mode, date }

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/leave-management/approval/export',
      body,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  //
  //Shift
  getListShift() {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/shift/dropdown', {
      headers: this.getCustomHeaders(),
    })
  }

  //
  //Upload
  uploadAttachment(payload: FormData, type: string) {
    const accessToken = store.get('accessToken')

    const header = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    })

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + `/api/leave-management/request/upload/${type}`,
      payload,
      {
        headers: header,
      },
    )
  }
}
