<nz-alert nzMessage="Your selected date: {{ selectedValue | date: 'dd-MM-yyyy' }}"></nz-alert>

<nz-calendar [(ngModel)]="selectedValue" (nzSelectChange)="selectChange($event)">
  <ul *nzDateCell="let date" class="events">
    <ng-container [ngSwitch]="date.getDate()">
      <ng-container *ngFor="let data of calendarStart">
        <ng-container *ngIf="[data.date_start] != [endGame]">
          <ng-container *ngSwitchCase="[data.date_start]">
            <li>
              <nz-badge [nzStatus]="data.colour" [nzText]="data.agenda"></nz-badge>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let data of rangeStart">
        <ng-container *ngSwitchCase="[data.range]">
          <li>
            <nz-badge [nzStatus]="data.colour" [nzText]="data.agenda"></nz-badge>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let data of calendarEnd">
        <ng-container *ngIf="[startGame] != [data.date_end]">
          <ng-container *ngSwitchCase="[data.date_end]">
            <li>
              <nz-badge [nzStatus]="data.colour" [nzText]="data.agenda"></nz-badge>
            </li>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let data of rangeEnd">
        <ng-container *ngSwitchCase="[data.range]">
          <li>
            <nz-badge [nzStatus]="data.colour" [nzText]="data.agenda"></nz-badge>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nz-calendar>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #fixedTable nzShowSizeChanger class="table-ScheduleRooming" [nzData]="listOfData"
          [nzFrontPagination]="false" [nzLoading]="isLoading" [nzTotal]="totalData" [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex" (nzQueryParams)="onQueryParamsChange($event)" [nzPageSizeOptions]="[5, 10, 20, 50]"
          [nzScroll]="{ x: '1500px', y: '42vh' }" nzSize="small">

          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th *ngIf="column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder" [nzColumnKey]="column.key" [nzWidth]="column.width">
                  {{ column.name }}
                </th>
                <th *ngIf="!column.sortFn" [nzLeft]="column.left" [nzRight]="column.right" [nzColumnKey]="column.key"
                  [nzWidth]="column.width">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Schedule">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewSchedule(data.id)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                  <span style="margin: 0 2px;"></span>
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Schedule">
                    <a class="btn btn-sm btn-light mr-2" (click)="deleteSchedule(data.id)">
                      <i class="fe fe-trash-2"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="(column.key != 'actions')" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>