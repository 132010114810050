import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ListSubstituteType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-view-detail-collective-leave',
  templateUrl: './view-detail-collective-leave.component.html',
  styleUrls: ['./view-detail-collective-leave.component.scss'],
})
export class ViewDetailCollectiveLeaveComponent implements OnInit {
  @Input() leaveId: string
  formViewCollectiveLeave: FormGroup
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []
  leaveType = 'Annual Leave'

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
  ) {
    this.formViewCollectiveLeave = this.fb.group({
      title: ['', [Validators.required]],
      startValue: ['', Validators.required],
      endValue: ['', Validators.required],
      description: ['', [Validators.required]],
      shift_name: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.leaveManagementService.getDetailCollectiveLeave(this.leaveId).subscribe(
      (r: any) => {
        let dateArray = r.date.split(' - ')
        const data = {
          title: r.title,
          startValue: dateArray[0],
          endValue: dateArray[1],
          description: r.description,
          shift_name: r.shift_name,
        }

        this.formViewCollectiveLeave.patchValue(data)
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  closeModal(): void {
    this.modal.destroy()
  }
}
