import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { CreateCollectiveLeave } from 'src/app/services/interface/leave-management/leave.model'
import { format } from 'date-fns'

@Component({
  selector: 'app-add-collective-leave',
  templateUrl: './add-collective-leave.component.html',
  styleUrls: ['./add-collective-leave.component.scss'],
})
export class AddCollectiveLeaveComponent implements OnInit {
  formCollectiveLeave: FormGroup
  isLoading: boolean = false
  selectedDate = null
  listOfShift = []
  selectedShift = null
  date = null

  constructor(
    private fb: FormBuilder,
    private leaveManagementService: LeaveManagementService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.formCollectiveLeave = this.fb.group({
      title: ['', [Validators.required]],
      date: ['', Validators.required],
      description: ['', Validators.required],
      shift_id: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.fetchLeaveShift()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    if (this.formCollectiveLeave.valid) {
      let payload = {
        title: this.formCollectiveLeave.value.title,
        description: this.formCollectiveLeave.value.description,
        date: [
          format(this.formCollectiveLeave.value.date[0], 'yyyy-MM-dd'),
          format(this.formCollectiveLeave.value.date[1], 'yyyy-MM-dd'),
        ],

        shift_id: this.formCollectiveLeave.value.shift_id,
      }
      this.modal.destroy()
      var formSubs: CreateCollectiveLeave = payload
      this.leaveManagementService.createCollectiveLeave(formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Create Collective Leave')
          this.modal.destroy()
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  onChange(result: Date[]): void {
    // console.log('onChange: ', result)
  }

  onCancel(): void {
    this.modal.destroy()
  }

  fetchLeaveShift() {
    this.isLoading = true
    this.leaveManagementService.getListShift().subscribe(
      (r: any) => {
        this.isLoading = false
        this.listOfShift = r
      },
      (error: any) => {
        this.isLoading = false
      },
    )
  }
}
