import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-shift-scheduler',
  templateUrl: './shift-scheduler.component.html',
})
export class ShiftSchedulerComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error('Method not implemented.')
  }
}
