<div class="row wrap">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          class="table-Location"
          [nzData]="listOfData"
          [nzLoading]="isLoading"
          [nzFrontPagination]="false"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          [nzPageSizeOptions]="[25, 50, 100, 250]"
          (nzQueryParams)="onQueryParamsChange($event)"
          nzShowPagination
          nzShowSizeChanger
          nzSize="small"
          [nzTitle]="headerTable"
          [nzScroll]="{ y: '40vh' }"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Detail">
                    <a class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  {{ data[column.key] }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <div class="row wrap justify-content-between">
    <div class="col">
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Location">
        <a class="btn btn-lg btn-light mr-2" (click)="addLocation()">
          <i class="fe fe-plus-circle"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
        <a class="btn btn-lg btn-light mr-2" (click)="reset()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Location">
        <a class="btn btn-lg btn-light mr-2" (click)="exportLocation()">
          <i class="fe fe-download"></i>
        </a>
      </span>
    </div>
    <div class="col">
      <form
        nz-form
        [nzLayout]="'inline'"
        [formGroup]="form"
        (ngSubmit)="search()"
        class="float-right"
      >
        <nz-form-item>
          <nz-form-label nzFor="prefix" class="text-left">Prefix</nz-form-label>
          <nz-form-control>
            <nz-select
              id="prefix"
              [formControl]="form.controls['prefix']"
              nzPlaceHolder="Select Prefix ..."
              style="width: 150px;"
            >
              <nz-option nzValue="locationName" nzLabel="Location Name"></nz-option>
              <nz-option nzValue="city" nzLabel="City"></nz-option>
              <nz-option [nzValue]="null" nzLabel="None"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <input
              id="search"
              [formControl]="form.controls['keyword']"
              nz-input
              placeholder="Keyword ..."
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-template>
