import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import storeeeee from 'store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { Observable, Observer } from 'rxjs'
import { CMSService } from 'src/app/services/cms.service'
import { DeptForm } from 'src/app/services/interface/department.model'

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss'],
})
export class EditDepartmentComponent implements OnInit {
  @Input() id: string
  form: FormGroup
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private cmsService: CMSService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      image: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([Validators.required])],
    })
  }

  isLoading: boolean = true
  ngOnInit(): void {
    this.fetchDeptDetails()
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchDeptDetails() {
    this.isLoading = true
    this.cmsService.detailDept(this.id).subscribe(
      res => {
        this.fileList = [res.image]
        res.image = true
        this.form.patchValue(res)
        this.isLoading = false
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    var isUploading = false
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (!isUploading && this.form.valid) {
      var formSubs: DeptForm = this.form.value
      formSubs.id = this.id
      if (this.fileList[0].status === 'done') {
        formSubs.image = {
          name: this.fileList[0].response?.name || this.fileList[0].name,
          status: this.fileList[0].status,
          url: this.fileList[0].response?.url || this.fileList[0].url,
          uid: this.fileList[0].uid,
        }
      }
      this.cmsService.updateDept(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({
            nzOkLoading: this.isLoading,
            nzCancelDisabled: this.isLoading,
          })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      return
    }
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ image: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }
}
