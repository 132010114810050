import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-new-user-voucher',
  templateUrl: './new-user-voucher.component.html',
  // styleUrls: ['./new-user-voucher.component.scss'],
})
export class NewUserVoucherComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
