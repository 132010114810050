<div class="container-top">
  <div class="container-button">
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Apply Leave">
      <a class="btn btn-lg btn-light mr-2" (click)="addLeave()">
        <i class="fe fe-plus-circle"></i>
      </a>
    </span>
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
      <a class="btn btn-lg btn-light mr-2" (click)="reset()">
        <i class="fe fe-refresh-cw"></i>
      </a>
    </span>

    <nz-select
      nzPlaceHolder="Leave Type"
      nzShowSearch
      [(ngModel)]="selectedType"
      style="height: 44px !important; padding-top: 4px !important; padding-bottom: 2px;"
    >
      <nz-option *ngFor="let item of listLeaveType" [nzLabel]="item.label" [nzValue]="item.label">
        {{ item.label }}
      </nz-option>
    </nz-select>
  </div>

  <div class="container-leave-quota">
    <button
      nz-button
      nzType="ghost"
      nzSize="large"
      style="background-color: #E4E9F0 !important; font-weight: 600 !important; color: black !important;"
    >
      Annual Leave Quota =
      <span style="margin-left: 8px; color: red;">
        {{ annualQuotaLeave }}
      </span>
    </button>
    <button
      nz-button
      nzType="ghost"
      nzSize="large"
      style="background-color: #E4E9F0 !important; font-weight: 600 !important; color: black !important"
    >
      Collective Leave Quota =
      <span style="margin-left: 8px; color: red;">
        {{ collectiveQuotaLeave }}
      </span>
    </button>
  </div>
</div>

<div>
  <nz-tabset>
    <nz-tab nzTitle="Waiting"
      ><app-tab-waiting-special-leave [selectedType]="selectedType"></app-tab-waiting-special-leave
    ></nz-tab>
    <nz-tab nzTitle="Approved">
      <app-tab-approved-special-leave
        [selectedType]="selectedType"
      ></app-tab-approved-special-leave>
    </nz-tab>
    <nz-tab nzTitle="Canceled"
      ><app-tab-cancelled-special-leave
        [selectedType]="selectedType"
      ></app-tab-cancelled-special-leave
    ></nz-tab>
  </nz-tabset>
</div>
