<form nz-form [formGroup]="formAddSpecialLeave">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="type" class="text-left">Special Leave Type</nz-form-label>
        <nz-form-control nzErrorTip="Please input special leave type correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a special leave type"
            [(ngModel)]="selectedType"
            formControlName="type"
            (ngModelChange)="onTypeChange()"
          >
            <nz-option
              *ngFor="let option of listLeaveType"
              [nzLabel]="option.label"
              [nzValue]="option.label"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="dateOfLeave" class="text-left col-2">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="padding-left:12px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              formControlName="startValue"
              nzPlaceHolder="Start"
              (ngModelChange)="onChangeStart($event)"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleStartOpenChange($event)"
            ></nz-date-picker>
            <div style="margin-left: 4px; margin-right: 4px;">-</div>
            <nz-date-picker
              #endDatePicker
              (ngModelChange)="onChangeEnd($event)"
              formControlName="endValue"
              nzPlaceHolder="End"
              nzFormat="dd-MMM-yyyy"
              (nzOnOpenChange)="handleEndOpenChange($event)"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="substitution" class="text-left">Substitution</nz-form-label>
        <nz-form-control nzErrorTip="Please input substitution correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a substitution"
            [(ngModel)]="selectedValue"
            formControlName="substitution"
            style="padding-left: 44px;"
          >
            <nz-option
              *ngFor="let option of listOfSubtitution"
              [nzLabel]="option.employee_name"
              [nzValue]="option.employee_code"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="attachment" class="text-left">Attachment</nz-form-label>

        <nz-form-control nzErrorTip="Please input attachment correctly!">
          <nz-upload
            nzName="attachment"
            nzListType=""
            [nzLimit]="1"
            [(nzFileList)]="fileList"
            [nzCustomRequest]="customUpload"
            (nzChange)="handleChange($event)"
            [nzAccept]="'.pdf'"
            [nzDisabled]="fileList.length > 0"
            [nzShowButton]="!(fileList.length > 0)"
          >
            <button nz-button>
              <span nz-icon nzType="upload"></span>
              Click to Upload
            </button>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-5">
      <nz-form-item>
        <nz-form-label nzFor="reason" class="text-left">Reason</nz-form-label>
        <nz-form-control nzErrorTip="Please input reason correctly!">
          <input
            nz-input
            id="reason'"
            formControlName="reason"
            placeholder="Input reason"
            style="margin-left: 72px;"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div
      class="d-flex"
      style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
    >
      <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
      <button
        nz-button
        nzType="default"
        nzDanger
        style="background-color: rgb(231, 64, 64) !important; color: white;"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
