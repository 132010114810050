import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { BehaviorSubject, Observable, Observer, of, Subject } from 'rxjs'
import { HrService } from 'src/app/services/hr.service'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { EmployeeForm } from 'src/app/services/interface/employee.model'

interface options {
  codeName: string
  code: string
}

interface optionsOsas {
  name: string
}

interface optionArgs {
  type: string
  value: string
}

interface roleOptions {
  codeName: string
  code: string
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
})
export class AddEmployeeComponent implements OnInit {
  date = null
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  val: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private hrService: HrService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{3,16}$/)]),
      ],
      email: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])],
      shift: [null, Validators.compose([Validators.required])],
      code: [null, Validators.compose([Validators.required])],
      name: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])],
      company: [null, Validators.compose([Validators.required])],
      description: [null],
      dateOfBirth: [null],
      placeOfBirth: [null],
      registeredAddress: [null],
      currentAddress: [null],
      citizenNumber: [null],
      typeOfSIM: [null],
      maritalStatus: [null],
      numberOfChild: [null],
      position: [null, Validators.compose([Validators.required])],
      placement: [null, Validators.compose([Validators.required])],
      contractNumber: [null],
      rangeContract: [null],
      startContract: [null],
      endContract: [null],
      // rangeWork: [null],
      joinDate: [null],
      resignDate: [null],
      reason: [null],
      employementStatus: [null],
      phoneNumberPrefix: ['+62'],
      phoneNumber: [null],
      waNumberPrefix: ['+62'],
      waNumber: [null],
      bankCode: [null],
      bankAccountNumber: [null],
      bankAccountName: [null],
      roles: [null],
      location_agent: [null],
      profile_img: [null],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })
  searchChangeOSAS$ = new Subject<{ value: string }>()
  optionList2$: Observable<any[]>
  roleOptions: options[] = []
  roleOsasOptions: options[] = []
  bankInfoOptions: options[] = []
  employementStatusOptions: options[] = []
  shiftOptions: options[] = []
  companyOptions: options[] = []
  positionOptions: options[] = []
  placementOptions: options[] = []
  agentLocationsList: any[] = []
  // placementOptions: options[] = []

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    this.getRolesOSAS()

    const getRandomNameList = (args: optionArgs) =>
      this.hrService.shiftOptionLoad(args.value, args.type).pipe(
        catchError(() => of({ results: [] })),
        map((res: any) => {
          var r = {}
          r[args.type] = res
          return r
        }),
      )
    const optionList$: Observable<options[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap<optionArgs, Observable<any>>(getRandomNameList))

    this.searchChangeOSAS$
      .asObservable()
      .pipe(
        debounceTime(500),
        switchMap(args => this.getRandomNameList2(args)),
      )
      .subscribe(results => {
        this.agentLocationsList = results
        this.cdr.markForCheck()
      })

    // const getRandomNameList2 = (args: any) =>
    //   this.hrService.getAgentLocation(args.value).pipe(
    //     catchError(() => of({ results: [] })),
    //     map((res: any) => res.results),
    //   )

    optionList$.subscribe(r => {
      var keys = Object.keys(r)[0]
      switch (keys) {
        case 'role':
          this.roleOptions = r[keys]
          break
        case 'bankInfo':
          this.bankInfoOptions = r[keys]
          break
        case 'employementStatus':
          this.employementStatusOptions = r[keys]
          break
        case 'shifts':
          this.shiftOptions = r[keys]
          break
        case 'positions':
          this.positionOptions = r[keys]
          break
        case 'company':
          this.companyOptions = r[keys]
          break
        case 'placement':
          this.placementOptions = r[keys]
          break
      }
      this.isLoadingOptions = false
    })
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
  }

  getRandomNameList2(args: any): Observable<any[]> {
    return this.hrService.getAgentLocation(args.value).pipe(
      catchError(err => {
        // console.error('Error fetching agent location:', err)
        this.isLoadingOptions = false
        return of([])
      }),
      map((res: any) => {
        // console.log('API response:', res)
        this.isLoadingOptions = false
        return res || []
      }),
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    var isUploading = false
    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (!isUploading && this.form.valid) {
      var formSubs: EmployeeForm = this.form.value
      formSubs.phoneNumber = formSubs.phoneNumberPrefix + formSubs.phoneNumber
      formSubs.waNumber = formSubs.waNumberPrefix + formSubs.waNumber
      formSubs.signer = this.signer

      if (this.val === true) {
        formSubs.osas = {
          location_agent: this.form.value.location_agent || '',
          role: this.form.value.roles || '',
        }
      } else {
        // console.log(formSubs)
      }

      if (this.fileList[0] != null && this.fileList[0].status === 'done') {
        formSubs.profile_img = {
          name: this.fileList[0].response?.name || this.fileList[0].name,
          status: this.fileList[0].status,
          url: this.fileList[0].response?.url || this.fileList[0].url,
          uid: this.fileList[0].uid,
        }
      }
      this.hrService.createEmployee(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  searchOSAS(value: string) {
    this.isLoadingOptions = true
    // console.log('searchOSAS called with value:', value)
    this.searchChangeOSAS$.next({ value })
  }

  getRolesOSAS() {
    this.hrService.rolesFromOsas().subscribe(
      (data: any) => {
        this.roleOsasOptions = data
      },
      error => {
        // console.log(error)
      },
    )
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  checked(event: boolean) {
    this.val = event
    // console.log(this.val)
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ image: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }

  onChange(result: Date[]): void {
    this.form.patchValue({
      startContract: result[0],
      endContract: result[1],
    })
  }

  // joinChange(result: Date): void {
  //   this.form.patchValue({
  //     joinDate: result
  //   })
  // }

  // resignChange(result: Date): void {
  //   this.form.patchValue({
  //     resignDate: result
  //   })
  // }
}
