import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { ScheduleRoomService } from 'src/app/services/schedule-room.service'
import { MeetingRoomService } from 'src/app/services/meeting-room.service'
import { ScheduleRoomForm } from 'src/app/services/interface/schedule-room.model'
import { differenceInCalendarDays } from 'date-fns'

interface options {
  id: string
  name: string
}

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.scss'],
})
export class AddScheduleComponent implements OnInit {
  form: FormGroup
  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  user: any
  roomArr: options[] = []
  divArr: options[] = [
    {
      id: 'Assistant Director',
      name: 'Assistant Director',
    },
    {
      id: 'HRD',
      name: 'HRD',
    },
    {
      id: 'Finance & Accounting',
      name: 'Finance & Accounting',
    },
    {
      id: 'Quality Control',
      name: 'Quality Control',
    },
    {
      id: 'Trainer',
      name: 'Trainer',
    },
    {
      id: 'IT',
      name: 'IT',
    },
    {
      id: 'Multimedia',
      name: 'Multimedia',
    },
    {
      id: 'GA and Purchasing',
      name: 'GA and Purchasing',
    },
    {
      id: 'Marketing',
      name: 'Marketing',
    },
    {
      id: 'Other',
      name: 'Other',
    },
  ]
  colourArr: options[] = [
    {
      id: 'error',
      name: 'Red',
    },
    {
      id: 'success',
      name: 'Green',
    },
    {
      id: 'warning',
      name: 'Yellow',
    },
  ]
  @Input() date_start: Date = new Date()
  constructor(
    private fb: FormBuilder,
    private scheduleRoomService: ScheduleRoomService,
    private meetingRoomService: MeetingRoomService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      username: [{ value: '', disabled: true }],
      capacity: [null, Validators.compose([Validators.required, Validators.min(1)])],
      meetingRoom: [null, Validators.compose([Validators.required])],
      division: [null, Validators.compose([Validators.required])],
      agenda: [null, Validators.compose([Validators.required])],
      timeStart: [null, Validators.compose([Validators.required])],
      timeEnd: [null, Validators.compose([Validators.required])],
      dateStart: [null, Validators.compose([Validators.required])],
      dateEnd: [null, Validators.compose([Validators.required])],
      colour: [null, Validators.compose([Validators.required])],
    })

    this.meetingRoomService.roomOption().subscribe((r: any) => {
      this.roomArr = r
    })

    this.scheduleRoomService.username().subscribe(data => {
      this.user = data
      this.form.get('username').patchValue(data.name)
    })
  }

  disabledDateStart = (current: Date): boolean => {
    // console.log(differenceInCalendarDays(this.date_start,current))
    // Can not select days before today and today
    return (
      differenceInCalendarDays(current, this.date_start) > 0 ||
      differenceInCalendarDays(current, this.date_start) < 0
    )
  }

  disabledDateEnd = (current: Date): boolean => {
    // console.log(differenceInCalendarDays(this.date_start,current))
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.date_start) < 0
  }

  ngOnInit(): void {
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm() {
    this.isLoading = true

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (this.form.valid) {
      var formSubs: ScheduleRoomForm = this.form.value
      formSubs.username = this.user.username
      this.scheduleRoomService.createScheduleRoom(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
