import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CMSService } from 'src/app/services/cms.service'
import storeeeee from 'store'
import { TrainingForm } from 'src/app/services/interface/training.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { Observable, Observer } from 'rxjs'
import { Editor, Toolbar, toHTML } from 'ngx-editor'

interface options {
  id: string
  title: string
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-add-training',
  templateUrl: './add-training.component.html',
  styleUrls: ['./add-training.component.scss'],
})
export class AddTrainingComponent implements OnInit {
  editor: Editor
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ]
  isLoading: boolean = false
  fileList: NzUploadFile[] = []
  targetArr: options[] = [
    {
      id: 'OMA',
      title: 'OMA',
    },
    {
      id: 'OPA',
      title: 'OPA',
    },
    {
      id: 'DC',
      title: 'DC',
    },
    {
      id: 'HO',
      title: 'HO',
    },
  ]
  constructor(
    private fb: FormBuilder,
    private cmsService: CMSService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      target: [null, Validators.compose([Validators.required])],
      ppt_url: [null],
      youtube_url: [null],
      description: [null, Validators.compose([Validators.required])],
      status: [null, Validators.compose([Validators.required])],
      video: [null, Validators.compose([Validators.required])],
    })
  }

  ngOnDestroy(): void {
    this.editor = new Editor()
  }

  form: FormGroup

  ngOnInit(): void {
    this.editor = new Editor()
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm = (): void => {
    this.isLoading = true
    var isUploading = false
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (!isUploading && this.form.valid) {
      var formSubs: TrainingForm = this.form.value
      if (this.fileList[0].status === 'done') {
        formSubs.video = {
          name: this.fileList[0].response?.name || this.fileList[0].name,
          status: this.fileList[0].status,
          url: this.fileList[0].response?.url || this.fileList[0].url,
          uid: this.fileList[0].uid,
        }
      }
      formSubs.description = toHTML(this.form.value.description)
      this.cmsService.createTraining(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return
    }
  }

  // handleBeforeUpload = (file: NzUploadFile): boolean => {
  //   if (file.size <= 11534336) {
  //     return true
  //   } else {
  //     this.msg.error('File too Large')
  //     return false
  //   }
  // }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ video: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ video: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }
}
