import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { customAlphabet } from 'nanoid'
import { paginateQueryBA } from '../shared/interface.service'
import { LoadingModalComponent } from '../loading-modal/loading-modal.component'
import store from 'store'

@Injectable()
export class BrandingApprovalService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  numberCus = customAlphabet(this.apiService.numberCustom, 5)
  alphaCus = customAlphabet(this.apiService.alphabethCustom, 6)
  alfanumerikCus = customAlphabet(this.apiService.alfanumerikCustom, 8)
  Object: ObjectConstructor

  genNodeId(prefix: string) {
    return prefix + '-' + this.alfanumerikCus() + '-' + this.numberCus()
  }

  objectMap(object: any, mapFn: any) {
    return Object.keys(object).reduce((result: any, key: string) => {
      result[key] = mapFn(object[key])
      return result
    }, {})
  }

  getTypeOfNode(id: string, data: any[] = this.jsonTemplateFlow): any {
    var node, result
    for (let i = 0; i < data.length; i++) {
      node = data[i]
      if (node.id === id) {
        return node.type
      } else if (node.children) {
        result = this.getTypeOfNode(id, node.children)
        if (result) return result
      }
    }
  }

  getPathIndex(id: string, data: any[] = this.jsonTemplateFlow): any {
    var node, result
    for (let i = 0; i < data.length; i++) {
      node = data[i]
      if (node.id === id) {
        return [i]
      } else if (node.children) {
        result = this.getPathIndex(id, node.children)
        if (result) return [i, result]
      }
    }
  }

  getConditionForm(data: any[] = this.jsonTemplateFlow): any[] {
    var node,
      result = []
    for (let i = 0; i < data.length; i++) {
      node = data[i]
      if (node.type === 'childBranch') {
        for (const key in node.data.config) {
          if (!(key === 'initiator')) {
            console.log(key)
            console.log(node.data.config[key])
            if (['DB', 'SB'].includes(node.data.config[key].type)) {
              if (node.data.config[key].value.length > 0) {
                result.push(key)
              }
            } else {
              if (node.data.config[key].config.value) {
                result.push(key)
              }
            }
          }
        }
      } else if (node.children) {
        result.push(...this.getConditionForm(node.children))
      }
    }
    return result
  }

  public jsonTemplateForm: any[] = []
  public jsonTemplateFlow: any[] = []

  public readonly nodeDefault: any = {
    approver: {
      type: 'approver',
      data: {
        title: 'Approver',
        config: {
          type: 'designatedMember',
          target: [],
          permissions: {},
        },
        error: false,
      },
    },
    notifier: {
      type: 'notifier',
      data: {
        title: 'Notifier',
        config: {
          target: [],
        },
        error: false,
      },
    },
    branch: {
      type: 'branch',
    },
    childBranch1: {
      type: 'childBranch',
      data: {
        title: 'Condition 1',
        config: {},
        error: false,
      },
      children: [],
    },
    childBranch2: {
      type: 'childBranch',
      data: {
        title: 'Condition 2',
        config: {},
        error: false,
      },
      children: [],
    },
  }

  public readonly dicType: any = {
    SLI: { name: 'Single Line Input', icon: 'text_format' },
    MLI: { name: 'Multi Line Input', icon: 'format_align_left' },
    NU: { name: 'Numbers', icon: 'pin' },
    SB: { name: 'Single Box', icon: 'toc' },
    MCB: { name: 'Multi Check Boxesss', icon: 'check_box' },
    SD: { name: 'Single Date', icon: 'today' },
    DR: { name: 'Date Range', icon: 'date_range' },
    FI: { name: 'File', icon: 'upload_file' },
    IM: { name: 'Image', icon: 'add_photo_alternate' },
    CR: { name: 'Currency', icon: 'attach_money' },
    DB: { name: 'Double Box', icon: 'attach_money' },
    PR: { name: 'Percentage', icon: 'attach_money' },
    // TEL: { name: "Telephone", icon: "attach_money" },
  }

  public readonly elementList: any[] = [
    {
      type: 'SLI',
      title: 'Single Line Input',
      required: false,
      hintText: 'Hint Text',
      print: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'MLI',
      title: 'Multi Line Input',
      required: false,
      hintText: 'Hint Text',
      print: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'NU',
      title: 'Numbers',
      required: false,
      hintText: 'Hint Text',
      print: false,
      unit: null,
      format: null,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'SB',
      title: 'Single Box',
      required: false,
      hintText: 'Hint Text',
      print: false,
      option: [
        {
          value: 'Option 1',
          assoc: [],
        },
        {
          value: 'Option 2',
          assoc: [],
        },
      ],
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'MCB',
      title: 'Multi Check Boxes',
      required: false,
      hintText: 'Hint Text',
      print: false,
      option: ['Option 1', 'Option 2'],
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'SD',
      title: 'Single Date',
      required: false,
      hintText: 'Hint Text',
      print: false,
      min: { year: 0, month: 0, day: 0 },
      max: { year: 0, month: 0, day: 0 },
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'DR',
      title: 'Date Range',
      required: false,
      hintText: 'Hint Text',
      print: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      title: 'File',
      type: 'FI',
      print: false,
      required: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      title: 'Image',
      type: 'IM',
      print: false,
      required: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'CR',
      title: 'Currency',
      print: false,
      required: false,
      currencyMark: 'Rp',
      permission: {},
      isLinked: false,
      isHide: false,
    },
  ]

  public readonly bundleList: any[] = [
    {
      type: 'DB',
      components: {
        parent: {
          title: 'Single Box 1',
          hintText: 'Hint Text',
        },
        child: {
          title: 'Single Box 2',
          hintText: 'Hint Text',
        },
      },
      options: [
        { parent: 'Option 1', child: 'Option 1', assoc: [] },
        { parent: 'Option 2', child: 'Option 2', assoc: [] },
        { parent: 'Option 3', child: 'Option 3', assoc: [] },
      ],
      required: false,
      print: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
    {
      type: 'PR',
      components: {
        parent: {
          title: 'Percentage 1',
          hintText: 'Hint Text',
        },
        child: {
          title: 'Percentage 2',
          hintText: 'Hint Text',
        },
      },
      required: false,
      print: false,
      permission: {},
      isLinked: false,
      isHide: false,
    },
  ]

  public readonly elementListType = ['SLI', 'MLI', 'NU', 'SB', 'MCB', 'SD', 'DR', 'FI', 'IM', 'CR']
  public readonly bundleListType = ['DB', 'PR']

  get customHeader() {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    })
  }

  createApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/create', content, {
      headers: this.customHeader,
    })
  }

  copyCreateApproval(content: any) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/create/copy',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  getApprovalDetail(content: any) {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/details',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  copyApprovalDetail(content: any) {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/details/copy',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  returnedFirstStepApproval(content: any) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/next/first',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  nextApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/next', content, {
      headers: this.customHeader,
    })
  }

  bulkNextApproval(content: any) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/next/bulk',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  withdrawApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/withdraw', content, {
      headers: this.customHeader,
    })
  }

  rejectApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/reject', content, {
      headers: this.customHeader,
    })
  }

  returnApproval(content: any) {
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/return/approval',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  readApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/read', content, {
      headers: this.customHeader,
    })
  }

  bulkReadApproval(content: any) {
    console.log(content)
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/read/bulk',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  commentApproval(content: any) {
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/approval/comment', content, {
      headers: this.customHeader,
    })
  }

  uploadApprovalFiles(content: any) {
    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/upload',
      content,
      {
        headers: this.customHeader,
        reportProgress: true,
        withCredentials: false,
      },
    )
    return this.http.request(req)
  }

  historyApproval(documentNumber: string) {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/approval/history', {
      headers: this.customHeader,
      params: {
        documentNumber: documentNumber,
      },
    })
  }

  returnFlowsHistroy(documentNumber: string) {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/approval/return/flows', {
      headers: this.customHeader,
      params: {
        documentNumber: documentNumber,
      },
    })
  }

  bulkPrintApproval(content: any) {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/approval/print', {
      headers: this.customHeader,
      params: { listId: content.join(',') },
    })
  }

  //  ------------- To Do Task || Completed Task || CC Task -------------------

  getApproval(type: string, paginateQuery: paginateQueryBA) {
    console.log(paginateQuery, 'paginate query')
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/task/' + type,
      paginateQuery,
      {
        headers: this.customHeader,
      },
    )
  }

  getApprovalAdmin(type: string, paginateQuery: paginateQueryBA) {
    console.log(paginateQuery, 'paginate query2')

    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/task/admin/' + type,
      paginateQuery,
      {
        headers: this.customHeader,
      },
    )
  }

  //  ------------- Completed Task -------------------

  getCompletedTask(paginateQuery: paginateQueryBA) {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/approval/task/completed', {
      headers: this.customHeader,
      params: {
        pageIndex: paginateQuery.pageIndex - 1,
        pageSize: paginateQuery.pageSize,
        keyword: paginateQuery.keyword,
      },
    })
  }

  //  ------------- CC Task -------------------

  getCCTask(paginateQuery: paginateQueryBA) {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/approval/task/cc', {
      headers: this.customHeader,
      params: {
        pageIndex: paginateQuery.pageIndex - 1,
        pageSize: paginateQuery.pageSize,
        keyword: paginateQuery.keyword,
      },
    })
  }

  // ----------------- Transfer ----------------------

  detailApprovalTransfer(content: any) {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/transfer/details',
      {
        headers: this.customHeader,
        params: content,
      },
    )
  }

  employeeOtherRoutes() {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/transfer/other-routes/employee',
      {
        headers: this.customHeader,
      },
    )
  }

  flowOnRoutes(documentNumber: string) {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/transfer/on-routes/flow',
      {
        headers: this.customHeader,
        params: {
          documentNumber: documentNumber,
        },
      },
    )
  }

  transferToBulk(content: any) {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/transfer/multiple',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  transferToSingleInput(content: any) {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/transfer/single',
      content,
      {
        headers: this.customHeader,
      },
    )
  }

  notificationApproval(content: any) {
    return this.http.get<any>(this.apiService.API_SUNSHINE_SERVER + '/api/approval/notification', {
      headers: this.customHeader,
      params: content,
    })
  }

  notificationRead(content: any) {
    console.log(content)
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/approval/notification/read/' + content,
      {},
      {
        headers: this.customHeader,
        params: content,
      },
    )
  }
}
