import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { ApiService } from './api.service'
import { BehaviorSubject, Observable } from 'rxjs'
import store from 'store'
import { ShiftForm } from './interface/shift.model'
import { QueryTable } from './interface/general.model'
import { CreateShiftSchedulerForm } from './interface/shift-scheduler.model'

@Injectable({
  providedIn: 'root',
})
export class SalesService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get customHeader() {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    })
  }

  getAttendance(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/sales/report/get-data',
      queryTable,
      params,
    )
  }

  exportAttendance(date: any): Observable<any> {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/sales/report/export', {
      headers: this.customHeader,
      params: {
        date: date,
      },
    })
  }

  exportAttendanceByMonth(date: any): Observable<any> {
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/sales/report/export-month', {
      headers: this.customHeader,
      params: {
        date: date,
      },
    })
  }
}
