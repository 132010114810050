<div class="container-top">
  <div class="container-button">
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Apply Leave">
      <a class="btn btn-lg btn-light mr-2" (click)="addLeave()">
        <i class="fe fe-plus-circle"></i>
      </a>
    </span>
    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
      <a class="btn btn-lg btn-light mr-2" (click)="reset()">
        <i class="fe fe-refresh-cw"></i>
      </a>
    </span>

    <nz-date-picker
      nzMode="year"
      [(ngModel)]="selectedFilter"
      (ngModelChange)="onChangeYear($event)"
      nzPlaceHolder="YEAR"
    ></nz-date-picker>
  </div>
</div>

<div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <nz-table
            #fixedTable
            nzShowSizeChanger
            class="table-MeetingRooming"
            [nzData]="listOfData"
            [nzFrontPagination]="false"
            [nzLoading]="isLoading"
            [nzTotal]="totalData"
            [nzPageSize]="pageSize"
            [nzPageIndex]="pageIndex"
            [nzPageSizeOptions]="[5, 10, 20, 50]"
            (nzQueryParams)="onQueryParamsChange($event)"
            [nzScroll]="{ y: '42vh', x: '900px' }"
            nzSize="small"
          >
            <thead>
              <tr>
                <ng-container *ngFor="let column of listOfColumns">
                  <th
                    *ngIf="column.sortFn"
                    [nzLeft]="column.left"
                    [nzRight]="column.right"
                    [nzSortFn]="column.sortFn"
                    [(nzSortOrder)]="column.sortOrder"
                    [nzColumnKey]="column.key"
                    [nzWidth]="column.width"
                  >
                    {{ column.name }}
                  </th>
                  <th
                    *ngIf="!column.sortFn"
                    [nzLeft]="column.left"
                    [nzRight]="column.right"
                    [nzColumnKey]="column.key"
                    [nzWidth]="column.width"
                  >
                    {{ column.name }}
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fixedTable.data">
                <ng-container *ngFor="let column of listOfColumns">
                  <td
                    *ngIf="column.key == 'actions'"
                    [nzLeft]="column.left"
                    [nzRight]="column.right"
                  >
                    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Detail">
                      <a class="btn btn-sm btn-light mr-2" (click)="viewDetailLeave(data.id)">
                        <span nz-icon nzType="eye" nzTheme="outline"></span>
                      </a>
                    </span>
                    <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Leave">
                      <a class="btn btn-sm btn-light mr-2" (click)="deleteLeave(data.id)">
                        <i class="fe fe-trash-2"></i>
                      </a>
                    </span>
                  </td>

                  <td
                    *ngIf="column.key != 'actions'"
                    [nzLeft]="column.left"
                    [nzRight]="column.right"
                  >
                    {{ data[column.key] }}
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>
