import { Component, OnInit } from '@angular/core'
import { QueryTable } from 'src/app/services/interface/shift.model'
import { NzTableQueryParams, NzTableFilterList, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ShiftService } from 'src/app/services/shift.service'
import { Attendance } from 'src/app/services/interface/attendance.model'
import { ApiService } from 'src/app/services/api.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}
@Component({
  selector: 'app-employee-shift',
  templateUrl: './employee-shift.component.html',
})
export class EmployeeShiftComponent implements OnInit {
  listOfData: Attendance[] = []

  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  previewVisible = false
  previewImage: string
  form: FormGroup
  constructor(
    private shiftService: ShiftService,
    private apiService: ApiService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
    date: [],
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Employee Code',
      key: 'employee_code',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '40px',
    },
    {
      name: 'Employee Name',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Work Status',
      key: 'work_status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '40px',
    },
    {
      name: 'Late Status',
      key: 'late',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '40px',
    },
    {
      name: 'Work Duration',
      key: 'duration',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '40px',
    },
    {
      name: 'Clock In',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Clock Out',
      key: 'updated_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Reference In',
      key: 'ref_in',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Reference Out',
      key: 'ref_out',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Shift Name',
      key: 'shift_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '60px',
    },
    {
      name: 'Status In',
      key: 'status_in',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '35px',
    },
    {
      name: 'Status Out',
      key: 'status_out',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '35px',
    },
  ]

  ngOnInit(): void {
    this.fetchAttendance()
  }

  fetchAttendance() {
    this.isLoading = true
    this.shiftService.getEmpShift(this.queryTable).subscribe((r: any) => {
      // console.log(r)
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAttendance()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
      this.queryTable.date = []
      this.date = null
    } else {
      this.queryTable.filters = []
    }
    this.fetchAttendance()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.queryTable.date = []
    this.date = null
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchAttendance()
  }

  date = null
  onChange(result: Date[]): void {
    if (result != null) {
      this.queryTable.date = [
        {
          field: 'date',
          startDate: result[0],
          endDate: result[1],
        },
      ]
    } else {
      this.queryTable.date = []
    }
    this.fetchAttendance()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  openImage(filename: string) {
    this.previewImage = this.apiService.API_SUNSHINE_SERVER + '/api/attendance/foto/' + filename
    this.previewVisible = true
  }
}
