import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
// import { Voucher } from 'src/app/services/interface/voucher.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AddVoucherComponent } from './add-voucher/add-voucher.component'
import { EditVoucherComponent } from './edit-voucher/edit-voucher.component'
import { VoucherService } from 'src/app/services/onapps/voucher.service'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  // styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {
  // listOfData: Voucher[] = []
  listOfData = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private onappsService: VoucherService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      // prefix: ['title', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '90px',
    },
    {
      name: 'Last Updated',
      key: 'updatedAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Code',
      key: 'code',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Name',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
    {
      name: 'Type',
      key: 'type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '100px',
    },
    {
      name: 'Start Date',
      key: 'startDate',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
    {
      name: 'Expiry Date',
      key: 'dueDate',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '180px',
    },
    {
      name: 'Created By',
      key: 'createdBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
    {
      name: 'Updated By',
      key: 'updatedBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
    {
      name: 'Is Inner City',
      key: 'is_inner_city',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '250px',
    },
  ]

  ngOnInit(): void {
    this.fetchVoucher()
  }

  fetchVoucher() {
    this.isLoading = true
    this.onappsService.getVoucher(this.queryTable).subscribe((r: any) => {
      console.log(r)
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchVoucher()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: null,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchVoucher()
  }

  reset() {
    this.form.reset()
    // this.form.patchValue({ prefix: 'title' })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchVoucher()
  }

  addVoucher() {
    const modal = this.modal.create({
      nzTitle: 'Add New Voucher',
      nzContent: AddVoucherComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchVoucher()
    })
  }

  viewVoucher(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Voucher',
      nzContent: EditVoucherComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          if (instance.submitForm()) {
            resolve
          } else {
            reject
          }
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchVoucher()
    })
  }

  showDeleteConfirm(id: string): void {
    this.modal.confirm({
      nzTitle: 'Are you sure you want to delete this voucher?',
      nzContent: '<b style="color: red;">You will not be able to undo this once it is done.</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        // console.log('OK')
        this.onappsService.deleteVoucher(id).subscribe(
          (r: any) => {
            this.notification.success('Success', r.message)
            this.fetchVoucher()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchVoucher()
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }
}
