import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { HrService } from 'src/app/services/hr.service'
import { PositionForm } from 'src/app/services/interface/position.model'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { BehaviorSubject, Observable, of } from 'rxjs'

interface options {
  id: string
  name: string
}

interface optionArgs {
  type: string
  value: string
}

@Component({
  selector: 'app-add-position',
  templateUrl: './add-position.component.html',
})
export class AddPositionComponent implements OnInit {
  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  headArr: options[] = []

  constructor(
    private fb: FormBuilder,
    private hrService: HrService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      parent_id: [null],
      head: [null, Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    const getRandomNameList = (args: optionArgs) =>
      this.hrService.headOption(args.value, args.type).pipe(
        catchError(() => of({ results: [] })),
        map((res: any) => {
          var r = {}
          r[args.type] = res
          return r
        }),
      )
    const optionList$: Observable<options[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(300))
      .pipe(switchMap<optionArgs, Observable<any>>(getRandomNameList))

    optionList$.subscribe(r => {
      var keys = Object.keys(r)[0]
      switch (keys) {
        case 'head':
          this.headArr = r[keys]
          break
      }
      this.isLoadingOptions = false
    })
  }

  switchValue = false
  loading = false

  clickSwitch(): void {
    if (!this.loading) {
      this.loading = true
      setTimeout(() => {
        this.switchValue = !this.switchValue
        this.loading = false
        this.form.value.disabled = this.switchValue
      }, 10)
    }
  }

  cancel(): void {
    this.modal.destroy()
  }

  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  submitForm() {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    if (this.form.valid) {
      var formSubs: PositionForm = this.form.value
      this.hrService.createPosition(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
