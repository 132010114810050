import { Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
import { Router } from '@angular/router'

@Component({
  selector: 'vb-topbar-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class TopbarUserMenuComponent {
  name: string = ''
  code: string = ''
  username: string = ''
  role: string = ''
  email: string = ''
  phone: string = ''
  profile_img: any = ''

  constructor(private store: Store<any>, private router: Router) {
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.name = state.name
      this.code = state.code
      this.username = state.username
      this.role = state.roleName
      this.email = state.email
      this.phone = state.phone
      this.profile_img = state.profile_img
    })
  }

  logout() {
    this.store.dispatch(new UserActions.Logout())
  }
  editProfile() {
    this.router.navigate(['/user-profile'])
    // console.log('YEsh')
  }
}
