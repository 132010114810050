import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTableLeaveConfig } from 'src/app/services/interface/general.model'
import { NzTableQueryParams } from 'ng-zorro-antd/table'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AddLeaveConfigComponent } from './add-leave-config/add-leave-config.component'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { LeaveConfigType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-leave-config',
  templateUrl: './leave-config.component.html',
  styleUrls: ['./leave-config.component.scss'],
})
export class LeaveConfigComponent implements OnInit {
  listOfData: LeaveConfigType[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private leaveManagementService: LeaveManagementService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTableLeaveConfig = {
    sort: null,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns = [
    {
      name: 'Type Config',
      key: 'type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Name',
      key: 'employee_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Quota',
      key: 'quota',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
    {
      name: 'Date Config',
      key: 'created_at',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '50px',
    },
  ]

  ngOnInit(): void {
    this.fetchListConfig()
  }

  fetchListConfig() {
    this.isLoading = true
    this.leaveManagementService.listConfig(this.queryTable).subscribe(
      (r: any) => {
        this.isLoading = false
        this.listOfData = r.rows

        this.totalData = r.count
      },
      error => {
        this.isLoading = false
      },
    )
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchListConfig()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchListConfig()
  }

  addLeave() {
    const modal = this.modal.create({
      nzTitle: 'Form Leave Config',
      nzContent: AddLeaveConfigComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Submit',
      nzOkDisabled: true,
      nzOnOk: () => {
        const instance = modal.getContentComponent()
        // instance.submitForm()
      },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchListConfig()
    })
  }
}
