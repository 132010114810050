import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { OnMitraService } from 'src/app/services/on-mitra.service'
import { Mitra } from 'src/app/services/interface/on-mitra.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { EditPendingComponent } from './edit-pending/edit-pending.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-on-mitra-pending',
  templateUrl: './on-mitra-pending.component.html',
})
export class OnMitraPendingComponent implements OnInit {
  listOfData: Mitra[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup

  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private mitraService: OnMitraService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      keyword: [null],
      prefix: ['name', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '60px',
    },
    {
      name: 'First Name',
      key: 'first_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Last Name',
      key: 'last_name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Email',
      key: 'email',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Vehicle Type',
      key: 'vehicle_type',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Registered At',
      key: 'createdAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
  ]

  ngOnInit(): void {
    this.fetchPending()
  }

  fetchPending() {
    this.isLoading = true
    this.mitraService.getPending(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchPending()
  }

  search() {
    if (this.form.value.keyword?.length > 0) {
      this.queryTable.filters = [
        {
          field: this.form.value.prefix,
          keyword: this.form.value.keyword,
        },
      ]
    } else {
      this.queryTable.filters = []
    }
    this.fetchPending()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: null })
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchPending()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  viewMitra(_id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Pending Mitra',
      nzContent: EditPendingComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        _id: _id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      // nzOkText: 'Verify',
      // nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchPending()
    })
  }
}
