<form nz-form [formGroup]="formAddAnnualLeave">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="type" class="text-left">Special Leave Type</nz-form-label>
        <nz-form-control nzErrorTip="Please input special leave type correctly!">
          <input nz-input formControlName="type" disabled="true" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="dateOfLeave" class="text-left">Date</nz-form-label>
        <nz-form-control nzErrorTip="Please pick date correctly!">
          <div style="margin-left: 18px; display: flex; flex-direction: row; align-items: center;">
            <nz-date-picker
              formControlName="startValue"
              nzPlaceHolder="Start"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formAddAnnualLeave.value.startValue"
              [nzDisabled]="true"
            ></nz-date-picker>
            <div style="margin-left: 4px; margin-right: 4px;">-</div>
            <nz-date-picker
              #endDatePicker
              formControlName="endValue"
              nzPlaceHolder="End"
              nzFormat="dd-MMM-yyyy"
              [nzDefaultPickerValue]="formAddAnnualLeave.value.endValue"
              [nzDisabled]="true"
            ></nz-date-picker>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="substitution" class="text-left">Substitution</nz-form-label>
        <nz-form-control nzErrorTip="Please input substitution correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a substitution"
            [(ngModel)]="selectedValue"
            formControlName="substitution"
            style="padding-left: 44px;"
          >
            <nz-option
              *ngFor="let option of listOfSubtitution"
              [nzLabel]="option.employee_name"
              [nzValue]="option.employee_code"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="reason" class="text-left">Reason</nz-form-label>
        <nz-form-control nzErrorTip="Please input reason correctly!">
          <input nz-input id="reason'" formControlName="reason" disabled="true" />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-5">
      <nz-form-item>
        <nz-form-label nzFor="attachement" class="text-left">Attachment</nz-form-label>
        <nz-form-control>
          <!-- <span style="margin-left: 48px;">*Has submitted the attachment</span> -->
          <!-- formControlName="attachement" -->
          <input
            nz-input
            id="attachement"
            disabled="true"
            style="margin-left: 48px;"
            placeholder="*Has submitted the attachment"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div
      class="d-flex"
      style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
    >
      <button
        nz-button
        nzType="primary"
        (click)="submitForm()"
        *ngIf="selectedFilter === 'WAITING'"
      >
        Edit
      </button>
      <button
        nz-button
        nzType="default"
        nzDanger
        style="background-color: rgb(231, 64, 64) !important; color: white;"
        (click)="closeModal()"
      >
        Close
      </button>
    </div>
  </div>
</form>
