<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table
          #fixedTable
          [nzShowSizeChanger]="false"
          class="table-ticketing"
          [nzData]="listOfData"
          [nzFrontPagination]="false"
          [nzLoading]="isLoading"
          [nzTotal]="totalData"
          [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzScroll]="{ y: '42vh' }"
          [nzTitle]="headerTable"
          [nzFooter]="footerTable"
          nzSize="small"
        >
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <th
                  *ngIf="column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzSortFn]="column.sortFn"
                  [(nzSortOrder)]="column.sortOrder"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
                <th
                  *ngIf="!column.sortFn"
                  [nzLeft]="column.left"
                  [nzRight]="column.right"
                  [nzColumnKey]="column.key"
                  [nzWidth]="column.width"
                >
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of fixedTable.data">
              <ng-container *ngFor="let column of listOfColumns; trackBy: trackByName">
                <td *ngIf="column.key == 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Ticket">
                    <a class="btn btn-sm btn-light mr-2" (click)="viewTicket(data.tag)">
                      <i class="fe fe-eye"></i>
                    </a>
                  </span>
                  <span style="margin: 0 2px;"></span>
                  <span
                    nzTooltipPlacement="top"
                    nz-tooltip
                    nzTooltipTitle="Delete Ticket"
                    *ngIf="role == 'head_qc' || role == 'administrator'"
                  >
                    <a class="btn btn-sm btn-light mr-2" (click)="deleteTicket(data.tag)">
                      <i class="fe fe-trash-2"></i>
                    </a>
                  </span>
                </td>
                <td *ngIf="column.key != 'actions'" [nzLeft]="column.left" [nzRight]="column.right">
                  <ng-container *ngIf="column.key === 'status'; else notStatus">
                    <span
                      [ngClass]="[
                        data[column.key] === 'Remainders'
                          ? 'font-size-12 badge badge-danger'
                          : data[column.key] === 'Solved'
                          ? 'font-size-12 badge badge-success'
                          : data[column.key] === 'Open'
                          ? 'font-size-12 badge badge-info'
                          : data[column.key] === 'In Progress'
                          ? 'font-size-12 badge badge-primary'
                          : 'font-size-12 badge badge-warning'
                      ]"
                    >
                      {{ data[column.key] }}
                    </span>
                  </ng-container>
                  <ng-template #notStatus>
                    {{ data[column.key] }}
                  </ng-template>
                </td>
              </ng-container>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #tplFooter let-ref="modalRef">
  <button
    nz-button
    (click)="ref.destroy()"
    [disabled]="ref.getContentComponent().isLoading"
    nzDanger
  >
    Cancel
  </button>
  <button
    nz-button
    nzType="primary"
    (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading"
  >
    Create
  </button>
</ng-template>

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col">
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Ticket">
          <a class="btn btn-lg btn-light mr-2" (click)="addTicket(tplFooter)">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Restart Filter & Sort">
          <a class="btn btn-lg btn-light mr-2" (click)="reset()">
            <i class="fe fe-refresh-cw"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Tickets">
          <a class="btn btn-lg btn-light mr-2" (click)="exportTickets()">
            <i class="fe fe-download"></i>
          </a>
        </span>
      </div>
      <div class="col">
        <form nz-form [formGroup]="form" (ngSubmit)="search()" class="float-right">
          <div
            formArrayName="filters"
            *ngFor="let filter of advancedFilters.controls; let i = index"
          >
            <div nz-form [formGroupName]="i" [nzLayout]="'inline'">
              <nz-form-item>
                <nz-form-control>
                  <nz-select
                    id="prefix"
                    formControlName="prefix"
                    nzPlaceHolder="Select Prefix ..."
                    style="width: 150px;"
                  >
                    <nz-option nzValue="vendor" nzLabel="Vendor"></nz-option>
                    <nz-option nzValue="agent" nzLabel="Agent"></nz-option>
                    <nz-option nzValue="waybill" nzLabel="Waybill"></nz-option>
                    <nz-option nzValue="person" nzLabel="Person"></nz-option>
                    <nz-option nzValue="problemType" nzLabel="Problem Type"></nz-option>
                    <nz-option nzValue="createdAt" nzLabel="Created At"></nz-option>
                    <nz-option nzValue="createdBy" nzLabel="Created By"></nz-option>
                    <nz-option nzValue="updatedAt" nzLabel="Updated At"></nz-option>
                    <!-- <nz-option
                      nzValue="lastUserCommented"
                      nzLabel="Last User Commented"
                    ></nz-option> -->
                    <!-- <nz-option nzValue="status" nzLabel="Status"></nz-option> -->
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <ng-container
                    *ngIf="
                      [
                        'vendor',
                        'agent',
                        'waybill',
                        'person',
                        'problemType',
                        'createdBy',
                        'lastUserCommented'
                      ].includes(advancedFilters.at(0).get('prefix').value)
                    "
                  >
                    <input formControlName="keyword" nz-input placeholder="Keyword ..." />
                  </ng-container>
                  <ng-container
                    *ngIf="['createdAt','updatedAt',].includes(advancedFilters.at(0).get('prefix').value)"
                  >
                    <nz-range-picker formControlName="keyword"></nz-range-picker>
                  </ng-container>
                  <!-- <ng-container *ngIf="advancedFilters.at(0).get('prefix').value === 'status'">
                  <nz-select formControlName="keyword" nzPlaceHolder="Select Status ...">
                    <nz-option nzValue="Open" nzLabel="Open"></nz-option>
                    <nz-option nzValue="In Progress" nzLabel="In Progress"></nz-option>
                    <nz-option nzValue="Remainders" nzLabel="Remainders"></nz-option>
                    <nz-option nzValue="Request Delete" nzLabel="Request Delete"></nz-option>
                    <nz-option nzValue="Solved" nzLabel="Solved"></nz-option>
                  </nz-select>
                </ng-container> -->
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-control>
                  <button type="submit" class="btn btn-primary" [disabled]="!form.valid">
                    Search
                  </button>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>

<ng-template #footerTable>
  <div class="row">
    <div class="col">
      <span *ngFor="let item of statusTotal" class="px-2">
        <a
          [ngClass]="[
            item.status === 'Remainders'
              ? 'font-size-12 badge badge-danger'
              : item.status === 'Solved'
              ? 'font-size-12 badge badge-success'
              : item.status === 'Open'
              ? 'font-size-12 badge badge-info'
              : item.status === 'In Progress'
              ? 'font-size-12 badge badge-primary'
              : item.status === 'Total'
              ? 'font-size-12 badge badge-secondary'
              : 'font-size-12 badge badge-warning'
          ]"
          (click)="statusFiltered = item.status; filterStatus()"
          >{{ item.status }}</a
        >
        <span class="px-1"></span>
        <span
          [ngClass]="{
            'font-size-12 badge badge-dark': statusFiltered === item.status,
            'text-white': statusFiltered === item.status
          }"
          >{{ item.count }}</span
        >
      </span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
