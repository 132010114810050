import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { ListSubstituteType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-view-detail-special-leave',
  templateUrl: './view-detail-special-leave.component.html',
  styleUrls: ['./view-detail-special-leave.component.scss'],
})
export class ViewDetailSpecialLeaveComponent implements OnInit {
  @Input() leaveId: string
  @Input() selectedFilter: string
  formAddAnnualLeave: FormGroup
  isLoading: boolean = false
  selectedValue = null
  listOfSubtitution: ListSubstituteType[] = []

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent

  constructor(
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private leaveManagementService: LeaveManagementService,
  ) {
    this.formAddAnnualLeave = this.fb.group({
      attachement: [{ value: '', disabled: true }, [Validators.required]],
      startValue: [{ value: '', disabled: true }, [Validators.required]],
      endValue: [{ value: '', disabled: true }, [Validators.required]],
      reason: [{ value: '', disabled: true }, [Validators.required]],
      substitution: ['', [Validators.required]], // Keep this editable
      type: [{ value: '', disabled: true }, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.fetchListSubstitute()

    this.leaveManagementService.getDetailAnnualLeave(this.leaveId).subscribe(
      (r: any) => {
        console.log({ r })
        const data = {
          attachement: r.attachment,
          startValue: r.date[0],
          endValue: r.date[1],
          reason: r.reason,
          substitution: r.substitute_name,
          type: r.type,
        }

        this.formAddAnnualLeave.patchValue(data)

        const selectedSubstitute = this.listOfSubtitution.find(
          option => option.employee_name === r.substitute_name,
        )

        if (selectedSubstitute) {
          this.formAddAnnualLeave.get('substitution')?.setValue(selectedSubstitute.employee_code)
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

    if (this.formAddAnnualLeave.valid) {
      let payload = {
        substitute_code: this.formAddAnnualLeave.value.substitution,
      }
      this.modal.destroy()
      var formSubs: Object = payload
      this.leaveManagementService.editRequestLeave(this.leaveId, formSubs).subscribe(
        r => {
          this.notification.success('Success', 'Successfully Edit Special Leave')
          this.modal.destroy()
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  closeModal(): void {
    this.modal.destroy()
  }

  fetchListSubstitute() {
    this.isLoading = true
    this.leaveManagementService.getListSubstitute().subscribe((r: any) => {
      this.isLoading = false
      this.listOfSubtitution = r

      console.log(this.listOfSubtitution)
    })
  }
}
