<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="first_name" class="text-left">First Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input first name!">
                        <input class="content" nz-input id="'first_name'" formControlName="first_name" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-form-item>
                    <nz-form-label nzFor="last_name" class="text-left">Last Name</nz-form-label>
                    <nz-form-control nzErrorTip="Please input last name!">
                        <input class="content" nz-input id="'last_name'" formControlName="last_name" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-6">
                <nz-form-item>
                    <nz-form-label nzFor="disabled" class="text-left">Mitra's Status</nz-form-label>
                    <nz-form-control nzErrorTip="Select available status!">
                        <nz-select formControlName="disabled" placeholder="Choose Mitra's Status">
                            <nz-option nzLabel="Active" [nzValue]="false"></nz-option>
                            <nz-option nzLabel="Disabled" [nzValue]="true"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="col-lg-6 col-md-12">
                <div *ngIf="([disabled] == 'true')">
                    <nz-form-item>
                        <nz-form-label nzFor="disabled_by" class="text-left">Disabled By</nz-form-label>
                        <nz-form-control>
                            <input class="content" nz-input id="'disabled_by'" formControlName="disabled_by" readonly />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-lg-6 col-md-12">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Personal Information">
                        <div class="row">
                            <div class="col-5">
                                <nz-form-item>
                                    <nz-form-label nzFor="gender" class="text-left">Gender</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input gender!">
                                        <input class="content" nz-input id="'gender'" formControlName="gender" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-7">
                                <nz-form-item>
                                    <nz-form-label nzFor="date_of_birth" class="text-left">Date of Birth</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input valid time">
                                        <div>
                                            <nz-date-picker formControlName="date_of_birth" nzFormat="dd-MM-yyyy">
                                            </nz-date-picker>
                                        </div>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-12">
                                <nz-form-item>
                                    <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input email!">
                                        <input class="content" nz-input id="'email'" formControlName="email" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-12">
                                <nz-form-item>
                                    <nz-form-label nzFor="phoneNumber">Phone Number</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input your phone number!">
                                        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                                            <ng-template #addOnBeforeTemplate>
                                                <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                                                    <nz-option nzLabel="+62" nzValue="+62"></nz-option>
                                                </nz-select>
                                            </ng-template>
                                            <input formControlName="phone_number" id="'phone_number'" nz-input />
                                        </nz-input-group>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-7">
                                <nz-form-item>
                                    <nz-form-label nzFor="vehicle_type" class="text-left">Vehicle Type</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input vehicle type!">
                                        <input class="content" nz-input id="'vehicle_type'"
                                            formControlName="vehicle_type" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-5">
                                <nz-form-item>
                                    <nz-form-label nzFor="plat_no" class="text-left">Plat No</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input vehicle's plate number!">
                                        <input class="content" nz-input id="'plat_no'" formControlName="plat_no" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-12">
                                <nz-form-item>
                                    <nz-form-label nzFor="npwp_no" class="text-left">NPWP No</nz-form-label>
                                    <nz-form-control nzErrorTip="Please input NPWP number!">
                                        <input class="content" nz-input id="'npwp_no'" formControlName="npwp_no" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </nz-collapse-panel>
                </nz-collapse>
            </div>
            <div class="col-lg-6 col-md-12">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Account Information">
                        <div class="row">
                            <div class="col-12">
                                <nz-form-item>
                                    <nz-form-label nzFor="balance" class="text-left">Balance</nz-form-label>
                                    <nz-form-control>
                                        <input class="content" nz-input id="'balance'" formControlName="balance"
                                            readonly />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-9">
                                        <nz-form-item>
                                            <nz-form-label nzFor="imei" class="text-left">Unique Key</nz-form-label>
                                            <nz-form-control>
                                                <input nz-input id="'imei'" formControlName="imei" readonly />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-3">
                                        <button nz-button nzDanger (click)="resetUniqueKey()">Reset Key</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-9">
                                        <nz-form-item>
                                            <nz-form-label nzFor="password" class="text-left">Password</nz-form-label>
                                            <nz-form-control>
                                                <input nz-input id="'password'" formControlName="password"
                                                    placeholder="***********" readonly />
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-3">
                                        <button nz-button nzDanger (click)="resetPassword()">Reset Pass</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6">
                                        <nz-form-item>
                                            <nz-form-label nzFor="join_date" class="text-left">Join Date</nz-form-label>
                                            <nz-form-control>
                                                <div>
                                                    <nz-date-picker formControlName="join_date" [nzDisabled]
                                                        nzFormat="dd-MM-yyyy" nzDisabled='true'>
                                                    </nz-date-picker>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div class="col-6">
                                        <nz-form-item>
                                            <nz-form-label nzFor="last_job" class="text-left">Latest Job</nz-form-label>
                                            <nz-form-control>
                                                <div>
                                                    <nz-date-picker formControlName="last_job" nzDisabled
                                                        nzFormat="dd-MM-yyyy">
                                                    </nz-date-picker>
                                                </div>
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <nz-form-item>
                                    <nz-form-label nzFor="verified_by" class="text-left">Verified By</nz-form-label>
                                    <nz-form-control>
                                        <input class="content" nz-input id="'verified_by'" formControlName="verified_by"
                                            readonly />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <div class="col-6">
                                <div *ngIf="[updatedBy] != null">
                                    <nz-form-item>
                                        <nz-form-label nzFor="updated_by" class="text-left">Updated By</nz-form-label>
                                        <nz-form-control>
                                            <input class="content" nz-input id="'updated_by'"
                                                formControlName="updated_by" readonly />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </div>
                    </nz-collapse-panel>
                </nz-collapse>
            </div>
        </div>
        <div class="row" style="margin-bottom: 15px;">
            <div class="col-12">
                <nz-collapse>
                    <nz-collapse-panel nzHeader="Verified Mitra Documents">
                        <div class="row">
                            <div class="col-6">
                                <nz-form-label nzFor="profile_img" class="text-left">Profile Picture</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['profile_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('profile_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['profile_img'].length > 0"
                                        [nzShowButton]="!(fileList['profile_img'].length > 0)"
                                        (nzChange)="handleChange($event,'profile_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                    <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                                        (nzOnCancel)="previewVisible = false">
                                        <ng-template #modalContent>
                                            <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                                        </ng-template>
                                    </nz-modal>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="ktp_img" class="text-left">KTP</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['ktp_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('ktp_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['ktp_img'].length > 0"
                                        [nzShowButton]="!(fileList['ktp_img'].length > 0)"
                                        (nzChange)="handleChange($event,'ktp_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="kk_img" class="text-left">Kartu Keluarga</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['kk_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('kk_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['kk_img'].length > 0"
                                        [nzShowButton]="!(fileList['kk_img'].length > 0)"
                                        (nzChange)="handleChange($event,'kk_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="sim_img" class="text-left">SIM</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['sim_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('sim_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['sim_img'].length > 0"
                                        [nzShowButton]="!(fileList['sim_img'].length > 0)"
                                        (nzChange)="handleChange($event,'sim_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="stnk_img" class="text-left">STNK</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['stnk_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('stnk_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['stnk_img'].length > 0"
                                        [nzShowButton]="!(fileList['stnk_img'].length > 0)"
                                        (nzChange)="handleChange($event,'stnk_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="skck_img" class="text-left">SKCK</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['skck_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('skck_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['skck_img'].length > 0"
                                        [nzShowButton]="!(fileList['skck_img'].length > 0)"
                                        (nzChange)="handleChange($event,'skck_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="sks_img" class="text-left">SKS</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['sks_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('sks_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['sks_img'].length > 0"
                                        [nzShowButton]="!(fileList['sks_img'].length > 0)"
                                        (nzChange)="handleChange($event,'sks_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>

                            <div class="col-6">
                                <nz-form-label nzFor="npwp_img" class="text-left">NPWP</nz-form-label>

                                <div class="col-lg-12 col-md-12">
                                    <nz-upload nzListType="picture-card" [(nzFileList)]="fileList['npwp_img']"
                                        [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                                        [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq('npwp_img')"
                                        [nzAccept]="'image/jpeg, image/jpg'" [nzFileType]="'image/jpeg, image/jpg'"
                                        [nzDisabled]="fileList['npwp_img'].length > 0"
                                        [nzShowButton]="!(fileList['npwp_img'].length > 0)"
                                        (nzChange)="handleChange($event,'npwp_img')">
                                        <div>
                                            <i nz-icon nzType="plus"></i>
                                            <div style="margin-top: 8px">Upload</div>
                                        </div>
                                    </nz-upload>
                                </div>
                            </div>
                        </div>
                    </nz-collapse-panel>
                </nz-collapse>
            </div>
        </div>
    </form>
</nz-spin>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()" [nzLoading]="isLoading">Cancel</button>
    <button nz-button nzType="primary" (click)="submitForm()" [nzLoading]="isLoading">Update</button>
</div>