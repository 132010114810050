<div class="footer">
  <div class="footerInner">
    <a
      href="https://sunshine.onindonesia.id"
      target="_blank"
      rel="noopener noreferrer"
      class="logo"
    >
      <img
        src="assets/images/logo.svg"
        alt="logo"
        style="height: 24px;width: 24px;margin-right: 10px;"
      />
      <strong class="mr-2">{{ settings.logo }}</strong>
    </a>
    <br />
    <p class="mb-0">
      Copyright © {{ date }} PT Onindonesia Sistem Teknologi
      <!-- <a href="https://sellpixels.com" target="_blank" rel="noopener noreferrer">
        Sellpixels.com
      </a>
      |
      <a href="https://sellpixels.com/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a> -->
    </p>
  </div>
</div>
