import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TicketingService } from 'src/app/services/ticketing.service'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as UserActions from 'src/app/store/user/actions'
import * as Reducers from 'src/app/store/reducers'
// import { TicketForm, uploadList } from 'src/app/services/interface/ticketing.model'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { BehaviorSubject, Observable, Observer, of } from 'rxjs'
import { FleetService } from 'src/app/services/fleet.service'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { DriverForm } from 'src/app/services/interface/driver.model'

interface options {
  codeName: string
  code: string
}

interface optionArgs {
  type: string
  value: string
}

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
})
export class AddDriverComponent implements OnInit {
  date = null
  isLoading: boolean = false
  isLoadingOptions: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private fleetService: FleetService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{3,16}$/)]),
      ],
      driverId: [null, Validators.compose([Validators.required])],
      driverName: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])],
      phoneNumber: [null, Validators.compose([Validators.required])],
      employmentStatus: [null, Validators.compose([Validators.required])],
      dateOfBirth: [null],
      placeOfBirth: [null],
      registeredAddress: [null],
      currentAddress: [null],
      citizenNumber: [null],
      driverLicenseType: [null],
      maritalStatus: [null],
      contractNumber: [null],
      phoneNumberPrefix: ['+62'],
      bankName: [null],
      accountNumber: [null],
      accountName: [null],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  @Input() ticketId: string
  form: FormGroup
  searchChange$ = new BehaviorSubject({ type: '', value: '' })

  ngOnInit(): void {
    // setInterval(() => console.log(this.fileList), 3000)
    this.form.statusChanges.subscribe(state => {
      // console.log(state)
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    // const getRandomNameList = (args: optionArgs) =>
    //   this.hrService.shiftOptionLoad(args.value, args.type).pipe(
    //     catchError(() => of({ results: [] })),
    //     map((res: any) => {
    //       var r = {}
    //       r[args.type] = res
    //       return r
    //     }),
    //   )
    // const optionList$: Observable<options[]> = this.searchChange$
    //   .asObservable()
    //   .pipe(debounceTime(500))
    //   .pipe(switchMap<optionArgs, Observable<any>>(getRandomNameList))

    // optionList$.subscribe(r => {
    //   var keys = Object.keys(r)[0]
    //   switch (keys) {
    //     case 'role':
    //       this.roleOptions = r[keys]
    //       break
    //     case 'bankInfo':
    //       this.bankInfoOptions = r[keys]
    //       break
    //     case 'employementStatus':
    //       this.employementStatusOptions = r[keys]
    //       break
    //     case 'placement':
    //       this.placementOptions = r[keys]
    //       break
    //   }
    //   this.isLoadingOptions = false
    // })
    // for (const i in this.form.controls) {
    //   if (this.form.controls.hasOwnProperty(i)) {
    //     this.form.controls[i].markAsDirty()
    //     this.form.controls[i].updateValueAndValidity()
    //   }
    // }
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    // var isUploading = false
    // for (let i = 0; i < this.fileList.length; i++) {
    //   if (this.fileList[i].status == 'uploading') {
    //     isUploading = true
    //     break
    //   }
    // }

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: DriverForm = this.form.value
      formSubs.phoneNumber = formSubs.phoneNumberPrefix + formSubs.phoneNumber
      // console.log(formSubs)
      this.fleetService.createDriver(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  handleBeforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    // console.log(item)
    var ext = item.file.name.split('.').pop()
    formData.append('file', item.file as any, this.ticketId + '.' + ext)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/ticketing/tickets/upload',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          // console.log(event)
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      // console.log(file)
      if (file.hasOwnProperty('originFileObj')) {
        if (file.response == undefined) {
          observer.next(true)
          observer.complete()
        } else {
          this.apiService.deleteFileTemp(file.response?.name).subscribe(
            r => {
              observer.next(true)
              observer.complete()
            },
            e => {
              observer.next(false)
              file.status = 'done'
              this.msg.error('Failed to Delete')
              observer.complete()
            },
          )
        }
      } else {
        observer.next(true)
        observer.complete()
      }
    })

  handleDownload = (file: NzUploadFile): void => {
    // console.log(file)
    window.open((file.response?.url || file.url) + '?statement=download', '_blank')
  }

  handlePreview = async (file: NzUploadFile) => {
    window.open((file.response?.url || file.url) + '?statement=preview', '_blank')
  }

  onChange(result: Date[]): void {
    this.form.patchValue({
      startContract: result[0],
      endContract: result[1],
    })
  }
}
