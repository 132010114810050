import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { NzModalService } from 'ng-zorro-antd/modal'
import { QueryTableLeave } from 'src/app/services/interface/general.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { AddSpecialLeaveComponent } from './add-special-leave/add-special-leave.component'
import { LeaveManagementService } from 'src/app/services/leave-management/leave-management.service'
import { AnnualLeaveType } from 'src/app/services/interface/leave-management/leave.model'

@Component({
  selector: 'app-special-leave',
  templateUrl: './special-leave.component.html',
  styleUrls: ['./special-leave.component.scss'],
})
export class SpecialLeaveComponent implements OnInit {
  listOfData: AnnualLeaveType[] = []
  listLeaveType: string[] = []
  annualQuotaLeave = null
  collectiveQuotaLeave = null
  isAnnualLeave = false

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  selectedFilter = 'WAITING'
  selectedType = null

  form: FormGroup
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private leaveManagementService: LeaveManagementService,
  ) {
    this.form = this.fb.group({
      keyword: [null, Validators.compose([Validators.required])],
      prefix: ['vendor', Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTableLeave = {
    sort: null,
    annual_leave: false,
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  ngOnInit(): void {
    this.checkQuotaLeave()
    this.fetchLeaveType()
  }

  fetchAnnualLeaveList() {
    this.isLoading = true
    this.leaveManagementService
      .getAnnualLeaveList(
        this.selectedFilter,
        this.queryTable,
        this.isAnnualLeave,
        this.selectedType,
      )
      .subscribe((r: any) => {
        this.isLoading = false

        this.listOfData = r.rows
        this.totalData = r.count
      }),
      err => {
        this.fetchAnnualLeaveList()
      }
  }

  onQueryParamsChange(params): void {
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchAnnualLeaveList()
  }

  reset() {
    this.form.reset()
    this.form.patchValue({ prefix: 'name' })
    this.queryTable.sort = null
    this.selectedType = null
    this.fetchAnnualLeaveList()
  }

  addLeave() {
    const modal = this.modal.create({
      nzTitle: 'Form Special Leave',
      nzContent: AddSpecialLeaveComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Submit',
      nzOkDisabled: true,

      nzOnOk: () => {
        const instance = modal.getContentComponent()
        instance.submitForm()
      },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()

    modal.afterClose.subscribe(() => {
      this.fetchAnnualLeaveList()
    })
  }

  checkQuotaLeave() {
    this.isLoading = true
    this.leaveManagementService.checkQuotaLeave().subscribe((r: any) => {
      this.isLoading = false
      this.annualQuotaLeave = r.annual_leave_quota
      this.collectiveQuotaLeave = r.collective_leave_quota
    })
  }

  fetchLeaveType() {
    this.leaveManagementService.listLeaveType().subscribe((r: any) => {
      const leaveTypes = r.types.map((leaveType: string, index: number) => ({
        label: leaveType,
        value: index.toString(),
      }))

      this.listLeaveType = leaveTypes
    })
  }
}
