<form nz-form [formGroup]="form">
    <div class="row">
        <div class="marginBot col-lg-6 col-md-12">
            <h3 class="title-font">Title</h3>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input title correctly!">
                    <input class="content" nz-input id="'title'" formControlName="title"
                        placeholder="Input blog title.." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="marginBot col-lg-6 col-md-12">
            <h3 class="title-font">PPT URL</h3>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input URL!">
                    <input class="content" nz-input id="'ppt_url'" formControlName="ppt_url"
                        placeholder="Insert PPT URL ..." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="marginBot col-lg-6 col-md-12">
            <h3 class="title-font">Youtube URL</h3>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please input URL!">
                    <input class="content" nz-input id="'youtube_url'" formControlName="youtube_url"
                        placeholder="Insert Youtube URL ..." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="marginBot col-lg-6 col-md-12">
            <h3 class="title-font">Training Target</h3>
            <nz-form-item>
                <nz-form-control nzErrorTip="Select available target!">
                    <nz-select nzMode="multiple" formControlName="target" nzPlaceHolder="Choose Target">
                        <nz-option *ngFor="let item of targetArr" [nzLabel]="item.title" [nzValue]="item.id">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="marginBot col-lg-6 col-md-12">
            <h3 class="title-font">Upload a Video</h3>
            <div class="col-lg-12 col-md-12">
                <nz-upload nzListType="picture-card" [(nzFileList)]="fileList" [nzPreview]="handlePreview"
                    [nzDownload]="handleDownload" [nzRemove]="handleRemove" [nzCustomRequest]="customUploadReq"
                    [nzDisabled]="fileList.length > 0" [nzShowButton]="!(fileList.length > 0)"
                    (nzChange)="handleChange($event)">
                    <div>
                        <i nz-icon nzType="plus"></i>
                        <div style="margin-top: 8px">Upload</div>
                    </div>
                </nz-upload>
                <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                    (nzOnCancel)="previewVisible = false">
                    <ng-template #modalContent>
                        <video [src]="previewImage" [ngStyle]="{ width: '100%' }" controls></video>
                    </ng-template>
                </nz-modal>
            </div>
        </div>
    </div>

    <div style="margin-bottom: 15px; margin-left: 15px;">
        <h3 class="title-font">Description</h3>
        <div class="editor">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
            </ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="description" style="height: 500px;">
            </ngx-editor>
        </div>
    </div>

    <div class="widthContainer" style="margin-left: 15px;">
        <h3 class="title-font">Publishing Status</h3>
        <nz-form-item>

            <nz-form-control nzErrorTip="Select available mode!">
                <nz-select formControlName="status" nzPlaceHolder="Choose Publishing Status">
                    <nz-option nzLabel="Publish" [nzValue]="true"></nz-option>
                    <nz-option nzLabel="Draft" [nzValue]="false"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>
</form>