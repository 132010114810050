import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import store from 'store'
import { ApiService } from './api.service'
import {
  NewCompany,
  NewConfig,
  UpdateCompany,
  UpdateConfig,
  newVendor,
  updateConfigValue,
  updateVendor,
} from 'src/app/models/asset-config.model'

@Injectable()
export class AssetManagementService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  private getCustomHeaders(): HttpHeaders {
    const accessToken = store.get('accessToken')
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    })
  }

  getCompany(): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/company',
      { headers: this.getCustomHeaders() },
    )
  }

  addCompany(payload: NewCompany): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/company',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  deleteCompany(company: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/company?company=' + company,
      { headers: this.getCustomHeaders() },
    )
  }

  updateCompany(payload: UpdateCompany): Observable<any> {
    return this.http.put<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/company',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  getConfig(company: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config?company=' + company,
      { headers: this.getCustomHeaders() },
    )
  }

  addConfig(payload: NewConfig): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  deleteConfig(company: string, config: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/config?company=' +
        company +
        '&config=' +
        config,
      { headers: this.getCustomHeaders() },
    )
  }

  updateConfig(payload: UpdateConfig): Observable<any> {
    return this.http.put<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  getConfigValue(company: string, config: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/config-value?company=' +
        company +
        '&config=' +
        config,
      { headers: this.getCustomHeaders() },
    )
  }

  addConfigValue(payload: NewConfig): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config-value',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  deleteConfigValue(company: string, config: string, value: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/config-value?company=' +
        company +
        '&config=' +
        config +
        '&value=' +
        value,
      { headers: this.getCustomHeaders() },
    )
  }

  updateConfigValue(payload: updateConfigValue): Observable<any> {
    return this.http.put<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config-value',
      payload,
      { headers: this.getCustomHeaders() },
    )
  }

  getVendorDetail(company: string, vendorName: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/vendor?company=' +
        company +
        '&vendorName=' +
        vendorName,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  addVendorDetail(payload: newVendor): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/vendor/',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  updateVendorDetail(payload: updateVendor): Observable<any> {
    return this.http.put<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/vendor/',
      payload,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  deleteVendor(company: string, vendorName: string): Observable<any> {
    return this.http.delete<any>(
      this.apiService.API_SUNSHINE_SERVER +
        '/api/assetManagement/vendor?company=' +
        company +
        '&vendorName=' +
        vendorName,
      {
        headers: this.getCustomHeaders(),
      },
    )
  }

  exportConfig(company: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/config/export?company=' + company,
      { headers: this.getCustomHeaders() },
    )
  }

  importVendor(payload: FormData): Observable<any> {
    return this.http.post<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/vendor/import',
      payload,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + store.get('accessToken'),
        }),
      },
    )
  }

  exportVendor(company: string): Observable<any> {
    return this.http.get<any>(
      this.apiService.API_SUNSHINE_SERVER + '/api/assetManagement/vendor/export?company=' + company,
      { headers: this.getCustomHeaders() },
    )
  }
}
