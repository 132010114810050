import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { ShiftService } from 'src/app/services/shift.service'
import { ShiftForm } from 'src/app/services/interface/shift.model'

@Component({
  selector: 'app-edit-shift',
  templateUrl: './edit-shift.component.html',
})
export class EditShiftComponent implements OnInit {
  @Input() id: string
  form: FormGroup
  val = false
  days = 'Monday'
  idx = 0
  tabs = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  output: any

  constructor(
    private fb: FormBuilder,
    private shiftService: ShiftService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      output: this.fb.group({
        // monday: this.fb.group({
        //   timeIn: [null],
        //   timeOut: [null],
        //   status: [null],
        //   holiday: [null],
        // }),
      }),
    })
    for (let i = 0; i < this.tabs.length; i++) {
      this.outputForm.addControl(
        this.tabs[i],
        this.fb.group({
          timeIn: [null],
          timeOut: [null],
          status: [null],
          holiday: [null],
        }),
      )
    }
  }

  get outputForm() {
    return this.form.get('output') as FormGroup
  }

  isLoading: boolean = true
  ngOnInit(): void {
    this.fetchShiftDetails()
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  newTab(event) {
    if (this.idx != event.index) {
      this.val = false
    }
    this.idx = event.index
    this.days = event.tab.nzTitle
    this.tabs.map(r => {
      if (r === this.days && this.output[r].holiday === true) {
        this.val = true
      }
    })
    // if ((this.output.Monday.holiday === true)) {
    //   this.val = true
    // }
    // this.form.controls['timeIn'].reset();
    // this.form.controls['timeOut'].reset();
    // this.form.controls['status'].reset();
    // this.form.controls['holiday'].reset();
  }

  fetchShiftDetails() {
    this.isLoading = true
    this.shiftService.detailShift(this.id).subscribe(
      res => {
        this.output = {
          Monday: res.monday,
          Tuesday: res.tuesday,
          Wednesday: res.wednesday,
          Thursday: res.thursday,
          Friday: res.friday,
          Saturday: res.saturday,
          Sunday: res.sunday,
        }
        var keyOfDays = Object.keys(this.output)
        for (let i = 0; i < keyOfDays.length; i++) {
          this.outputForm.get(keyOfDays[i])!.patchValue(this.output[keyOfDays[i]])
        }
        if (res.monday.holiday === true) {
          this.val = true
          this.output.Monday.holiday = true
        }
        this.form.patchValue(res)
        this.isLoading = false
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  cancel(): void {
    this.modal.destroy()
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (this.form.valid) {
      var formSubs: ShiftForm = this.output
      formSubs.name = this.form.value.name
      formSubs.id = this.id
      this.shiftService.updateShift(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({
            nzOkLoading: this.isLoading,
            nzCancelDisabled: this.isLoading,
          })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill in the Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return
    }
  }

  checked(value: any) {
    if (value != 'true') {
      this.val = false
    } else {
      this.val = true
    }

    this.tabs.map(r => {
      if ((r = this.days)) {
        // this.form.controls[this.output[r].timeIn].reset();
        // this.form.controls[this.output[r].timeIntimeOut].reset();
        // this.form.controls[this.output[r].timeInstatus].reset();
        this.output[r] = {
          timeIn: null,
          timeOut: null,
          status: null,
          holiday: this.val,
        }
      }
    })
  }

  time_in(value: any) {
    // var tabz = this.tabs.map(r => r.toLowerCase())
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
      }
    })
  }

  time_out(value: any) {
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
      }
    })
  }

  clicked(value: any) {
    this.tabs.map(r => {
      if ((r = this.days)) {
        this.output[r] = {
          timeIn: this.outputForm.value[r].timeIn,
          timeOut: this.outputForm.value[r].timeOut,
          status: this.outputForm.value[r].status,
          holiday: this.val,
        }
      }
    })
  }
}
