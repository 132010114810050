import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import storeeeee from 'store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiService } from 'src/app/services/api.service'
import { BehaviorSubject, Observable, Observer, of } from 'rxjs'
import { HrService } from 'src/app/services/hr.service'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { EmployeeForm } from 'src/app/services/interface/employee.model'

interface options {
  codeName: string
  code: string
}

interface optionArgs {
  type: string
  value: string
}

interface roleOptions {
  codeName: string
  code: string
}

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.scss'],
})
export class EditEmployeeComponent implements OnInit {
  date = null
  isLoading: boolean = true
  isLoadingOptions: boolean = false
  signer: string
  fileList: NzUploadFile[] = []
  constructor(
    private fb: FormBuilder,
    private hrService: HrService,
    private apiService: ApiService,
    private store: Store<any>,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]{3,16}$/)]),
      ],
      email: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])],
      shift: [null, Validators.compose([Validators.required])],
      company: [null, Validators.compose([Validators.required])],
      oldCode: [null, Validators.compose([Validators.required])],
      code: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      imei: [null],
      name: [null, Validators.compose([Validators.required])],
      gender: [null, Validators.compose([Validators.required])],
      description: [null],
      dateOfBirth: [null],
      placeOfBirth: [null],
      registeredAddress: [null],
      currentAddress: [null],
      citizenNumber: [null],
      typeOfSIM: [null],
      maritalStatus: [null],
      numberOfChild: [null],
      position: [null, Validators.compose([Validators.required])],
      placement: [null, Validators.compose([Validators.required])],
      contractNumber: [null],
      rangeContract: [null],
      startContract: [null],
      endContract: [null],
      // rangeWork: [null],
      joinDate: [null],
      resignDate: [null],
      reason: [null],
      employementStatus: [null],
      phoneNumberPrefix: ['+62'],
      phoneNumber: [null],
      waNumberPrefix: ['+62'],
      waNumber: [null],
      bankCode: [null],
      bankAccountNumber: [null],
      bankAccountName: [null],
      profile_img: [null],
      disabled: [null],
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.signer = state.username
    })
  }

  @Input() code: string
  form: FormGroup
  switchValue = false
  loading = false
  searchChange$ = new BehaviorSubject({ type: '', value: '' })
  roleOptions: options[] = []
  bankInfoOptions: options[] = []
  employementStatusOptions: options[] = []
  shiftOptions: options[] = []
  companyOptions: options[] = []
  positionOptions: options[] = []
  placementOptions: options[] = []

  loopArgs = [
    'role',
    'placement',
    'bankInfo',
    'employementStatus',
    'shifts',
    'positions',
    'company',
  ]
  ngOnInit(): void {
    this.fetchEmployeeDetails()
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
    const getRandomNameList = (args: optionArgs) =>
      this.hrService.shiftOptionLoad(args.value, args.type).pipe(
        catchError(() => of({ results: [] })),
        map((res: any) => {
          var r = {}
          r[args.type] = res
          return r
        }),
      )
    const optionList$: Observable<options[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap<optionArgs, Observable<any>>(getRandomNameList))

    optionList$.subscribe(r => {
      var keys = Object.keys(r)[0]
      // console.log('options=', r)
      switch (keys) {
        case 'role':
          this.roleOptions = r[keys]
          break
        case 'bankInfo':
          this.bankInfoOptions = r[keys]
          break
        case 'employementStatus':
          this.employementStatusOptions = r[keys]
          break
        case 'shifts':
          this.shiftOptions = r[keys]
          break
        case 'positions':
          this.positionOptions = r[keys]
          break
        case 'company':
          this.companyOptions = r[keys]
          break
        case 'placement':
          this.placementOptions = r[keys]
          break
      }
      this.isLoadingOptions = false
    })
    this.loopArgs.forEach(r => {
      this.hrService
        .shiftOptionLoad('', r)
        .toPromise()
        .then(rr => {
          switch (r) {
            case 'role':
              this.roleOptions = rr
              break
            case 'bankInfo':
              this.bankInfoOptions = rr
              break
            case 'employementStatus':
              this.employementStatusOptions = rr
              break
            case 'shifts':
              this.shiftOptions = rr
              break
            case 'positions':
              this.positionOptions = rr
              break
            case 'company':
              this.companyOptions = rr
              break
            case 'placement':
              this.placementOptions = rr
              break
          }
        })
    })
  }
  search(value: string, type: string) {
    this.isLoadingOptions = true
    this.searchChange$.next({ type, value })
  }

  fetchEmployeeDetails() {
    this.isLoading = true
    // console.log('Array Company List = ', this.companyOptions)
    // console.log('Array Shift List = ', this.shiftOptions)
    this.hrService.detailEmployee(this.code).subscribe(
      r => {
        if (r.profile_img !== null) {
          this.fileList = [r.profile_img]
          r.profile_img = true
        }
        if (r.disabled !== null) {
          this.switchValue = r.disabled
        }
        this.form.patchValue(r)
        for (const i in this.form.controls) {
          if (this.form.controls.hasOwnProperty(i)) {
            this.form.controls[i].markAsDirty()
            this.form.controls[i].updateValueAndValidity()
          }
        }
        this.isLoading = !this.isLoading
      },
      err => {
        this.notification.error('Error', err.error.message)
      },
    )
  }

  clickSwitch(): void {
    if (!this.loading) {
      this.loading = true
      setTimeout(() => {
        this.switchValue = !this.switchValue
        this.loading = false
        this.form.value.disabled = this.switchValue
      }, 10)
    }
  }

  submitForm(): boolean {
    this.isLoading = true
    this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
    var isUploading = false
    for (let i = 0; i < this.fileList.length; i++) {
      if (['uploading', 'error'].includes(this.fileList[i].status)) {
        isUploading = true
        break
      }
      if (isUploading) break
    }

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }

    if (!isUploading && this.form.valid) {
      var formSubs: EmployeeForm = this.form.value
      // console.log(formSubs)
      formSubs.phoneNumber = formSubs.phoneNumberPrefix + formSubs.phoneNumber
      formSubs.waNumber = formSubs.waNumberPrefix + formSubs.waNumber
      formSubs.signer = this.signer
      if (this.fileList[0] != null) {
        if (this.fileList[0].status === 'done') {
          formSubs.profile_img = {
            name: this.fileList[0].response?.name || this.fileList[0].name,
            status: this.fileList[0].status,
            url: this.fileList[0].response?.url || this.fileList[0].url,
            uid: this.fileList[0].uid,
          }
        }
      }
      this.hrService.updateEmployee(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.modal.destroy()
          this.isLoading = false
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error(
        isUploading ? 'Please wait until all files uploaded' : 'Please Fill Blank Form',
      )
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }

  handleBeforeUpload = (file: NzUploadFile): boolean => {
    if (file.size <= 11534336) {
      return true
    } else {
      this.msg.error('File too Large')
      return false
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)

    const req = new HttpRequest(
      'POST',
      this.apiService.API_SUNSHINE_SERVER + '/api/upload/temp',
      formData,
      {
        headers: new HttpHeaders({
          authorization: 'Bearer ' + storeeeee.get('accessToken'),
        }),
        reportProgress: true,
        withCredentials: false,
      },
    )

    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            ;(event as any).percent = (event.loaded / event.total) * 100 // tslint:disable-next-line:no-any
          }
          item.onProgress!(event, item.file)
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file, event)
        }
      },
      err => {
        // console.log(err)
        /* error */
        item.onError!(err, item.file)
      },
    )
  }

  handleRemove = (file: NzUploadFile) =>
    new Observable((observer: Observer<boolean>) => {
      this.form.patchValue({ image: null })
      observer.next(true)
      observer.complete()
    })

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  previewImage: string | undefined = ''
  previewVisible = false

  handlePreview = async (file: NzUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!)
    }
    this.previewImage = file.response?.url || file.url || file.preview
    this.previewVisible = true
  }

  handleChange(info: NzUploadChangeParam): void {
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`)
      this.form.patchValue({ image: true })
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`)
    }
  }

  onChange(result: Date[]): void {
    if (result != null) {
      this.form.patchValue({
        startContract: result[0],
        endContract: result[1],
      })
    }
  }

  // whenChange(result: Date[]): void {
  //   this.form.patchValue({
  //     startWork: result[0],
  //     endWork: result[1],
  //   })
  // }

  resetPassword() {
    this.modalService.confirm({
      nzTitle: 'Are you sure reset password this employee?',
      nzContent: '<b style="color: red;">This Action cannot be undone !</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.apiService.resetPassword(this.form.value.username).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchEmployeeDetails()
          },
          err => {
            this.notification.error('Error', err.error.message)
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }

  resetUniqueKey() {
    this.modalService.confirm({
      nzTitle: 'Are you sure reset unique key this employee?',
      nzContent: '<b style="color: red;">This Action cannot be undone !</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.apiService.resetUniqueKey(this.form.value.username).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchEmployeeDetails()
          },
          err => {
            this.notification.error('Error', err.error.message)
          },
        )
      },
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel'),
    })
  }
}
