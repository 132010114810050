<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <nz-table #sortTable [nzData]="data" [nzLoading]="isLoading" nzTableLayout="fixed" [nzScroll]="{ y: '40vh'}"
          [nzTitle]="headerTable" [nzTotal]="totalData" [nzPageSize]="pageSize" [nzPageIndex]="pageIndex"
          nzSize="small">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns">
                <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Candidate">
                  <button class="btn btn-sm btn-light mr-2" (click)="handleView(data)">
                    <i class="fe fe-eye"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Accept">
                  <button [disabled]="data.status!=='Process'" class="btn btn-sm btn-light mr-2"
                    (click)="handleAccept(data)">
                    <i class="fe fe-check"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Send PKS">
                  <button class="btn btn-sm btn-light mr-2" (click)="handleSendPKS(data)">
                    <i class="fe fe-file-text"></i>
                  </button>
                </span>
                <span style="margin: 0 2px;"></span>
              </td>
              <td>{{data.agent_id}}</td>
              <td>{{data.status}}</td>
              <td>{{data.name}}</td>
              <td>{{data.phone_number}}</td>
              <td>{{data.email}}</td>
              <td>{{data.reject_reason}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<ng-template #headerTable>
  <div class="row justify-content-between">
    <div class="col-4">
      <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
        <a class="btn btn-lg btn-light mr-2" (click)="refreshData()">
          <i class="fe fe-refresh-cw"></i>
        </a>
      </span>
    </div>

    <div class="col">
      <form nz-form [nzLayout]="'inline'" style="display: flex; flex-direction: row; justify-content: flex-end;">

        <nz-form-item>
          <nz-form-control>
            <!-- <nz-select id="filter" style="width: 130px;" formControlName="filter" (ngModelChange)="clearSearch()">
              <ng-container *ngFor="let item of listOfFilter">
                <nz-option [nzValue]="item" [nzLabel]="item"></nz-option>
              </ng-container>
            </nz-select> -->
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <nz-input-group id="search" style="width: 150px;">
              <input type="text" nz-input placeholder="Search" style=" border-radius: 8px;" />

            </nz-input-group>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-control>
            <button type="submit" class="btn btn-primary">
              Search
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>
</ng-template>

<nz-modal [nzWidth]="'50%'" [nzVisible]="isModalEditVisible" (nzOnCancel)="onCancelModal()" [nzTitle]="modalTitle"
  [nzContent]="modalContent" [nzFooter]="modalFooter">
  <ng-template #modalTitle>Edit Candicate</ng-template>
  <ng-template #modalContent>
    <form [formGroup]="profile">
      <div class="row">
        <div class="col">
          <nz-form-label nzFor="first_name" class="text-left col-4" style="padding-left:0">First Name</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="first_name" type="text" style="border-radius: 8px;" formControlName="firstName"
                [nzDisabled]="true" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="last_name" class="text-left col-4" style="padding-left:0">Last Name</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="last_name" type="text" style="border-radius: 8px;" formControlName="lastName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="gender" class="text-left col-4" style="padding-left:0">Gender</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-select id="gender" style="border-radius: 8px;" formControlName="gender">
                <nz-option nzValue="Male" nzLabel="Male"></nz-option>
                <nz-option nzValue="Female" nzLabel="Female"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-label class="text-left col-4" style="padding-left:0">Date and Place of Birth</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <div class="d-md-flex">
                <nz-date-picker formControlName="dateOfBirth" style="width:100%"></nz-date-picker>
                <input nz-input id="placeofbirth" type="text" class="ml-md-2" style="border-radius: 8px;"
                  formControlName="placeOfBirth" />
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="phone_number" class="text-left col-4" style="padding-left:0">Phone
            number</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-input-group [nzPrefix]="prefix">
                <input nz-input id="phone_number" type="text" style="border-radius: 8px;"
                  formControlName="phoneNumber" />
                <ng-template #prefix><span>+62</span></ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="address" class="text-left col-4" style="padding-left:0">Address</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="address" type="text" style="border-radius: 8px;" formControlName="address" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="citizen_number" class="text-left col-4" style="padding-left:0">Citizen
            Number</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="citizen_number" type="text" style="border-radius: 8px;"
                formControlName="citizenNumber" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="interviewState" class="text-left col-4" style="padding-left:0">Interview
            State</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <nz-switch [nzDisabled]="this.profile.value.status" formControlName="status"
                (ngModelChange)="interviewStateChange($event)" [nzLoading]="isSwitchLoading"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col">
          <nz-form-label nzFor="email" class="text-left col-4" style="padding-left:0">Email</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="email" type="text" style="border-radius: 8px;" formControlName="email" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="company" class="text-left col-4" style="padding-left:0">Company</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="company" type="text" style="border-radius: 8px;" formControlName="company" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="bank_name" class="text-left col-4" style="padding-left:0">Bank Name</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="bank_name" type="text" style="border-radius: 8px;" formControlName="bankName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="account_name" class="text-left col-4" style="padding-left:0">Bank Account
            Name</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="account_name" type="text" style="border-radius: 8px;"
                formControlName="accountBankName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="account_number" class="text-left col-4" style="padding-left:0">Bank Account
            Number</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <input nz-input id="account_name" type="text" style="border-radius: 8px;"
                formControlName="accountNumber" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-label nzFor="account_number" class="text-left col-4" style="padding-left:0">Candidate
            Picture</nz-form-label>
          <nz-form-item>
            <nz-form-control>
              <!-- <img nz-image width="100px" height="100px" [nzSrc]="profile.value.avatar" alt="" /> -->
              <nz-upload nzName="picture" nzListType="picture-card" [nzLimit]="1" [(nzFileList)]="pictureList"
                [nzRemove]="false" [nzAccept]="'image/jpeg, image/jpg, image/png'" [nzDownload]="handleDownload"
                [nzShowButton]="false">
                <div>
                  <span nz-icon nzType="plus"></span>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </nz-upload>
            </nz-form-control>
          </nz-form-item>


        </div>
      </div>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button nz-button nzType="default" (click)="onCancelModal()">Cancel</button>
    <button nz-button nzType="primary" [nzLoading]="isModalEditConfirm" (click)="handleEdit()">Update</button>
  </ng-template>
</nz-modal>

<ng-template #acceptContent>
  <div>
    <p style="font-size:16px; text-align:center">Accept Reason<span style="color:red">*</span></p>
    <textarea rows="4" nz-input [(ngModel)]="reason"></textarea>
  </div>
</ng-template>