<nz-spin [nzSpinning]="isLoading">
  <div class="row">
    <div class="col-lg-7 col-md-12">
      <form nz-form [formGroup]="form">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <nz-form-item>
              <nz-form-label nzFor="vendor" class="text-left">Vendor</nz-form-label>
              <nz-form-control nzErrorTip="Please select vendor!">
                <input nz-input id="vendor" formControlName="vendor" placeholder="Input Vendor ..." readonly />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-6 col-md-12">
            <nz-form-item>
              <nz-form-label nzFor="waybill" class="text-left">Waybill</nz-form-label>
              <nz-form-control nzErrorTip="Please input waybill correctly!">
                <input nz-input id="waybill" formControlName="waybill" placeholder="Input Waybill ..." readonly />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-6 col-md-12">
            <nz-form-item>
              <nz-form-label nzFor="agent" class="text-left">Agent</nz-form-label>
              <nz-form-control nzErrorTip="Please select agent!">
                <input nz-input id="agent" formControlName="agent" placeholder="Input Agent ..." readonly />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-6 col-md-12">
            <nz-form-item>
              <nz-form-label nzFor="person" class="text-left">Person</nz-form-label>
              <nz-form-control nzErrorTip="Please input person correctly!">
                <input nz-input id="person" formControlName="person" placeholder="Input Person ..." />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-form-item>
              <nz-form-label nzFor="problemType" class="text-left">Problem Type</nz-form-label>
              <nz-form-control nzErrorTip="Please select problem type">
                <input nz-input id="problemType" formControlName="problemType" placeholder="Input Problem Type ..."
                  readonly />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-form-item>
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="description" class="text-left">Description</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please input description!">
                <textarea rows="4" id="description" nz-input formControlName="description"
                  placeholder="Input Description ..." readonly></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="col-lg-12 col-md-12">
            <nz-upload nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76" nzListType="picture-card"
              [(nzFileList)]="fileList" [nzMultiple]="true" [nzPreview]="handlePreview" [nzDownload]="handleDownload"
              [nzShowButton]="false" [nzShowUploadList]="{
                showRemoveIcon: false,
                showPreviewIcon: true,
                showDownloadIcon: true
              }">
              <div>
                <i nz-icon nzType="plus"></i>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </nz-upload>
          </div>
        </div>
      </form>
    </div>
    <ng-container *ngIf="profile_img; else elseBlock">
      <div class="col-lg-5 col-md-12">
        <div class="col-12">
          <h4>Comment</h4>
        </div>
        <div class="col-12" style="overflow-y:scroll;height: 300px;">
          <nz-list [nzDataSource]="data" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
            <ng-template #item let-item>
              <nz-comment [nzAuthor]="item.createdBy" [nzDatetime]="item.createdAt">
                <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]=profile_img.url></nz-avatar>
                <nz-comment-content>
                  <p>{{ item.comments }}</p>
                  <ng-container *ngIf="item.attachments?.length > 0">
                    <nz-upload nzListType="picture-card" [(nzFileList)]="item.attachments" [nzPreview]="handlePreview"
                      [nzDownload]="handleDownload" [nzShowButton]="false" [nzShowUploadList]="{
                        showRemoveIcon: false,
                        showPreviewIcon: true,
                        showDownloadIcon: true
                      }" style="margin-top: 10px;">
                      <div>
                        <i nz-icon nzType="plus"></i>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </ng-container>
                </nz-comment-content>
              </nz-comment>
            </ng-template>
          </nz-list>
        </div>
        <div class="col-12">
          <nz-comment>
            <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]=profile_img.url></nz-avatar>
            <nz-comment-content>
              <nz-form-item>
                <textarea [formControl]="formComment.controls['comments']" nz-input rows="4"></textarea>
              </nz-form-item>
              <div class="row" style="justify-content: space-between;">
                <nz-form-item>
                  <nz-form-label nzFor="statusTicket" class="text-left">Status</nz-form-label>
                  <nz-form-control nzErrorTip="Please select status ticket">
                    <nz-select nzAllowClear id="statusTicket" [formControl]="formComment.controls['status']"
                      nzPlaceHolder="Select Status ..." style="width: 220px;">
                      <nz-option nzValue="In Progress" nzLabel="In Progress"></nz-option>
                      <nz-option nzValue="Solved" nzLabel="Solved"></nz-option>
                      <nz-option nzValue="Request Delete" nzLabel="Request Delete"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <button nz-button nzType="primary" [nzLoading]="isLoadingComment" [disabled]="!formComment.valid"
                    (click)="submitComment()">
                    Add Comment
                  </button>
                </nz-form-item>
              </div>
            </nz-comment-content>
            <nz-upload nzListType="picture-card" [(nzFileList)]="fileListComment" [nzMultiple]="true"
              [nzPreview]="handlePreview" [nzDownload]="handleDownload" [nzRemove]="handleRemove"
              [nzCustomRequest]="customUploadReq" [nzBeforeUpload]="handleBeforeUpload">
              <div>
                <i nz-icon nzType="plus"></i>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </nz-upload>
            <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
              (nzOnCancel)="previewVisible = false">
              <ng-template #modalContent>
                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
              </ng-template>
            </nz-modal>
          </nz-comment>
        </div>
      </div>
    </ng-container>
    <ng-template #elseBlock>
      <div class="col-lg-5 col-md-12">
        <div class="col-12">
          <h4>Comment</h4>
        </div>
        <div class="col-12" style="overflow-y:scroll;height: 300px;">
          <nz-list [nzDataSource]="data" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
            <ng-template #item let-item>
              <nz-comment [nzAuthor]="item.createdBy" [nzDatetime]="item.createdAt">
                <nz-avatar nz-comment-avatar nzIcon="user"
                nzSrc='https://sunshineapi.onindonesia.id/api/employee/image/default.png'></nz-avatar>
                <nz-comment-content>
                  <p>{{ item.comments }}</p>
                  <ng-container *ngIf="item.attachments?.length > 0">
                    <nz-upload nzListType="picture-card" [(nzFileList)]="item.attachments" [nzPreview]="handlePreview"
                      [nzDownload]="handleDownload" [nzShowButton]="false" [nzShowUploadList]="{
                        showRemoveIcon: false,
                        showPreviewIcon: true,
                        showDownloadIcon: true
                      }" style="margin-top: 10px;">
                      <div>
                        <i nz-icon nzType="plus"></i>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </ng-container>
                </nz-comment-content>
              </nz-comment>
            </ng-template>
          </nz-list>
        </div>
        <div class="col-12">
          <nz-comment>
            <nz-avatar nz-comment-avatar nzIcon="user"
                nzSrc='https://sunshineapi.onindonesia.id/api/employee/image/default.png'></nz-avatar>
            <nz-comment-content>
              <nz-form-item>
                <textarea [formControl]="formComment.controls['comments']" nz-input rows="4"></textarea>
              </nz-form-item>
              <div class="row" style="justify-content: space-between;">
                <nz-form-item>
                  <nz-form-label nzFor="statusTicket" class="text-left">Status</nz-form-label>
                  <nz-form-control nzErrorTip="Please select status ticket">
                    <nz-select nzAllowClear id="statusTicket" [formControl]="formComment.controls['status']"
                      nzPlaceHolder="Select Status ..." style="width: 220px;">
                      <nz-option nzValue="In Progress" nzLabel="In Progress"></nz-option>
                      <nz-option nzValue="Solved" nzLabel="Solved"></nz-option>
                      <nz-option nzValue="Request Delete" nzLabel="Request Delete"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <button nz-button nzType="primary" [nzLoading]="isLoadingComment" [disabled]="!formComment.valid"
                    (click)="submitComment()">
                    Add Comment
                  </button>
                </nz-form-item>
              </div>
            </nz-comment-content>
            <nz-upload nzListType="picture-card" [(nzFileList)]="fileListComment" [nzMultiple]="true"
              [nzPreview]="handlePreview" [nzDownload]="handleDownload" [nzRemove]="handleRemove"
              [nzCustomRequest]="customUploadReq" [nzBeforeUpload]="handleBeforeUpload">
              <div>
                <i nz-icon nzType="plus"></i>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </nz-upload>
            <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
              (nzOnCancel)="previewVisible = false">
              <ng-template #modalContent>
                <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
              </ng-template>
            </nz-modal>
          </nz-comment>
        </div>
      </div>
    </ng-template>
  </div>
</nz-spin>