import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'capitalizeFirstLetter',
})
export class CapitalizeWord implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value
    }

    return value
      .toLocaleLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }
}
