<form nz-form [formGroup]="formAddLeaveConfig">
  <div class="row">
    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="individual" class="text-left">Type</nz-form-label>
        <nz-form-control nzErrorTip="Please input type correctly!">
          <nz-select
            formControlName="individual"
            nzPlaceHolder="Select type"
            (ngModelChange)="onTypeChange($event)"
          >
            <nz-option [nzValue]="true" nzLabel="Individual"></nz-option>
            <nz-option [nzValue]="false" nzLabel="Group"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="quota" class="text-left">Total Quota</nz-form-label>
        <nz-form-control nzErrorTip="Please input quota correctly!">
          <input
            nz-input
            id="quota"
            formControlName="quota"
            placeholder="Input quota"
            type="number"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="employee_code" class="text-left">Employee</nz-form-label>
        <nz-form-control nzErrorTip="Please input employee correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select an employee"
            [(ngModel)]="selectedValue"
            formControlName="employee_code"
            [nzDisabled]="!isIndividual"
            (nzOnSearch)="onSearch($event)"
          >
            <nz-option
              *ngFor="let option of listOfEmployee"
              [nzLabel]="option.employee_name"
              [nzValue]="option.employee_code"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-6">
      <nz-form-item>
        <nz-form-label nzFor="shift_id" class="text-left">Leave Shift</nz-form-label>
        <nz-form-control nzErrorTip="Please input shift correctly!">
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select a shift"
            [(ngModel)]="selectedShift"
            formControlName="shift_id"
            [nzDisabled]="isIndividual"
          >
            <nz-option
              *ngFor="let option of listOfShift"
              [nzLabel]="option.shift_name"
              [nzValue]="option.id"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div
      class="d-flex"
      style="align-items: end; justify-content: end; width: 100%; column-gap: 8px; padding: 16px;"
    >
      <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
      <button
        nz-button
        nzType="default"
        nzDanger
        style="background-color: rgb(231, 64, 64) !important; color: white;"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
