<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" >
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
          <nz-form-control nzErrorTip="Please input name correctly">
            <input nz-input id="name" readonly formControlName="name"/>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="capacity" class="text-left">Total Participants</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid capacity">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else elseBlock">
              <input nz-input id="capacity" type="number" formControlName="capacity"/>
            </div>
            <ng-template #elseBlock>
              <input nz-input id="capacity" type="number" [placeholder]="data?.capacity" [disabled]="true"/>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="meetingRoom" class="text-left">Meeting Room</nz-form-label>
          <nz-form-control nzErrorTip="Please select an available meeting room">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else elseThis">
              <nz-select formControlName="meetingRoom">
                <nz-option *ngFor="let item of roomArr" [nzLabel]="item.name" [nzValue]="item.name"></nz-option>
              </nz-select>
            </div>
            <ng-template #elseThis>
              <nz-select [nzPlaceHolder]="data?.meetingRoom" nzDisabled></nz-select>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="division" class="text-left">Division</nz-form-label>
          <nz-form-control nzErrorTip="Please select available division">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else elseHere">
              <nz-select formControlName="division">
                <nz-option *ngFor="let item of divArr" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
              </nz-select>
            </div>
            <ng-template #elseHere>
              <nz-select [nzPlaceHolder]="data?.division" nzDisabled></nz-select>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="agenda" class="text-left">Function</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid function">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else elseTry">
              <nz-textarea-count>
                <textarea rows="4" formControlName="agenda" nz-input></textarea>
              </nz-textarea-count>
            </div>
            <ng-template #elseTry>
              <nz-textarea-count>
                <textarea rows="4" [placeholder]="data?.agenda" nz-input [disabled]="true"></textarea>
              </nz-textarea-count>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="timeStart" class="text-left">Start Time</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid time">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else here">
              <nz-time-picker formControlName="timeStart" nzFormat="HH:mm"></nz-time-picker>
            </div>
            <ng-template #here>
              <nz-date-picker formControlName="timeStart" [nzDisabled]="true" nzFormat="HH:mm"></nz-date-picker>
            </ng-template>
          </nz-form-control>
          <nz-form-label nzFor="timeEnd" class="text-left">End Time</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid time">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else heree">
              <nz-time-picker formControlName="timeEnd" nzFormat="HH:mm"></nz-time-picker>
            </div>
            <ng-template #heree>
              <nz-date-picker formControlName="timeEnd" [nzDisabled]="true" nzFormat="HH:mm"></nz-date-picker>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="date_start" class="text-left">Start Date</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid date">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else disini">
              <nz-date-picker formControlName="dateStart" [nzDisabledDate]="disabledDateStart" nzFormat="dd-MM-yyyy"
            ></nz-date-picker>
            </div>
            <ng-template #disini>
              <nz-date-picker formControlName="dateStart" [nzDisabled]="true" nzFormat="dd-MM-yyyy"></nz-date-picker>
            </ng-template>
          </nz-form-control>
          <nz-form-label nzFor="date_end" class="text-left">End Date</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid date">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else sini">
              <nz-date-picker formControlName="dateEnd" [nzDisabledDate]="disabledDateEnd" nzFormat="dd-MM-yyyy"
          ></nz-date-picker>
            </div>
            <ng-template #sini>
              <nz-date-picker formControlName="dateEnd" [nzDisabled]="true" nzFormat="dd-MM-yyyy"></nz-date-picker>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="colour" class="text-left">Colour</nz-form-label>
          <nz-form-control nzErrorTip="Please select available colour">
            <div *ngIf="((user == 'administrator') || (user == 'head_ga') || (user == 'staff_ga')); else terakhirYuhuu">
              <nz-select formControlName="colour" [nzPlaceHolder]="colours">
                <nz-option *ngFor="let item of colourArr" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
              </nz-select>
            </div>
            <ng-template #terakhirYuhuu>
              <nz-select formControlName="colour" [nzPlaceHolder]="colours" nzDisabled></nz-select>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</nz-spin>