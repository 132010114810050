import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'
import { CountTicket, Ticket } from 'src/app/services/interface/ticketing.model'
import { FilterObj, QueryTable } from 'src/app/services/interface/general.model'
import { TicketingService } from 'src/app/services/ticketing.service'
import { AddTicketComponent } from './add-ticket/add-ticket.component'
import { customAlphabet } from 'nanoid'
import {
  NzTableQueryParams,
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table'
import { ViewTicketComponent } from './view-ticket/view-ticket.component'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzResizeEvent } from 'ng-zorro-antd/resizable'

interface DataItem {
  dateCreated: string
  vendor: string
  waybill: string
  agent: string
  person: string
  problemType: string
  createdBy: string
  lastUpdate: string
  lastUserComment: string
  status: string
}

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-ticketing',
  templateUrl: './ticketing.component.html',
  styleUrls: ['./ticketing.component.scss'],
})
export class TicketingComponent implements OnInit {
  listOfData: Ticket[] = []
  countTicketStatus: CountTicket[] = []
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1

  form: FormGroup
  // form: FormArray

  statusFiltered: string = 'Total'
  isDownloading: boolean = false
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private ticketService: TicketingService,
    private fb: FormBuilder,
    private store: Store<any>,
    private notification: NzNotificationService,
  ) {
    this.form = this.fb.group({
      filters: this.fb.array([
        this.fb.group({
          prefix: ['vendor', Validators.compose([Validators.required])],
          keyword: [null],
        }),
      ]),
    })
    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.roleCode
      // console.log(this.role)
    })
  }

  role: string

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    {
      name: 'Date Created',
      key: 'createdAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Vendor',
      key: 'vendor',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Waybill',
      key: 'waybill',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Problem Type',
      key: 'problemType',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },

    {
      name: 'Agent',
      key: 'agent',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Person',
      key: 'person',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },

    {
      name: 'Created By',
      key: 'createdBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Last Update',
      key: 'updatedAt',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Last User Comment',
      key: 'lastUserCommented',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Status',
      key: 'status',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: true,
      width: '200px',
    },
  ]

  ngOnInit(): void {
    this.fetchTicket()
    this.fetchFooterTicket()
    // console.log(this.advancedFilters.at(0).get('prefix').value)
  }

  fetchTicket() {
    this.isLoading = true
    this.ticketService.getTicket(this.queryTable).subscribe((r: any) => {
      // console.log(r)
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  fetchFooterTicket() {
    this.ticketService.totalTicket(this.queryTable).subscribe(r => {
      this.countTicketStatus = r
    })
  }

  get statusTotal() {
    var total: CountTicket[] = [
      { status: 'Total', count: '0' },
      { status: 'Open', count: '0' },
      { status: 'Remainders', count: '0' },
      { status: 'In Progress', count: '0' },
      { status: 'Request Delete', count: '0' },
      { status: 'Solved', count: '0' },
    ]
    this.countTicketStatus.forEach(e => {
      switch (e.status) {
        case 'Open':
          total[1].count = e.count
          break
        case 'Remainders':
          total[2].count = e.count
          break
        case 'In Progress':
          total[3].count = e.count
          break
        case 'Request Delete':
          total[4].count = e.count
          break
        case 'Solved':
          total[5].count = e.count
          break
      }
      total[0].count = (Number(total[0].count) + Number(e.count)).toString()
    })
    return total
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  get advancedFilters(): FormArray {
    return this.form.get('filters') as FormArray
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    // console.log(this.pageIndex, this.pageSize)
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchTicket()
  }

  search() {
    if (this.advancedFilters.controls[0].value.keyword?.length > 0) {
      var index = this.queryTable.filters.findIndex(e => e.field !== 'status')
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
      var filter: FilterObj = {
        field: this.advancedFilters.controls[0].value.prefix,
        keyword: this.advancedFilters.controls[0].value.keyword,
      }
      this.queryTable.filters.push(filter)
    } else {
      var index = this.queryTable.filters.findIndex(e => e.field !== 'status')
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
    }
    // console.log(this.queryTable)
    this.queryTable.pageIndex = 0
    this.fetchTicket()
    this.fetchFooterTicket()
  }

  filterStatus() {
    if (this.statusFiltered !== 'Total') {
      var index = this.queryTable.filters.findIndex(e => e.field === 'status')
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
      var filter: FilterObj = {
        field: 'status',
        keyword: this.statusFiltered,
      }
      this.queryTable.filters.push(filter)
    } else {
      var index = this.queryTable.filters.findIndex(e => e.field === 'status')
      if (index !== -1) {
        this.queryTable.filters.splice(index, 1)
      }
    }
    // console.log(this.queryTable)
    this.fetchTicket()
  }

  reset() {
    this.form.reset()
    for (let i = 0; i < this.advancedFilters.length; i++) {
      if (i == 0) this.advancedFilters.at(i).patchValue({ prefix: 'vendor', keyword: null })
      if (i > 0) this.advancedFilters.removeAt(i)
    }
    this.queryTable.sort = null
    this.queryTable.filters = []
    this.listOfColumns.forEach(r => {
      r.sortOrder = null
    })
    this.fetchTicket()
    this.fetchFooterTicket()
  }

  addTicket(tplFooter: TemplateRef<{}>) {
    // console.log(tplFooter)
    var nanoid = customAlphabet(
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      15,
    )()
    const modal = this.modal.create({
      nzTitle: 'Add Ticket',
      nzContent: AddTicketComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        ticketId: nanoid,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: tplFooter,
      nzWidth: '50%',
    })
    modal.afterClose.subscribe(() => {
      this.fetchTicket()
    })
  }

  viewTicket(ticketId: string) {
    const modal = this.modal.create({
      nzTitle: 'View Ticket',
      nzContent: ViewTicketComponent,
      nzStyle: { top: '50px' },
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        ticketTag: ticketId,
      },
      nzMaskClosable: false,
      nzFooter: [
        {
          label: 'Close',
          danger: true,
          onClick() {
            modal.destroy()
          },
        },
      ],
      nzWidth: '100%',
    })
    modal.afterClose.subscribe(() => this.fetchTicket())
  }

  deleteTicket(tag: string) {
    const modal = this.modal.confirm({
      nzTitle: 'Are you sure delete this Ticket?',
      nzContent: '<b style="color: red;">This action cannot be undo</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => {
        this.ticketService.deleteTicket(tag).subscribe(
          r => {
            this.notification.success('Success', r.message)
            this.fetchTicket()
          },
          err => {
            this.notification.error('Error', err.error.message)
            this.fetchTicket()
          },
        )
      },
      nzCancelText: 'No',
      nzOnCancel: () => console.log('Cancel'),
    })
  }

  exportTickets(): void {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')
    this.ticketService.exportTicket(this.queryTable).subscribe(
      r => {
        this.isDownloading = false
        blankPage!.location.href = r.link
        this.notification.success('Success', r.message)
      },
      err => {
        blankPage.close()
        this.isDownloading = false
        this.notification.error('Error', err.error.message)
      },
    )
  }
}
