import { Component } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'

@Component({
  selector: 'vb-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  constructor(private apiService: ApiService) {}

  checkLink() {
    this.apiService.appMobile().subscribe(r => {
      // console.log(r)
      this.link = r.link
      this.version = r.version
    })
  }
  version: string = ''
  link: string = ''
  linkQR = this.apiService.API_SUNSHINE_SERVER + '/api/static/qrcodeMobile.svg'

  openNewTab() {
    window.open(this.link)
  }
}
