<form nz-form [formGroup]="form">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <nz-form-item>
                <nz-form-label nzFor="name" class="text-left">Position Name</nz-form-label>
                <nz-form-control>
                    <input nz-input id="name" formControlName="name" placeholder="Input Position Name ..." />
                </nz-form-control>
            </nz-form-item>
        </div>

        <div class="col-6">
            <nz-form-label nzFor="head" class="text-left">Head of Position</nz-form-label>
            <nz-switch id="'head'" formControlName="head" [(ngModel)]="switchValue" [nzControl]="true"
                (click)="clickSwitch()"></nz-switch>
            <div *ngIf="switchValue === true">
                <nz-form-item>
                    <nz-form-control nzErrorTip="Please choose a head!">
                        <nz-select nzShowSearch nzServerSearch nzAllowClear id="parent_id" formControlName="parent_id"
                            nzPlaceHolder="Choose a Head" (nzOnSearch)="search($event, 'head')">
                            <ng-container *ngFor="let item of headArr">
                                <nz-option *ngIf="!isLoadingOptions" [nzValue]="item.id" [nzLabel]="item.name">
                                </nz-option>
                            </ng-container>
                            <nz-option *ngIf="isLoadingOptions" nzDisabled nzCustomContent>
                                <i nz-icon nzType="loading" class="loading-icon"></i>
                                Loading Data...
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div>
</form>
<div *nzModalFooter>
    <button nz-button nzType="default" (click)="cancel()" [nzLoading]="isLoading">Cancel</button>
    <button nz-button nzType="primary" (click)="submitForm()" [nzLoading]="isLoading">Create</button>
</div>